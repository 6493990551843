import React from 'react';
import DOMPurify from 'dompurify';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

import { useTranslation } from 'react-i18next';

import { ReactComponent as SuccessIcon } from 'assets/images/JobApplication/SuccessIcon.svg';
import { Skeleton } from 'primereact/skeleton';

const RegsiterSuccess = () => {
  const { t } = useTranslation(['translation']);
  const location = useLocation();
  const queryStringValues = queryString.parse(location.search);

  return (
    <div className='mx-6 lg:mx-0'>
      <div className='relative text-center min-h-80vh max-w-screen-xl mx-auto'>
        <div className='flex min-h-80vh bg-SuccessBgIllus bg-no-repeat bg-left-top h-full w-full flex-col justify-center mx-auto rounded-lg bg-white shadow-lg my-8 border pt-4 pb-8 px-8'>
          <div className='h-full'>
            <div className='sucss-reg-bg w-full flex justify-center items-center my-8 md:my-12 bigtab:my-8 lg:my-8'>
              <div className=''>
                <SuccessIcon />
              </div>
            </div>
            <h1 className='text-center font-bold text-515151 text-2xl 2xl:text-3xl my-8 '>
              {t('translation:thank_you')}
            </h1>

            {!queryStringValues.successMessage ? (
              <div className='flex justify-center'>
                <Skeleton height='1.75rem' width='40rem' />
              </div>
            ) : (
              <div
                className='text-515151 flex justify-center mx-auto text-xl 2xl:text-2xl prose'
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(queryStringValues.successMessage),
                }}
              ></div>
            )}
            <p className='text-Primary my-4 text-sm'>
              {t('translation:you_can_close_this_window')}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegsiterSuccess;
