import React from 'react';

import Lottie from 'react-lottie-player';
import loader_lottie from 'assets/lottieFiles/loder_lottie.json';

const LottiLoader = ({ width = '70px', height = '70px' }) => {
  return (
    <Lottie
      animationData={loader_lottie}
      background='transparent'
      speed='1'
      style={{ width: width, height: height }}
      play
    />
  );
};

export default LottiLoader;
