import React, { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import queryString from 'query-string';

const VideoConference = () => {
  // const [loading, setLoading] = useState(false);

  const location = useLocation();
  const history = useHistory();
  const queryStringValues = queryString.parse(location.search);

  let jitsiAPI = undefined;
  const containerStyle = {
    width: '100%',
    height: '100%',
  };

  const jitsiContainerStyle = {
    display: 'block',
    width: '100%',
    height: '100%',
  };

  function startConference() {
    try {
      const domain = 'jitsi.aptagrim.com';
      const options = {
        roomName: queryStringValues?.room_id,
        height: 550,
        parentNode: document.getElementById('jitsi-container'),
        interfaceConfigOverwrite: {
          filmStripOnly: false,
          SHOW_JITSI_WATERMARK: false,
        },
        configOverwrite: {
          enableClosePage: true,
          disableSimulcast: false,
          toolbarButtons: [
            'camera',
            'chat',
            'closedcaptions',
            'desktop',
            // 'download',
            // 'embedmeeting',
            'etherpad',
            // 'feedback',
            'filmstrip',
            'fullscreen',
            'hangup',
            'help',
            // 'invite',
            // 'livestreaming',
            'microphone',
            // 'mute-everyone',
            // 'mute-video-everyone',
            // 'participants-pane',
            // 'profile',
            'raisehand',
            'recording',
            // 'security',
            'select-background',
            'settings',
            // 'shareaudio',
            // 'sharedvideo',
            // 'shortcuts',
            // 'stats',
            'tileview',
            'toggle-camera',
            // 'videoquality',
            '__end',
          ],
        },
      };

      //  const api = new JitsiMeetExternalAPI(domain, options);
      jitsiAPI = new window.JitsiMeetExternalAPI(domain, options);
      jitsiAPI.addEventListener('videoConferenceJoined', () => {
        jitsiAPI.executeCommand(
          'displayName',
          queryStringValues?.applicant_name
        );
      });

      jitsiAPI.addEventListener('readyToClose', () => {
        const searchString = queryString.stringify(queryStringValues);
        history.replace({
          pathname: '/panel-interview/success',
          search: `?${searchString}`,
        });
      });

      // jitsiAPI.addEventListeners({
      //   readyToClose: function () {
      //     console.log('meeting ended');
      //   },
      // });
      // jitsiAPI.addListener('readyToClose', () => {
      //   console.log('readyToClose');
      //   console.log('readyToClose');
      // });
    } catch (error) {
      console.error('Failed to load Jitsi API', error);
    }
  }

  useEffect(() => {
    // verify the JitsiMeetExternalAPI constructor is added to the global..
    if (window.JitsiMeetExternalAPI) startConference();
    else alert('Jitsi Meet API script not loaded');

    return () => jitsiAPI?.dispose?.();
    // eslint-disable-next-line
  }, []);

  return (
    <div style={containerStyle}>
      <div id='jitsi-container' style={jitsiContainerStyle} />
    </div>
  );
};

export default VideoConference;
