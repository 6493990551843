import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router';

import makeAPIRequest from 'services/makeApiRequest';
import apiEndPoints from 'services/apiEndpoints';

import LottiLoader from '../../components/LottiLoader';
function PreConnection() {
  const [urlFailed, setUrlFailed] = useState('');

  const { preConnectionID } = useParams();

  useQuery(
    ['preConnectionQuery'],
    async () => {
      if (preConnectionID) {
        const data = await makeAPIRequest.get(
          `${apiEndPoints.APPLICANTURLGENERATION}?secret_code=${preConnectionID}`
        );
        return data;
      }
    },
    {
      onSuccess: (data) => {
        window.location.replace(data?.data?.data);
      },
      onError: (err) => {
        setUrlFailed(err?.response?.data.message);
      },
      enabled: !!preConnectionID,
    }
  );

  return (
    <div className='flex min-h-86vh max-h-86vh justify-center items-center'>
      {urlFailed ? (
        <div className='text-red-600 text-xl'>{urlFailed}</div>
      ) : (
        <LottiLoader />
      )}
    </div>
  );
}

export default PreConnection;
