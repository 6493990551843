import React, { useState, useContext } from 'react';
import { MainContext } from 'contexts/mainContext';
import { useQuery, useMutation } from 'react-query';
import makeAPIRequest from 'services/makeApiRequest.js';
import apiEndPoints from 'services/apiEndpoints';
import { useParams, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import ApplyJob from './components/ApplyJob';
import ViewAllJobs from './components/viewAllJobs';
import ViewJobDetails from './components/viewJobDetails';
import RegsiterSuccess from './components/registerSuccess';
import ErrorMessage from './components/ErrorMessage';

export default function JobApplication() {
  const { mainDispatch } = useContext(MainContext);
  const location = useLocation();
  const queryStringValues = queryString.parse(location.search);
  const [selectJobID, setSelectJobID] = useState(queryStringValues._id || null);
  const [selectJobName, setSelectJobName] = useState();

  const { company, jobId } = useParams();

  const getApplicationFormJobs = useQuery(
    ['getApplicationFormJobs', company, queryStringValues.client],
    async () => {
      let resData;
      if (!!queryStringValues.client) {
        resData = await makeAPIRequest.get(
          `${apiEndPoints.APPLICANTJOBS}?company=${company}&client=${queryStringValues.client}`
        );
      } else {
        resData = await makeAPIRequest.get(
          `${apiEndPoints.APPLICANTJOBS}?company=${company}`
        );
      }
      return resData.data;
    },
    {
      enabled: !jobId,
      onSuccess: (data) => {
        mainDispatch({
          type: 'SETNAMELOGOCOLOR',
          payload: {
            company_name: data.company_name,
            company_logo: data.company_logo,
            company_color: data.company_color,
          },
        });
      },
      onError: (err) => {
        mainDispatch({
          type: 'SETNAMELOGOCOLOR',
          payload: {
            company_name: err.response?.data?.company_name,
            company_logo: err.response?.data?.company_logo,
            company_color: err.response?.data?.company_color,
          },
        });
      },
    }
  );
  const getApplicationFormFields = useQuery(
    ['getApplicationFormFields', company],
    async () => {
      const { data } = await makeAPIRequest.get(
        `${apiEndPoints.APPLICANTJOBAPPFIELDS}?company_name=${company}`
      );
      return data;
    },
    {
      enabled: !!jobId || !!selectJobID,
    }
  );

  const getApplicationFormJobData = useQuery(
    ['getApplicationFormJobData', company, jobId, selectJobID],
    async () => {
      let data;
      if (company && selectJobID) {
        data = await makeAPIRequest.get(
          `${apiEndPoints.APPLICANTJOBS}?company=${company}&job_id=${selectJobID}`
        );
        setSelectJobName(data.data.data[0].jobtitle);
        // console.log(data, 'dsdss');
        return data;
      } else if (company && jobId) {
        data = await makeAPIRequest.get(
          `${apiEndPoints.APPLICANTJOBS}?company=${company}&job_id=${jobId}`
        );
        setSelectJobName(data.data.data[0].jobtitle);
        // console.log(data, 'dsdss');

        return data;
      }
    },
    {
      enabled: !!jobId || !!selectJobID,
      onSuccess: (data) => {
        if (jobId) {
          mainDispatch({
            type: 'SETNAMELOGOCOLOR',
            payload: {
              company_name: data?.data?.company_name,
              company_logo: data?.data?.company_logo,
              company_color: data?.data?.company_color,
            },
          });
        }
      },
      onError: (err) => {
        mainDispatch({
          type: 'SETNAMELOGOCOLOR',
          payload: {
            company_name: err.response?.data?.company_name,
            company_logo: err.response?.data?.company_logo,
            company_color: err.response?.data?.company_color,
          },
        });
      },
    }
  );

  const postJobApplication = useMutation(async (data) => {
    const res = await makeAPIRequest.post(apiEndPoints.APPLICANTAPI, data);
    return res.data;
  });

  const testJobApplicationResume = useMutation(async (data) => {
    const res = await makeAPIRequest.post(
      apiEndPoints.CHECK_TEST_APPLICANT_RESUME,
      data
    );
    return res.data;
  });

  return (
    <>
      {queryStringValues.view === 'view' ? (
        <ViewJobDetails
          setSelectJobName={setSelectJobName}
          jobId={!!jobId}
          getApplicationFormJobData={getApplicationFormJobData}
          currentJobId={jobId}
        />
      ) : queryStringValues.view === 'all' ? (
        <ViewAllJobs
          setSelectJobID={setSelectJobID}
          getApplicationFormJobs={getApplicationFormJobs}
        />
      ) : queryStringValues.view === 'apply' ? (
        <ApplyJob
          getApplicationFormFields={getApplicationFormFields}
          selectJobName={selectJobName}
          company={company}
          selectJobID={selectJobID}
          jobId={jobId}
          postJobApplication={postJobApplication}
          testJobApplicationResume={testJobApplicationResume}
        />
      ) : queryStringValues.view === 'success' ? (
        <RegsiterSuccess getApplicationFormJobs={getApplicationFormJobs} />
      ) : queryStringValues.view === 'error' ? (
        <ErrorMessage getApplicationFormJobs={getApplicationFormJobs} />
      ) : null}
    </>
  );
}
