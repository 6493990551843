import React from 'react';
import PrimaryButton from '../../../components/Buttons/ButtonPrimary';
import { Link } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import { ReactComponent as JobIlls } from '../../../assets/images/SkillAssessment/jobIlls.svg';
import { ReactComponent as TimeIlls } from '../../../assets/images/SkillAssessment/TimeIlls.svg';
import { ReactComponent as LevelIlls } from '../../../assets/images/PersonalityProfiling/level.svg';

export default function AssessmentOverview() {
  const { t } = useTranslation(['translation']);
  return (
    <div className='w-11/12 bg-white mx-auto border min-h-full rounded-lg shadow-lg my-4 max-w-screen-xl'>
      <div className='bg-Primary rounded-t-lg'>
        <p className='text-2xl text-white text-center pt-6'>
          {t('translation:personality_profiling')}
        </p>
        <div className='flex justify-center text-white pt-4 pb-6 space-x-2'>
          <JobIlls className='w-4 h-4 mt-0.5' />
          <p className='pr-4'>{t('translation:ux_designer')}</p>
          <TimeIlls className='w-4 h-4 mt-0.5' />
          <p>{t('translation:total_time')} - 15 Mins</p>
        </div>
      </div>
      <div>
        <div className='py-4 px-8'>
          <p className=''>
            {t(
              'translation:please_use_the_following_scale_to_rate_the_statements'
            )}{' '}
            :
          </p>
          <div className='mt-2 w-2/4 flex justify-start'>
            <LevelIlls />
          </div>
          <p className='text-gray-500'>{t('translation:example')}</p>
          <div className='grid grid-flow-col md:gap-4 xl:gap-8 mt-4 text-gray-400'>
            <div className='py-4 pl-2 border-purple border rounded-lg '>
              <div className='flex w-full relative pt-2'>
                <p className='w-4/5'>{t('translation:take_charge')}</p>
                <p className='absolute md:right-1 xl:right-4 border-b border-gray-400 text-gray-600 font-bold px-2'>
                  3
                </p>
              </div>
              <div className='flex w-full relative pt-2'>
                <p className='w-4/5'>{t('translation:negotiate_outcomes')}</p>
                <p className='absolute md:right-1 xl:right-4 border-b border-gray-400 text-gray-600 font-bold px-2'>
                  2
                </p>
              </div>
              <div className='flex w-full relative pt-2'>
                <p className='w-4/5'>
                  {t('translation:need_security_at_work')}
                </p>
                <p className='absolute md:right-1 xl:right-4 border-b border-gray-400 text-gray-600 font-bold px-2'>
                  4
                </p>
              </div>
              <div className='flex w-full relative py-2'>
                <p className='w-4/5'>{t('translation:organising_tasks')}</p>
                <p className='absolute md:right-1 xl:right-4 border-b border-gray-400 text-gray-600 font-bold px-2'>
                  1
                </p>
              </div>
            </div>
            <div className='p-4 border-purple border  rounded-lg'>
              <div className='flex w-full relative pt-2'>
                <p className='w-4/5'>{t('translation:drive_for_results')}</p>
                <p className='absolute md:right-1 xl:right-4 border-b border-gray-400 text-gray-600 font-bold px-2'>
                  2
                </p>
              </div>
              <div className='flex w-full relative pt-2'>
                <p className='w-4/5'>{t('translation:motivating_others')}</p>
                <p className='absolute md:right-1 xl:right-4 border-b border-gray-400 text-gray-600 font-bold px-2'>
                  4
                </p>
              </div>
              <div className='flex w-full relative pt-2'>
                <p className='w-4/5'>
                  {t('translation:likes_to_work_in_one_place')}
                </p>
                <p className='absolute md:right-1 xl:right-4 border-b border-gray-400 text-gray-600 font-bold px-2'>
                  1
                </p>
              </div>
              <div className='flex w-full relative py-2'>
                <p className='w-4/5'>{t('translation:following_rules')}</p>
                <p className='absolute md:right-1 xl:right-4 border-b border-gray-400 text-gray-600 font-bold px-2'>
                  2
                </p>
              </div>
            </div>
            <div className='p-4 border-purple border rounded-lg'>
              <div className='flex w-full relative pt-2'>
                <p className='w-4/5'>{t('translation:challenge_others')}</p>
                <p className='absolute md:right-1 xl:right-4 border-b border-gray-400 text-gray-600 font-bold px-2'>
                  4
                </p>
              </div>
              <div className='flex w-full relative pt-2'>
                <p className='w-4/5'>{t('translation:likes_to_help')}</p>
                <p className='absolute md:right-1 xl:right-4 border-b border-gray-400 text-gray-600 font-bold px-2'>
                  2
                </p>
              </div>
              <div className='flex w-full relative pt-2'>
                <p className='w-4/5'>{t('translation:very_patient')}</p>
                <p className='absolute md:right-1 xl:right-4 border-b border-gray-400 text-gray-600 font-bold px-2'>
                  3
                </p>
              </div>
              <div className='flex w-full relative py-2'>
                <p className='w-4/5'>{t('translation:prefers_direction')}</p>
                <p className='absolute md:right-1 xl:right-4 border-b border-gray-400 text-gray-600 font-bold px-2'>
                  1
                </p>
              </div>
            </div>
            <div className='p-4 border-purple border  rounded-lg'>
              <div className='flex w-full relative pt-2'>
                <p className='w-4/5'>{t('translation:competitive')}</p>
                <p className='absolute md:right-1 xl:right-4 border-b border-gray-400 text-gray-600 font-bold px-2'>
                  4
                </p>
              </div>
              <div className='flex w-full relative pt-2'>
                <p className='w-4/5'>{t('translation:very_enthusiastic')}</p>
                <p className='absolute md:right-1 xl:right-4 border-b border-gray-400 text-gray-600 font-bold px-2'>
                  2
                </p>
              </div>
              <div className='flex w-full relative pt-2'>
                <p className='w-4/5'>{t('translation:satisfied_with_work')}</p>
                <p className='absolute md:right-1 xl:right-4 border-b border-gray-400 text-gray-600 font-bold px-2'>
                  3
                </p>
              </div>
              <div className='flex w-full relative py-2'>
                <p className='w-4/5'>
                  {t('translation:very_detailed_oriented')}
                </p>
                <p className='absolute md:right-1 xl:right-4 border-b border-gray-400 text-gray-600 font-bold px-3 pt-5'></p>
              </div>
            </div>
          </div>
        </div>
        <div className='w-32 mx-auto'>
          <Link to='/disc-assessment/:test'>
            <button className='text-sm pt-2 pb-4 w-32'>
              <PrimaryButton>{t('translation:start_assessment')}</PrimaryButton>
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}
