import React from 'react';
import PrimaryButton from '../../../components/Buttons/ButtonPrimary';
import { useLocation, useHistory } from 'react-router-dom';
import queryString from 'query-string';
// import { useEffectOnce } from 'react-use';
import makeAPIRequest from '../../../services/makeApiRequest.js';
import apiEndPoints from '../../../services/apiEndpoints';

import { useTranslation } from 'react-i18next';
import cookies from 'js-cookie';

import { ReactComponent as JobIlls } from '../../../assets/images/SkillAssessment/jobIlls.svg';
import { ReactComponent as TimeIlls } from '../../../assets/images/PreHire/TimeIlls.svg';
import { ReactComponent as CheckIcon } from '../../../assets/images/PreHire/CheckIcon.svg';
import { ReactComponent as ArrowIcon } from '../../../assets/images/PreHire/ArrowIcon.svg';
import { useQuery } from 'react-query';

export default function PreHireOverview({
  getVideoProfileAssessmentExamData,
  getSkillAssessmentExamData,
  toggle,
}) {
  const currentLanguageCode = cookies.get('i18next');
  const { t } = useTranslation(['translation']);
  const location = useLocation();
  const history = useHistory();
  const queryStringValues = queryString.parse(location.search);

  const secToMin = (value) => {
    const minutes = Math.floor(value / 60);
    return (
      minutes.toString().padStart(2, '0') +
      ':' +
      (value - minutes * 60).toString().padStart(2, '0')
    );
  };

  useQuery(
    ['isSkillAssessmentCompleted'],
    async () => {
      const { data } = await makeAPIRequest.get(
        `${apiEndPoints.CHECK_IS_SKILL_ASSESSMENT_COMPLETED}?company_uuid=${queryStringValues.company_name}&applicant_id=${queryStringValues.applicant_id}`
      );
      return data;
    },
    {
      staleTime: 10000000,
      onSuccess: (data) => {
        if (data?.data) {
          const newsearchString = {
            isSkillComplete: true,
            ...queryStringValues,
          };
          const newsuccesssearchString = queryString.stringify(newsearchString);
          history.push({
            pathname: '/prehire-assessment/prehire-overview',
            search: `?${newsuccesssearchString}`,
          });
        }
      },
    }
  );

  // useEffectOnce(() => {
  //   toggle(false);
  // });

  return (
    <div className='flex flex-col w-11/12 bg-OverviewBgPreHire bg-no-repeat bg-bottom bg-white min-h-80vh mx-auto border rounded-lg shadow-lg my-8'>
      <div className='bg-Primary rounded-t-lg'>
        <p className='text-2xl text-white text-center pt-6'>
          {t('translation:pre_hire_assessment')}
        </p>
        <div className='flex items-center justify-center text-white pt-4 pb-6'>
          <JobIlls
            className={`w-4 h-4 mt-0.5 ${
              currentLanguageCode === 'ar' ? 'ml-2' : 'mr-2'
            }`}
          />
          <p
            className={`${
              currentLanguageCode === 'ar' ? 'pl-4 ml-2' : 'pr-4 mr-2'
            }`}
          >
            {getVideoProfileAssessmentExamData?.data?.data?.job_title}
          </p>

          {/* <p>Total time - {secToMin(600)} Min(s):Sec(s)</p> */}
        </div>
      </div>
      <div className='flex flex-col justify-start items-center h-full'>
        <div className='flex w-full flex-col p-12'>
          <div className='flex justify-between w-full py-4  border-b border-Primary border-opacity-20'>
            <div className='flex text-lg w-60'>
              {t('translation:skill_assessment')}
            </div>
            <div className='flex w-60'>
              <TimeIlls
                className={`w-4 h-4 mt-1 ${
                  currentLanguageCode === 'ar' ? 'ml-2' : 'mr-2'
                }`}
              />
              {getSkillAssessmentExamData?.data?.data?.total_time ? (
                <p
                  className={`${
                    currentLanguageCode === 'ar' ? 'pl-4' : 'pr-4'
                  }`}
                >
                  {secToMin(getSkillAssessmentExamData?.data?.data?.total_time)}{' '}
                  Min:Sec
                </p>
              ) : null}
            </div>
            {queryStringValues.isSkillComplete ? (
              <div className='flex items-center w-36 justify-center'>
                <CheckIcon
                  className={`${
                    currentLanguageCode === 'ar' ? 'ml-2' : 'mr-2'
                  }`}
                />{' '}
                <span className='text-Primary'>
                  {t('translation:completed')}
                </span>
              </div>
            ) : (
              <button
                onClick={() => {
                  const searchString = queryString.stringify(queryStringValues);

                  history.push({
                    pathname: '/prehire-assessment/overview',
                    search: `?${searchString}`,
                  });
                }}
                className='w-36'
              >
                <PrimaryButton>
                  <div className='flex justify-center items-center'>
                    <ArrowIcon
                      className={`${
                        currentLanguageCode === 'ar' ? 'ml-2' : 'mr-2'
                      }`}
                    />{' '}
                    <span className=''>{t('translation:begin_test')}</span>
                  </div>
                </PrimaryButton>
              </button>
            )}
          </div>
          <div className='flex justify-between w-full py-4 '>
            <div className='flex text-lg w-60'>
              {queryStringValues.stageName
                ? queryStringValues.stageName
                : 'Psychometric Assessment'}
            </div>
            <div className='flex w-60'>
              <TimeIlls
                className={`w-4 h-4 mt-1 ${
                  currentLanguageCode === 'ar' ? 'ml-2' : 'mr-2'
                }`}
              />
              {getVideoProfileAssessmentExamData?.data?.data?.total_time ? (
                <p
                  className={`${
                    currentLanguageCode === 'ar' ? 'pl-4' : 'pr-4'
                  }`}
                >
                  {secToMin(
                    getVideoProfileAssessmentExamData?.data?.data?.total_time
                  )}{' '}
                  Min:Sec
                </p>
              ) : null}
            </div>
            {queryStringValues.isSkillComplete ? (
              <button
                onClick={() => {
                  const searchString = queryString.stringify(queryStringValues);

                  history.push({
                    pathname: '/prehire-assessment/overviewVideo',
                    search: `?${searchString}`,
                  });
                }}
                className='w-36'
              >
                <PrimaryButton>
                  <div className='flex justify-center items-center'>
                    <ArrowIcon
                      className={`${
                        currentLanguageCode === 'ar' ? 'ml-2' : 'mr-2'
                      }`}
                    />{' '}
                    <span className=''>{t('translation:begin_test')}</span>
                  </div>
                </PrimaryButton>
              </button>
            ) : (
              <button
                disabled={true}
                className='w-36 opacity-50 cursor-not-allowed'
              >
                <PrimaryButton>
                  <div className='flex justify-center items-center'>
                    <ArrowIcon
                      className={`${
                        currentLanguageCode === 'ar' ? 'ml-2' : 'mr-2'
                      }`}
                    />{' '}
                    <span className=''>{t('translation:begin_test')}</span>
                  </div>
                </PrimaryButton>
              </button>
            )}
          </div>
        </div>
        <div className='w-40 mx-auto'></div>
      </div>
    </div>
  );
}
