import React, { useContext, useEffect } from 'react';
import SecondaryButton from '../../../components/Buttons/SecondaryButton';
import PrimaryButton from '../../../components/Buttons/ButtonPrimary';
import DOMPurify from 'dompurify';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import queryString from 'query-string';
import { Skeleton } from 'primereact/skeleton';
import dateFormatter from 'helper/dateFormatter';
import Avatar from 'components/Avatar';
import { MainContext } from 'contexts/mainContext';
import Tip from 'components/Tooltip';
import makeApiRequest from 'services/makeApiRequest';
import apiEndPoints from 'services/apiEndpoints';

import { useTranslation } from 'react-i18next';
import cookies from 'js-cookie';

// import googleStructuredData from 'helper/googleJobPostingStructuredData';

import { ReactComponent as LocationIlls } from '../../../assets/images/JobApplication/locationIlls.svg';
import { ReactComponent as ExternalLinkIlls } from '../../../assets/images/JobApplication/ExternalLinkIlls.svg';
import { ReactComponent as ExperienceIlls } from '../../../assets/images/JobApplication/ExperienceIlls.svg';
import { ReactComponent as DateIlls } from '../../../assets/images/JobApplication/DateIlls.svg';
import { ReactComponent as NOJObs } from 'assets/images/JobApplication/no_jobs.svg';

export default function ViewJobDetails({
  getApplicationFormJobData,
  jobId,
  currentJobId,
  isAppliedThroughEmail,
}) {
  const { t } = useTranslation(['translation']);
  const currentLanguageCode = cookies.get('i18next');

  const location = useLocation();
  const history = useHistory();
  const queryStringValues = queryString.parse(location.search);
  const { mainState } = useContext(MainContext);

  const { company } = useParams();

  useEffect(() => {
    // if (!getApplicationFormJobData.isLoading) {
    //   const structuredDataForGoogleJobPosting = googleStructuredData(
    //     getApplicationFormJobData.data?.data?.data[0],
    //     mainState
    //   );

    //   const script = document.createElement('script');
    //   script.setAttribute('type', 'application/ld+json');
    //   script.textContent = structuredDataForGoogleJobPosting;
    //   document.head.appendChild(script);
    // }

    if (!getApplicationFormJobData.isLoading) {
      if (getApplicationFormJobData?.data?.data?.data[0].post_job_on_google) {
        makeApiRequest
          .post(apiEndPoints.GOOGLEJOBPOSTING, {
            job_id: currentJobId,
            company_uuid: company,
          })
          .then((res) => {
            const script = document.createElement('script');
            script.setAttribute('type', 'application/ld+json');
            script.textContent = JSON.stringify(res.data.data);
            document.head.appendChild(script);
          });
      }
    }
  }, [
    currentJobId,
    company,
    getApplicationFormJobData.isLoading,
    getApplicationFormJobData?.data?.data,
  ]);
  return (
    <>
      {getApplicationFormJobData?.status === 'error' &&
      getApplicationFormJobData?.error?.response?.data?.message ===
        'No Job(s) Found.' ? (
        <div className='max-h-75vh min-h-75vh flex flex-col items-center justify-center'>
          <div>
            <NOJObs />
          </div>
          <div>{t('translation:no_Jobs_found')}</div>
        </div>
      ) : (
        <div className='mx-6 lg:mx-0'>
          {getApplicationFormJobData.isLoading ? (
            <div className='flex flex-col lg:flex-row max-w-screen-xl mx-auto max-width-main-page'>
              <div
                className={`w-full lg:w-4/6 lg:min-h-full border bg-white rounded-lg shadow-lg my-8 p-8 ${
                  currentLanguageCode === 'ar' ? 'pl-4 lg:ml-3' : 'pr-4 lg:mr-3'
                }`}
              >
                <div className='text-2xl'>
                  <Skeleton height='2rem' width='12rem' />
                </div>
                <div className='mt-4 flex'>
                  <div
                    className={`flex ${
                      currentLanguageCode === 'ar'
                        ? 'sm:ml-2 lg:ml-3'
                        : 'sm:mr-2 lg:mr-3'
                    }`}
                  >
                    <Skeleton height='1rem' width='1rem' />
                    <div
                      className={`text-sm text-gray-400 pt-1 ${
                        currentLanguageCode === 'ar' ? 'pr-1' : 'pl-1'
                      }`}
                    >
                      <Skeleton height='1.3rem' width='8rem' />
                    </div>
                  </div>
                  <Skeleton
                    className={`${
                      currentLanguageCode === 'ar'
                        ? 'sm:ml-4 lg:ml-5'
                        : 'sm:mr-4 lg:mr-5'
                    }`}
                    height='0.75rem'
                    width='2px'
                  />
                  <div
                    className={`flex ${
                      currentLanguageCode === 'ar'
                        ? 'sm:ml-2 lg:ml-3'
                        : 'sm:mr-2 lg:mr-3'
                    }`}
                  >
                    <Skeleton height='1rem' width='1rem' />
                    <div
                      className={`text-sm text-gray-400 pt-1 ${
                        currentLanguageCode === 'ar' ? 'pr-1' : 'pl-1'
                      }`}
                    >
                      <Skeleton height='1.3rem' width='8rem' />
                    </div>
                  </div>
                  <Skeleton
                    className={`${
                      currentLanguageCode === 'ar'
                        ? 'sm:ml-4 lg:ml-5'
                        : 'sm:mr-4 lg:mr-5'
                    }`}
                    height='0.75rem'
                    width='2px'
                  />
                  <div
                    className={`flex ${
                      currentLanguageCode === 'ar'
                        ? 'sm:ml-2 lg:ml-3'
                        : 'sm:mr-2 lg:mr-3'
                    }`}
                  >
                    <Skeleton height='1rem' width='1rem' />
                    <div
                      className={`text-sm text-gray-400 pt-1 ${
                        currentLanguageCode === 'ar' ? 'pr-1' : 'pl-1'
                      }`}
                    >
                      <Skeleton height='1.3rem' width='12rem' />
                    </div>
                  </div>
                </div>
                <div className='flex flex-wrap gap-4 text-Primary mt-6'>
                  <Skeleton height='2.2rem' width='6rem' borderRadius='2rem' />
                  <Skeleton height='2.2rem' width='6rem' borderRadius='2rem' />
                  <Skeleton height='2.2rem' width='6rem' borderRadius='2rem' />
                  <Skeleton height='2.2rem' width='6rem' borderRadius='2rem' />
                  <Skeleton height='2.2rem' width='6rem' borderRadius='2rem' />
                </div>
                <div className='h-96'>
                  <div>
                    <div className='text-lg font-bold mt-6 mb-3 text-gray-600'>
                      <Skeleton height='1.5rem' width='8rem' />
                    </div>
                    <div className='w-1/2 my-4'>
                      <Skeleton height='1.2rem' />
                    </div>
                    <div className='w-1/2 my-4'>
                      <Skeleton height='1.2rem' />
                    </div>
                    <div className='w-3/4 my-4'>
                      <Skeleton height='1.2rem' />
                    </div>
                    <div className='w-3/4 my-4'>
                      <Skeleton height='1.4rem' />
                    </div>
                    <div className='w-3/4 my-4'>
                      <Skeleton height='1.2rem' />
                    </div>
                    <div className='w-3/4 my-4'>
                      <Skeleton height='1.4rem' />
                    </div>
                    <div className='w-full my-4'>
                      <Skeleton height='1.4rem' />
                    </div>
                    <div className='w-full my-4'>
                      <Skeleton height='1.6rem' />
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={`w-full lg:w-2/6 shadow-lg bg-white rounded-lg my-8 p-8 lg:min-h-full border ${
                  currentLanguageCode === 'ar' ? 'pl-4 lg:mr-3' : 'pr-4 lg:ml-3'
                }`}
              >
                <div className='flex'>
                  <div>
                    <Skeleton
                      className='my-1'
                      height='3.5rem'
                      width='3.5rem'
                      borderRadius='3.5rem'
                    />
                  </div>
                  <div
                    className={`py-2 ${
                      currentLanguageCode === 'ar' ? 'mr-4' : 'ml-4'
                    }`}
                  >
                    <div className='flex'>
                      <div className='text-lg'>
                        <Skeleton
                          className='my-1'
                          height='1.5rem'
                          width='10rem'
                        />
                      </div>
                      <Skeleton className='m-2' height='1rem' width='1rem' />
                    </div>
                    <div className='text-sm text-gray-400'>
                      <Skeleton
                        className='my-1'
                        height='0.75rem'
                        width='8rem'
                      />
                    </div>
                  </div>
                </div>
                <div className='flex w-full flex-wrap mt-12 text-gray-600'>
                  {/* <div className='flex w-full justify-between my-1'>
              <p className='w-1/2  text-sm'>Hiring Contact</p>
              <span>:</span>
              <p className='w-1/2 text-gray-400 text-sm pl-2'>
                Cristina Dashwood
              </p>
            </div> */}
                  <div className='flex w-full justify-between items-center my-1'>
                    <div className='w-4/12 text-gray-400 text-sm'>
                      <Skeleton height='1.5rem' width='6rem' />
                    </div>
                    <span>
                      <Skeleton height='1rem' width='4px' />
                    </span>
                    <div
                      className={`w-8/12 text-gray-400 text-sm ${
                        currentLanguageCode === 'ar' ? 'pr-2' : 'pl-2'
                      }`}
                    >
                      <Skeleton height='1.5rem' width='6rem' />
                    </div>
                  </div>
                  <div className='flex w-full justify-between items-center my-1'>
                    <div className='w-4/12 text-gray-400 text-sm'>
                      <Skeleton height='1.5rem' width='6rem' />
                    </div>
                    <span>
                      <Skeleton height='1rem' width='4px' />
                    </span>
                    <div
                      className={`w-8/12 text-gray-400 text-sm ${
                        currentLanguageCode === 'ar' ? 'pr-2' : 'pl-2'
                      }`}
                    >
                      <Skeleton height='1.5rem' width='6rem' />
                    </div>
                  </div>
                  <div className='flex w-full justify-between items-center my-1'>
                    <div className='w-4/12 text-gray-400 text-sm'>
                      <Skeleton height='1.5rem' width='6rem' />
                    </div>
                    <span>
                      <Skeleton height='1rem' width='4px' />
                    </span>
                    <div
                      className={`w-8/12 text-gray-400 text-sm ${
                        currentLanguageCode === 'ar' ? 'pr-2' : 'pl-2'
                      }`}
                    >
                      <Skeleton height='1.5rem' width='6rem' />
                    </div>
                  </div>
                  <div className='flex w-full justify-between items-center my-1'>
                    <div className='w-4/12 text-gray-400 text-sm'>
                      <Skeleton height='1.5rem' width='6rem' />
                    </div>
                    <span>
                      <Skeleton height='1rem' width='4px' />
                    </span>
                    <div
                      className={`w-8/12 text-gray-400 text-sm ${
                        currentLanguageCode === 'ar' ? 'pr-2' : 'pl-2'
                      }`}
                    >
                      <Skeleton height='1.5rem' width='6rem' />
                    </div>
                  </div>

                  {/* <div className='flex w-full justify-between items-center my-1'>
                <p className='w-4/12 text-sm'>Visa Sponsorship</p>
                <p>:</p>
                <p className={`w-8/12 text-gray-400 text-sm ${currentLanguageCode === 'ar' ? 'pr-2' : 'pl-2'}`}>
                  <Skeleton height='1rem' width='6rem' />
                </p>
              </div> */}
                </div>
                <div className='mt-4 flex'>
                  <Skeleton
                    className={`${
                      currentLanguageCode === 'ar' ? 'ml-4' : 'mr-4'
                    }`}
                    height='2.2rem'
                    width='7rem'
                  />
                  <Skeleton height='2.2rem' width='8rem' />
                </div>
              </div>
            </div>
          ) : (
            getApplicationFormJobData.data?.data?.data.map((item) => (
              <div
                className=' flex flex-col lg:flex-row max-w-screen-xl mx-auto max-width-main-page'
                key={item.job_id}
              >
                <div
                  className={`w-full lg:w-4/6 lg:min-h-full border bg-white rounded-lg shadow-lg my-8 p-8 ${
                    currentLanguageCode === 'ar'
                      ? 'pl-4 lg:ml-3'
                      : 'pr-4 lg:mr-3'
                  }`}
                >
                  <p className='text-2xl'>{item.jobtitle}</p>
                  <div className='mt-4 flex flex-col md:flex-row'>
                    <div
                      className={`flex ${
                        currentLanguageCode === 'ar'
                          ? 'sm:ml-2 lg:ml-3'
                          : 'sm:mr-2 lg:mr-3'
                      }`}
                    >
                      <LocationIlls className='w-4 h-4' />
                      <p
                        className={`text-sm text-gray-400 pt-1 ${
                          currentLanguageCode === 'ar' ? 'pr-1' : 'pl-1'
                        }`}
                      >
                        {item.location}
                      </p>
                    </div>
                    <div
                      className={`h-3 w-0.5 bg-gray-700 hidden md:block ${
                        currentLanguageCode === 'ar'
                          ? 'sm:ml-2 lg:ml-3'
                          : 'sm:mr-2 lg:mr-3'
                      }`}
                    ></div>
                    <div
                      className={`flex ${
                        currentLanguageCode === 'ar'
                          ? 'sm:ml-2 lg:ml-3'
                          : 'sm:mr-2 lg:mr-3'
                      }`}
                    >
                      <DateIlls className='w-4 h-4' />
                      <p
                        className={`text-sm text-gray-400 pt-1 ${
                          currentLanguageCode === 'ar' ? 'pr-1' : 'pl-1'
                        }`}
                      >
                        {dateFormatter(item.posted_date, 'll')}
                      </p>
                    </div>
                    <div
                      className={`h-3 w-0.5 bg-gray-700 hidden md:block ${
                        currentLanguageCode === 'ar'
                          ? 'sm:ml-2 lg:ml-3'
                          : 'sm:mr-2 lg:mr-3'
                      }`}
                    ></div>
                    <div
                      className={`flex ${
                        currentLanguageCode === 'ar'
                          ? 'sm:ml-2 lg:ml-3'
                          : 'sm:mr-2 lg:mr-3'
                      }`}
                    >
                      <ExperienceIlls className='w-4 h-4' />
                      <p
                        className={`text-sm text-gray-400 pt-1 ${
                          currentLanguageCode === 'ar' ? 'pr-1' : 'pl-1'
                        }`}
                      >
                        {`Experience: ${item.experience} ${
                          item.experience === '0-1' ? 'year' : 'years'
                        }`}
                      </p>
                    </div>
                  </div>
                  <div className='flex flex-wrap text-Primary mt-4'>
                    {item.key_skills.map((skill) => (
                      <p
                        className={`border px-3 py-1 my-2 rounded-2xl ${
                          currentLanguageCode === 'ar' ? 'ml-4' : 'mr-4'
                        }`}
                        key={skill}
                      >
                        {skill}
                      </p>
                    ))}
                  </div>
                  <div className='h-96 flex w-full'>
                    <div className='flex flex-col w-full '>
                      <p className='text-lg font-bold mt-4 mb-3 text-gray-600'>
                        {t('translation:job_description')}
                      </p>
                      <div
                        className='w-full text-Gray1 prose max-w-full overflow-y-scroll scroller h-80 text-sm'
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(item?.jobdescription),
                        }}
                      ></div>
                    </div>
                  </div>
                </div>
                <div
                  className={`w-full lg:w-2/6 shadow-lg bg-white rounded-lg my-8 p-8 lg:min-h-full border ${
                    currentLanguageCode === 'ar'
                      ? 'pl-4 lg:mr-3'
                      : 'pr-4 lg:ml-3'
                  }`}
                >
                  <div className='flex'>
                    <div>
                      <Avatar
                        imgSrc={mainState?.company_logo}
                        className='inline object-cover my-1 w-14 h-14 rounded-full'
                        userInitials={`${mainState.company_name
                          ?.charAt(0)
                          .toUpperCase()}`}
                      />
                    </div>
                    <div
                      className={`py-2 ${
                        currentLanguageCode === 'ar' ? 'mr-4' : 'ml-4'
                      }`}
                    >
                      <div className='flex'>
                        <p className='text-lg'>
                          {getApplicationFormJobData.data?.data?.company_name}
                        </p>
                        {getApplicationFormJobData.data?.data?.website ? (
                          <a
                            target='_blank'
                            rel='noopener noreferrer'
                            href={getApplicationFormJobData.data?.data?.website}
                          >
                            <ExternalLinkIlls className='w-4 h-4 m-2 ' />
                          </a>
                        ) : null}
                      </div>
                      <p className='text-sm text-gray-400'>{item.jobtitle}</p>
                    </div>
                  </div>
                  <div className='flex w-full flex-wrap mt-12 text-gray-600'>
                    {/* <div className='flex w-full justify-between my-1'>
                  <p className='w-5/12  text-sm'>Hiring Contact</p>
                  <span>:</span>
                  <p className='w-7/12 text-gray-400 text-sm pl-2'>
                    Cristina Dashwood
                  </p>
                </div> */}
                    <div className='flex w-full justify-between items-center my-1'>
                      <p className='w-4/12 text-sm my-auto'>
                        {t('translation:job_id')}
                      </p>
                      <span className='flex items-center'>:</span>
                      <p
                        className={`w-8/12 text-gray-400 my-auto text-sm ${
                          currentLanguageCode === 'ar' ? 'pr-2' : 'pl-2'
                        }`}
                      >
                        {item.job_id}
                      </p>
                    </div>
                    {item?.hide_salary_information ? null : (
                      <div className='flex w-full justify-between items-center my-1'>
                        <p className='w-4/12 text-sm my-auto'>
                          {t('translation:ctc')}
                        </p>
                        <p className='flex items-center'>:</p>
                        <p
                          className={`w-8/12 text-gray-400 my-auto text-sm ${
                            currentLanguageCode === 'ar' ? 'pr-2' : 'pl-2'
                          }`}
                        >
                          {`${item.salary_currency} ${item.salary_min} - ${item.salary_max}/${item.salary_per}`}
                        </p>
                      </div>
                    )}
                    <div className='flex w-full justify-between items-center my-1'>
                      <p className='w-4/12 text-sm my-auto'>
                        {t('translation:job_type')}
                      </p>
                      <p className='flex items-center'>:</p>
                      <p
                        className={`w-8/12 text-gray-400 my-auto text-sm ${
                          currentLanguageCode === 'ar' ? 'pr-2' : 'pl-2'
                        }`}
                      >
                        {item.employment_type === 'Full_Time'
                          ? 'Full-time'
                          : item.employment_type === 'Part_Time'
                          ? 'Part-time'
                          : item.employment_type}
                      </p>
                    </div>
                    <div className='flex w-full justify-between my-1'>
                      <p className='w-4/12 text-sm my-auto'>
                        {t('translation:no_of_openings')}
                      </p>
                      <p className='flex items-center'>:</p>
                      <p
                        className={`w-8/12 text-gray-400 my-auto text-sm ${
                          currentLanguageCode === 'ar' ? 'pr-2' : 'pl-2'
                        }`}
                      >
                        {item.no_of_openings}
                      </p>
                    </div>
                    {/* <div className='flex w-full justify-between items-center my-1'>
                  <p className='w-5/12 text-sm'>Visa Sponsorship</p>
                  <p>:</p>
                  <p className='w-7/12 text-gray-400 text-sm pl-2'>N/A</p>
                </div> */}
                  </div>
                  {isAppliedThroughEmail ? (
                    <div className='mt-4 flex'>
                      <Tip
                        content={
                          item.status !== 'Activated'
                            ? 'No longer accepting applications'
                            : ''
                        }
                      >
                        <button
                          disabled={item.status !== 'Activated'}
                          onClick={() => {
                            history.goBack();
                          }}
                          className={`w-28 ${
                            item.status !== 'Activated'
                              ? 'opacity-60 cursor-not-allowed'
                              : ''
                          } ${currentLanguageCode === 'ar' ? 'ml-4' : 'mr-4'}`}
                        >
                          <PrimaryButton>
                            {t('translation:apply')}
                          </PrimaryButton>
                        </button>
                      </Tip>
                    </div>
                  ) : (
                    <div className='mt-4 flex'>
                      <Tip
                        content={
                          item.status !== 'Activated'
                            ? 'No longer accepting applications'
                            : ''
                        }
                      >
                        <button
                          disabled={item.status !== 'Activated'}
                          onClick={() => {
                            queryStringValues.view = 'apply';
                            const searchString =
                              queryString.stringify(queryStringValues);
                            history.push({
                              pathname: location.pathname,
                              search: `?${searchString}`,
                            });
                          }}
                          className={`w-28 ${
                            item.status !== 'Activated'
                              ? 'opacity-60 cursor-not-allowed'
                              : ''
                          } ${currentLanguageCode === 'ar' ? 'ml-4' : 'mr-4'}`}
                        >
                          <PrimaryButton>
                            {t('translation:apply')}
                          </PrimaryButton>
                        </button>
                      </Tip>
                      <button
                        onClick={() => {
                          queryStringValues.view = 'all';
                          const searchString =
                            queryString.stringify(queryStringValues);
                          history.push({
                            pathname: location.pathname,
                            search: `?${searchString}`,
                          });
                        }}
                        className={`${
                          jobId ? 'hidden' : 'block'
                        } w-32 text-sm whitespace-nowrap`}
                      >
                        <SecondaryButton>
                          {t('translation:view_other_jobs')}
                        </SecondaryButton>
                      </button>
                    </div>
                  )}
                </div>
              </div>
            ))
          )}
        </div>
      )}
    </>
  );
}
