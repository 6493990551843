import React, { useContext } from 'react';
import { MainContext } from 'contexts/mainContext';

import cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';

import { Dialog } from 'primereact/dialog';
// import SecondaryButton from 'components/Buttons/SecondaryButton';
// import PrimaryButton from 'components/Buttons/ButtonPrimary';

const ChromeRecomendedBanner = () => {
  const { mainState, mainDispatch } = useContext(MainContext);

  const currentLanguageCode = cookies.get('i18next');
  const { t } = useTranslation(['translation']);

  let isSafari =
    navigator.vendor.match(/apple/i) &&
    !navigator.userAgent.match(/crios/i) &&
    !navigator.userAgent.match(/fxios/i) &&
    !navigator.userAgent.match(/Opera|OPT\//);

  const closeChormeWarning = () => {
    mainDispatch({
      type: 'SETWARNINGBANNER',
      payload: false,
    });
  };

  const header = (
    <div className='flex justify-end'>
      <div className='z-50'>
        <svg
          width='23'
          height='23'
          viewBox='0 0 23 23'
          fill='none'
          xmlns='https://www.w3.org/2000/svg'
          onClick={closeChormeWarning}
        >
          <path
            d='M20.2939 2.95898L2.29395 20.959'
            stroke='#FF009C'
            strokeWidth='4'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            d='M2.29395 2.95898L20.2939 20.959'
            stroke='#FF009C'
            strokeWidth='4'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </svg>
      </div>
    </div>
  );

  if (isSafari) {
    return (
      <div>
        <Dialog
          header={header}
          visible={mainState.show_warning_banner}
          onHide={closeChormeWarning}
          style={{
            // marginTop: '4rem',
            borderRadius: '1rem',
          }}
          className='w-10/12 max-w-2xl h-70vh  rounded-lg'
          modal
          draggable={false}
          dismissableMask={true}
          blockScroll={true}
          closable={false}
          position='center'
          resizable={false}
        >
          <div className='relative w-full h-full px-10 pb-6 z-40'>
            <p className='text-center text-xl'>
              {currentLanguageCode === 'ar' ? (
                t(
                  'translation:for_optimal_experience_please_use_chrome_browser'
                )
              ) : (
                <>
                  For optimal experience, please use{' '}
                  <span className=' font-bold'>Chrome</span> Browser
                </>
              )}
            </p>
            <div className='flex justify-center mt-4'>
              <a
                href='https://www.google.com/intl/en_in/chrome/'
                target='_blank'
                rel='noreferrer'
                className=' text-blue-700 hover:underline underline'
              >
                {t('translation:download_chrome')}
              </a>
            </div>
          </div>
        </Dialog>
      </div>
    );
  } else {
    return null;
  }
};

export default ChromeRecomendedBanner;
