import React from 'react';
import PrimaryButton from '../../../components/Buttons/ButtonPrimary';
import { useLocation, useHistory } from 'react-router-dom';
import queryString from 'query-string';

import { useTranslation } from 'react-i18next';
import cookies from 'js-cookie';

import { ReactComponent as JobIlls } from '../../../assets/images/SkillAssessment/jobIlls.svg';
import { ReactComponent as TimeIlls } from '../../../assets/images/SkillAssessment/TimeIlls.svg';
import { ReactComponent as MCQIlls } from '../../../assets/images/SkillAssessment/MCQIlls.svg';
import { ReactComponent as TFIlls } from '../../../assets/images/SkillAssessment/TFIlls.svg';
import { ReactComponent as Shortanslls } from '../../../assets/images/SkillAssessment/shortansIlls.svg';

export default function AssessmentOverview({
  getSkillAssessmentExamData,
  isFullscreen,
}) {
  const currentLanguageCode = cookies.get('i18next');
  const { t } = useTranslation(['translation']);
  const location = useLocation();
  const history = useHistory();
  const queryStringValues = queryString.parse(location.search);

  // console.log(isFullscreen);

  const secToMin = (value) => {
    const minutes = Math.floor(value / 60);
    return (
      minutes.toString().padStart(2, '0') +
      ':' +
      (value - minutes * 60).toString().padStart(2, '0')
    );
  };

  return (
    <div className='flex flex-col w-11/12 bg-white min-h-80vh mx-auto border rounded-lg shadow-lg my-8'>
      <div className='bg-Primary rounded-t-lg'>
        <p className='text-2xl text-white text-center pt-6'>
          {t('translation:skill_assessment')}
        </p>
        <div className='flex items-center justify-center text-white pt-4 pb-6'>
          <JobIlls
            className={`w-4 h-4 mt-0.5 ${
              currentLanguageCode === 'ar' ? 'ml-2' : 'mr-2'
            }`}
          />
          <p
            className={`${
              currentLanguageCode === 'ar' ? 'pl-4 ml-2' : 'pr-4 mr-2'
            }`}
          >
            {getSkillAssessmentExamData?.data?.data?.job_title}
          </p>
          <TimeIlls
            className={`w-4 h-4 mt-0.5 ${
              currentLanguageCode === 'ar' ? 'ml-2' : 'mr-2'
            }`}
          />
          <p
            className={`${
              currentLanguageCode === 'ar' ? 'pl-4 ml-2' : 'pr-4 mr-2'
            }`}
          >
            {secToMin(getSkillAssessmentExamData?.data?.data?.total_time)}{' '}
            Min:Sec
          </p>
          {/* <p>Total time - {secToMin(600)} Min(s):Sec(s)</p> */}
        </div>
      </div>
      <div className='h-full'>
        <div className='flex flex-wrap gap-6 justify-center pt-16 sm:w-full md:w-3/4 mx-auto'>
          {getSkillAssessmentExamData?.data?.data?.MCQ_count ? (
            <div className=' shadow-lg border rounded-lg h-44 lg:w-52 sm:w-48 relative'>
              <p className='text-Primary font-bold py-4 text-center whitespace-nowrap md:text-sm lg:text-normal'>
                {t('translation:multiple_choice_questions')}{' '}
              </p>
              <hr className='border-Primary mx-4 opacity-30' />
              <p className='font-semibold text-xl text-center pb-4 pt-8'>
                {getSkillAssessmentExamData?.data?.data?.MCQ_count}
              </p>
              <MCQIlls className='w-20 h-10 absolute right-4' />
            </div>
          ) : null}
          {getSkillAssessmentExamData?.data?.data?.TF_count ? (
            <div className=' shadow-lg border rounded-lg h-44 lg:w-52 sm:w-48 relative'>
              <p className='text-Primary font-bold py-4 text-center whitespace-nowrap md:text-sm lg:text-normal'>
                {t('translation:true_or_false_questions')}
              </p>
              <hr className='border-Primary mx-4 opacity-30' />
              <p className='font-semibold text-xl text-center pb-4 pt-8'>
                {getSkillAssessmentExamData?.data?.data?.TF_count}
              </p>
              <TFIlls className='w-20 h-10 absolute right-4 bottom-4' />
            </div>
          ) : null}
          {getSkillAssessmentExamData?.data?.data?.SAQ_count ? (
            <div className='shadow-lg border rounded-lg h-44 lg:w-52 sm:w-48 relative'>
              <p className='text-Primary font-bold py-4 whitespace-nowrap md:text-sm lg:text-normal text-center'>
                {t('translation:short_answer_questions')}
              </p>
              <hr className='border-Primary mx-4 opacity-30' />
              <p className='font-semibold text-xl text-center pb-16 pt-8'>
                {getSkillAssessmentExamData?.data?.data?.SAQ_count}
              </p>
              <Shortanslls className='h-12 absolute right-4 bottom-4' />
            </div>
          ) : null}
        </div>
        <div className='w-40 mx-auto'>
          <button
            onClick={() => {
              const searchString = queryString.stringify(queryStringValues);
              history.push({
                pathname: '/skill-assessment/test',
                search: `?${searchString}`,
              });
            }}
            className='pt-16 w-40'
          >
            <PrimaryButton>{t('translation:start_assessment')}</PrimaryButton>
          </button>
        </div>
      </div>
    </div>
  );
}
