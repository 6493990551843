import React, { useState } from 'react';
import { Checkbox } from 'primereact/checkbox';
import { useLocation, useHistory } from 'react-router-dom';
import queryString from 'query-string';
import PrimaryButton from '../../../components/Buttons/ButtonPrimary';

import makeAPIRequest from 'services/makeApiRequest.js';
import apiEndPoints from 'services/apiEndpoints';

import { useTranslation } from 'react-i18next';
import cookies from 'js-cookie';

import { ReactComponent as VideoPermissionIllus } from '../../../assets/images/VideoProfileAssessment/VideoPermissionIllus.svg';

export default function Instructions() {
  const { t } = useTranslation(['translation']);
  const currentLanguageCode = cookies.get('i18next');
  const [checkboxValue, setCheckboxValue] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const queryStringValues = queryString.parse(location.search);

  const pushNotificationFn = async (data) => {
    await makeAPIRequest.post(apiEndPoints.SENDPUSHNOTIFICATIONAPI, data);
  };

  return (
    <div className='w-11/12 max-w-main-page bg-BgArrowIllus bg-no-repeat bg-left-top shadow-lg rounded-lg mx-auto my-8 border bg-white py-4'>
      <div className='flex'>
        <div className='w-full p-4'>
          <p className='text-center text-xl text-gray-600 font-semibold'>
            {t('translation:video_permissions')}
          </p>
          <VideoPermissionIllus className='w-28 mx-auto mt-4' />
          <div
            className={`mt-2 text-justify text-333333 leading-7 ${
              currentLanguageCode === 'ar' ? 'pr-4 pl-6' : 'pl-4 pr-6'
            }`}
          >
            I <b className='text-Primary'>{queryStringValues.applicant_name}</b>
            , understand that{' '}
            <b className='text-Primary'>
              {queryStringValues.company_display_name}
            </b>{' '}
            is using Smart Hire - an Applicant Tracking System to process their
            job position. I, hereby give my permission to video/audio record my
            interview/presentation for their internal use and to be accessed
            solely by members of the{' '}
            <b className='text-Primary'>
              {queryStringValues.company_display_name}
            </b>
            . I understand that the video/audio will only be available to the
            recruiter and/or hiring manager and will be stored in a secure
            location. It shall not be used for any other purpose than evaluating
            my candidacy without my explicit written permission
            <p className='py-4'>
              I also acknowledge that my permission to video/audio record my
              interview/presentation is completely voluntary. I understand at
              any point during the recording, I can quit/cancel the recording of
              my interview at an point I choose to.
            </p>
            <p>
              I release Future Look ITC,{' '}
              {queryStringValues.company_display_name}, their offices,
              employees, agents, and designees from liability for any violation
              of any personal or proprietary right I may have in connection with
              such use.
            </p>
          </div>
        </div>
      </div>
      <div className='p-field-checkbox flex justify-start px-8 mt-4'>
        <Checkbox
          inputId='terms'
          checked={checkboxValue}
          onChange={(e) => {
            setCheckboxValue(e.checked);
          }}
        />
        <label
          htmlFor='terms'
          className={`text-Primary ${
            currentLanguageCode === 'ar' ? 'pr-2' : 'pl-2'
          }`}
        >
          {t('translation:i_give_my_consent')}
        </label>
      </div>
      <div className='w-28 mx-auto my-4 '>
        {checkboxValue ? (
          <button
            onClick={() => {
              pushNotificationFn({
                company_uuid: queryStringValues?.company_name,
                applicant_id: queryStringValues?.applicant_id,
                stage: queryStringValues?.stage,
              });
              const searchString = queryString.stringify(queryStringValues);
              history.push({
                pathname: '/technical-interview/video-interview',
                search: `?${searchString}`,
              });
            }}
            className='w-28'
          >
            <PrimaryButton>{t('translation:next')}</PrimaryButton>
          </button>
        ) : (
          <button
            disabled={true}
            className='w-28 opacity-80 cursor-not-allowed'
          >
            <PrimaryButton>{t('translation:next')}</PrimaryButton>
          </button>
        )}
      </div>
    </div>
  );
}
