import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import ScrollToTop from './scrollTotop';

import PreConnection from '../pages/PreConnection';
import PersonalityProfiling from '../pages/PersonalityProfilingAssesment';
import JobApplication from 'pages/JobApplication';
import SkillAssessment from 'pages/SkillAssessment/index';
import VideoProfileAssessment from 'pages/VideoProfileAssessment/index';
import PreHireAssessment from 'pages/PreHireAssessment/index';
import TechnicalInterview from 'pages/TechnicalInterview/index';
import HRInterview from 'pages/HRInterview/index';
import PanelInterview from 'pages/PanelInterview/index';
import ApplyThroughEmail from 'pages/JobApplication/applyThrouhEmail';
import JobDetailsApplyThroughEmail from 'pages/JobApplication/applyThrouhEmail/jobDetailsApplyThroughEmail';

const AppRoutes = ({ isFullscreen, toggle, width }) => {
  return (
    <>
      <Router>
        <ScrollToTop />
        <Switch>
          <Route exact path='/'></Route>
          <Route path='/jobs/postings/:company/:jobId'>
            <JobApplication />
          </Route>
          <Route path='/jobs/apply-through-email/:company/:jobId'>
            <ApplyThroughEmail />
          </Route>
          <Route path='/jobs/apply-through-email-job-details/:company/:jobId'>
            <JobDetailsApplyThroughEmail />
          </Route>
          <Route path='/jobs/postings/:company'>
            <JobApplication />
          </Route>
          <Route path='/disc-assessment'>
            <PersonalityProfiling />
          </Route>
          <Route path='/pre-connection/:preConnectionID'>
            <PreConnection />
          </Route>
          <Route path='/skill-assessment'>
            <SkillAssessment
              toggle={toggle}
              isFullscreen={isFullscreen}
              width={width}
            />
          </Route>
          <Route path='/psychometric-assessment'>
            <VideoProfileAssessment
              toggle={toggle}
              isFullscreen={isFullscreen}
              width={width}
            />
          </Route>
          <Route path='/prehire-assessment'>
            <PreHireAssessment
              toggle={toggle}
              isFullscreen={isFullscreen}
              width={width}
            />
          </Route>
          <Route path='/technical-interview'>
            <TechnicalInterview width={width} />
          </Route>
          <Route path='/hr-interview'>
            <HRInterview width={width} />
          </Route>
          <Route path='/panel-interview'>
            <PanelInterview width={width} />
          </Route>
        </Switch>
      </Router>
    </>
  );
};

export default AppRoutes;
