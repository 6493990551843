import React, { useRef, useEffect } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import Header from './components/Header';
import MainProvider from 'contexts/mainContext';
import { useFullscreen, useToggle, useWindowSize } from 'react-use';
import { useTranslation } from 'react-i18next';

import cookies from 'js-cookie';

import AppRoutes from './routes';

import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 1,
    },
  },
});

const languages = [
  {
    code: 'en',
    name: 'English',
    country_code: 'gb',
  },
  {
    code: 'ar',
    name: 'العربية',
    dir: 'rtl',
    country_code: 'sa',
  },
];

function App() {
  const { width } = useWindowSize();
  const ref = useRef(null);
  const [show, toggle] = useToggle(false);
  const isFullscreen = useFullscreen(ref, show, {
    onClose: () => toggle(false),
  });

  const currentLanguageCode = cookies.get('i18next') || 'ar';
  // const currentLanguageCode = cookies.get('i18next') || 'ar';
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode);
  const { t } = useTranslation();

  useEffect(() => {
    // console.log('Setting page stuff');
    document.body.dir = currentLanguage.dir || 'ltr';
    localStorage.setItem('dir', JSON.stringify(currentLanguage.dir || 'ltr'));
    // document.title = t('app_title');
  }, [currentLanguage, t]);

  return (
    <div
      ref={ref}
      className={
        'App bg-gray-50 h-full bg-BgIllustration bg-no-repeat bg-fixed bg-left-top'
      }
    >
      <MainProvider>
        <QueryClientProvider client={queryClient}>
          {width > 750 ? <Header /> : null}
          <AppRoutes
            toggle={toggle}
            isFullscreen={isFullscreen}
            width={width}
          />
        </QueryClientProvider>
      </MainProvider>
    </div>
  );
}

export default App;
