import React from 'react';
import { useEffectOnce } from 'react-use';
import { useLocation } from 'react-router-dom';
import DOMPurify from 'dompurify';
import queryString from 'query-string';

import { useTranslation } from 'react-i18next';

import { ReactComponent as CancellIllus } from 'assets/images/VideoProfileAssessment/CancellIllus.svg';

const ExamCancelled = ({ toggle, clearMediaStream }) => {
  const { t } = useTranslation(['translation']);
  const location = useLocation();
  const queryStringValues = queryString.parse(location.search);
  useEffectOnce(() => {
    toggle(false);
    clearMediaStream();
  });
  return (
    <div className='mx-6 lg:mx-0'>
      <div className='relative text-center min-h-80vh max-w-screen-xl mx-auto'>
        <div className='flex min-h-80vh h-full w-full flex-col justify-center mx-auto rounded-lg bg-white shadow-lg my-8 border pt-4 pb-8 px-8'>
          <div className='h-full'>
            <div className='sucss-reg-bg w-full flex justify-center items-center my-8 md:my-12 bigtab:my-8 lg:my-8'>
              <div className=''>
                <CancellIllus />
              </div>
            </div>

            <div className='text-515151 text-xl 2xl:text-2xl'>
              {queryStringValues.ErrorMessage ? (
                <div
                  className='text-515151 flex justify-center mx-auto text-xl 2xl:text-2xl prose'
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(queryStringValues.ErrorMessage),
                  }}
                ></div>
              ) : (
                t('translation:something_went_wrong')
              )}
            </div>
            <p className='text-515151 my-4 text-sm'>
              {t('translation:you_can_close_this_window')}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExamCancelled;
