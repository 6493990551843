import React from 'react';
// import confetti from 'canvas-confetti';
import { useEffectOnce } from 'react-use';

import { useTranslation } from 'react-i18next';

import { ReactComponent as SuccessIcon } from 'assets/images/JobApplication/SuccessIcon.svg';

const RegsiterSuccess = ({ toggle }) => {
  const { t } = useTranslation(['translation']);
  // const count = 150;
  // const defaults = {
  //   origin: { y: 0.7 },
  // };

  // function fire(particleRatio, opts) {
  //   confetti(
  //     Object.assign({}, defaults, opts, {
  //       particleCount: Math.floor(count * particleRatio),
  //     })
  //   );
  // }
  useEffectOnce(() => {
    toggle(false);
    // fire(0.25, {
    //   spread: 26,
    //   startVelocity: 55,
    // });
    // fire(0.2, {
    //   spread: 60,
    // });
    // fire(0.35, {
    //   spread: 100,
    //   decay: 0.91,
    //   scalar: 0.8,
    // });
  });
  return (
    <div className='mx-6 lg:mx-0'>
      <div className='relative text-center min-h-80vh max-w-screen-xl mx-auto'>
        <div className='flex min-h-80vh bg-SuccessBgIllus bg-no-repeat bg-left-top h-full w-full flex-col justify-center mx-auto rounded-lg bg-white shadow-lg my-8 border pt-4 pb-8 px-8'>
          <div className='h-full'>
            <div className='sucss-reg-bg w-full flex justify-center items-center my-8 md:my-12 bigtab:my-8 lg:my-8'>
              <div className=''>
                <SuccessIcon />
              </div>
            </div>

            <p className='text-515151 text-xl 2xl:text-2xl'>
              {t('translation:your_assessment_has_been_submitted_successfully')}
            </p>
            <p className='text-515151 my-4 text-sm'>
              {t('translation:you_can_close_this_window')}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegsiterSuccess;
