import React, { useContext } from 'react';
import { MainContext } from 'contexts/mainContext';
import PrimaryButton from '../../../components/Buttons/ButtonPrimary';
import { useLocation, useHistory } from 'react-router-dom';
import queryString from 'query-string';
import { useQuery } from 'react-query';
import makeAPIRequest from '../../../services/makeApiRequest.js';
import apiEndPoints from '../../../services/apiEndpoints';
import dateFormatter from 'helper/dateFormatter';
import { Skeleton } from 'primereact/skeleton';

import { useTranslation } from 'react-i18next';
import cookies from 'js-cookie';

import { ReactComponent as CorrectIlls } from '../../../assets/images/SkillAssessment/correctIlls.svg';
import { ReactComponent as LinkExpired } from '../../../assets/images/VideoProfileAssessment/LinkExpired.svg';
// import { ReactComponent as CalenderIlls } from '../../../assets/images/SkillAssessment/calender.svg';
import { ReactComponent as InfoIlls } from '../../../assets/images/SkillAssessment/infoIlls.svg';
// import { ReactComponent as MainpageIlls } from '../../../assets/images/SkillAssessment/MainpageIlls.svg';
// import { ReactComponent as MainpageIlls2 } from '../../../assets/images/SkillAssessment/MainpageIlls2.svg';

export default function AssessmentInfo() {
  const currentLanguageCode = cookies.get('i18next');
  const { t } = useTranslation(['translation']);
  const { mainState, mainDispatch } = useContext(MainContext);
  const location = useLocation();
  const history = useHistory();
  let queryStringValues = queryString.parse(location.search);

  const getAssessmentInfoDetails = useQuery(
    ['getAssessmentInfoDetails'],
    async () => {
      const { data } = await makeAPIRequest.get(
        `${apiEndPoints.ASSESSMENTSTAGES}?applicant_id=${queryStringValues.applicant_id}&company_name=${queryStringValues.company_name}&stage=${queryStringValues.stage}`
      );
      return data;
    },
    {
      onSuccess: (data) => {
        mainDispatch({
          type: 'SETNAMELOGOCOLOR',
          payload: {
            company_name: data.company_name,
            company_logo: data.company_logo,
            company_color: data.company_color,
          },
        });
      },
    }
  );

  return (
    <div className='bg-gray-50 mx-auto w-full max-w-main-page min-h-85vh h-full flex items-center justify-center '>
      <div className='felx items-center felx-nowrap pt-28 pb-32 relative'>
        {getAssessmentInfoDetails?.data?.state === 'missed' ? (
          <LinkExpired className='w-20 h-20 mx-auto' />
        ) : (
          <CorrectIlls className='w-20 h-20 mx-auto' />
        )}
        {/* <MainpageIlls2 className='absolute h-52 top-28 left-20' />
        <MainpageIlls className='absolute h-72 right-8 top-20' /> */}
        {getAssessmentInfoDetails?.data?.state === 'submitted' ? null : (
          <div className='flex w-full justify-center mt-2'>
            {getAssessmentInfoDetails.isLoading ? (
              <div className='flex w-full justify-center'>
                <Skeleton
                  className={`my-auto ${
                    currentLanguageCode === 'ar' ? 'ml-2' : 'mr-2'
                  }`}
                  height='1.2rem'
                  width='1.2rem'
                />{' '}
                <Skeleton height='1.5rem' width='26rem' />
              </div>
            ) : (
              <div className='flex w-full justify-center'>
                <p className='w-32rem text-xl font-medium text-center mt-4'>
                  {getAssessmentInfoDetails?.data?.state === 'submitted' ? (
                    'Your Interview has been already completed'
                  ) : getAssessmentInfoDetails?.data?.state === 'missed' ? (
                    'You have missed the interview.'
                  ) : getAssessmentInfoDetails?.data?.state === 'scheduled' ||
                    getAssessmentInfoDetails?.data?.state === 'proceed' ? (
                    <span>
                      {' '}
                      {currentLanguageCode === 'ar'
                        ? ' جُدولت مقابلتك التقنية لتكون مقابلة عن بعد - من خلال الانترنت  '
                        : 'Your Online Technical Interview​ has be scheduled for '}
                      <br />
                      <span className='text-Primary font-bold'>
                        {dateFormatter(
                          getAssessmentInfoDetails?.data?.data?.range_info
                            ?.from_date,
                          'LLL'
                        )}
                      </span>{' '}
                    </span>
                  ) : (
                    getAssessmentInfoDetails?.data?.message
                  )}
                </p>
              </div>
            )}
          </div>
        )}

        <div>
          {getAssessmentInfoDetails?.data?.state === 'submitted' ||
          getAssessmentInfoDetails?.data?.state === 'missed' ? null : (
            <div className='w-28 mx-auto mt-12'>
              {getAssessmentInfoDetails?.data?.data?.login ? (
                <button
                  onClick={() => {
                    queryStringValues = {
                      ...queryStringValues,
                      company_display_name: mainState.company_name,
                      company_logo: mainState.company_logo,
                      company_color: mainState.company_color,
                    };
                    const searchString =
                      queryString.stringify(queryStringValues);
                    history.push({
                      pathname: '/technical-interview/instructions',
                      search: `?${searchString}`,
                    });
                  }}
                  className='w-28'
                >
                  <PrimaryButton>{t('translation:next')}</PrimaryButton>
                </button>
              ) : (
                <button
                  disabled={true}
                  className='w-28 opacity-80 cursor-not-allowed'
                >
                  <PrimaryButton>{t('translation:next')}</PrimaryButton>
                </button>
              )}
            </div>
          )}

          <div className='flex items-center justify-center mt-4'>
            {getAssessmentInfoDetails?.data?.state === 'missed' ? null : (
              // <p className='text-sm text-4F4F4F'>
              //   As you failed to take interview in given time line, link has
              //   been expired
              // </p>
              <>
                <InfoIlls
                  className={`w-4 h-4 ${
                    currentLanguageCode === 'ar' ? 'ml-1' : 'mr-1'
                  }`}
                />
                <p className='text-sm text-4F4F4F'>
                  {/* This link will be valid till <b>45 mins</b>. */}
                  {t(
                    'translation:please_ensure_your_microphone_and_webcam_are_enabled'
                  )}
                </p>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
