import React, { useState } from 'react';
import { Checkbox } from 'primereact/checkbox';
import { useLocation, useHistory } from 'react-router-dom';
import queryString from 'query-string';
import PrimaryButton from '../../../components/Buttons/ButtonPrimary';

import { useTranslation } from 'react-i18next';
import cookies from 'js-cookie';

import { ReactComponent as InstructionsIlls } from '../../../assets/images/SkillAssessment/instructionsIlls.svg';
// import { ReactComponent as PermissionsIlls } from '../../../assets/images/SkillAssessment/permissionsIlls.svg';

export default function Instructions({ useGeolocation, setGeoLocation }) {
  const currentLanguageCode = cookies.get('i18next');
  const { t } = useTranslation(['translation']);
  const [checkboxValue, setCheckboxValue] = useState(false);
  const [checkbox1Value, setCheckbox1Value] = useState(true);
  const [checkbox2Value, setCheckbox2Value] = useState(false);
  const [checkbox3Value, setCheckbox3Value] = useState(false);
  const [checkbox4Value, setCheckbox4Value] = useState(false);
  const [checkbox5Value, setCheckbox5Value] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const queryStringValues = queryString.parse(location.search);

  setGeoLocation(useGeolocation());

  return (
    <div className='w-11/12 max-w-main-page shadow-lg rounded-lg mx-auto my-8 border bg-white py-4'>
      <div className='flex'>
        <div className='w-full flex flex-col lg:flex-row p-6'>
          <div className='flex flex-col w-full lg:w-5/12'>
            <p className='text-center text-xl text-gray-600 font-semibold'>
              {t('translation:instructions')}
            </p>
            <InstructionsIlls className='my-auto mt-16 mx-auto' />
          </div>
          <div className='flex flex-col mt-6 lg:mt-0 w-full lg:w-7/12 px-2 text-333333 text-justify'>
            <div className='flex my-3'>
              <Checkbox
                inputId='check1'
                className={`${currentLanguageCode === 'ar' ? 'ml-2' : 'mr-2'}`}
                disabled={true}
                checked={checkbox1Value}
                onChange={(e) => {
                  setCheckbox1Value(e.checked);
                }}
              />
              <p>
                {t(
                  'translation:your_login_credentials_shall_be_active_only_for_the_duration_of_the_scheduled_assessment'
                )}
              </p>
            </div>
            <div className='flex my-3'>
              <Checkbox
                inputId='check2'
                className={`${currentLanguageCode === 'ar' ? 'ml-2' : 'mr-2'}`}
                disabled={!checkbox1Value}
                checked={checkbox2Value}
                onChange={(e) => {
                  setCheckbox2Value(e.checked);
                }}
              />
              <p className={`${!checkbox1Value ? 'opacity-60' : ''}`}>
                {t(
                  'translation:please_do_not_refresh_your_browser_once_you_login'
                )}
              </p>
            </div>
            <div className='flex my-3'>
              <Checkbox
                inputId='check3'
                className={`${currentLanguageCode === 'ar' ? 'ml-2' : 'mr-2'}`}
                disabled={!checkbox1Value || !checkbox2Value}
                checked={checkbox3Value}
                onChange={(e) => {
                  setCheckbox3Value(e.checked);
                }}
              />
              <p
                className={`${
                  !checkbox1Value || !checkbox2Value ? 'opacity-60' : ''
                }`}
              >
                {t(
                  'translation:please_do_not_close_your_browser_until_the_end_of_the_assessment'
                )}
              </p>
            </div>
            <div className='flex my-3'>
              <span>
                <Checkbox
                  inputId='check4'
                  className={`${
                    currentLanguageCode === 'ar' ? 'ml-2' : 'mr-2'
                  }`}
                  disabled={
                    !checkbox1Value || !checkbox2Value || !checkbox3Value
                  }
                  checked={checkbox4Value}
                  onChange={(e) => {
                    setCheckbox4Value(e.checked);
                  }}
                />
              </span>
              <p
                className={`flex ${
                  !checkbox1Value || !checkbox2Value || !checkbox3Value
                    ? 'opacity-60'
                    : ''
                }`}
              >
                {t(
                  'translation:once_you_start_your_assessment_full_screen_activate'
                )}
              </p>
            </div>
            <div className='flex my-3'>
              <span>
                <Checkbox
                  inputId='check5'
                  className={`${
                    currentLanguageCode === 'ar' ? 'ml-2' : 'mr-2'
                  }`}
                  disabled={
                    !checkbox1Value ||
                    !checkbox2Value ||
                    !checkbox3Value ||
                    !checkbox4Value
                  }
                  checked={checkbox5Value}
                  onChange={(e) => {
                    setCheckbox5Value(e.checked);
                  }}
                />
              </span>
              <p
                className={`flex font-bold ${
                  !checkbox1Value ||
                  !checkbox2Value ||
                  !checkbox3Value ||
                  !checkbox4Value
                    ? 'opacity-60'
                    : ''
                }`}
              >
                {t(
                  'translation:existing_full_screen_will_result_in_deactivation'
                )}
              </p>
            </div>
            <div className='p-field-checkbox flex  my-3'>
              <Checkbox
                inputId='terms'
                disabled={
                  !checkbox1Value ||
                  !checkbox2Value ||
                  !checkbox3Value ||
                  !checkbox4Value ||
                  !checkbox5Value
                }
                checked={checkboxValue}
                onChange={(e) => {
                  setCheckboxValue(e.checked);
                }}
              />
              <label
                htmlFor='terms'
                className={`text-Primary ${
                  currentLanguageCode === 'ar' ? 'pr-2' : 'pl-2'
                }`}
              >
                {t('translation:i_give_my_consent')}
              </label>
            </div>
          </div>
        </div>
        {/* <div className='w-1/2 p-4'>
          <p className='text-center text-xl text-gray-600 font-semibold'>
            Permissions
          </p>
          <PermissionsIlls className='w-28 mx-auto mt-4' />
          <div className='pl-4 pr-6 mt-2 text-justify text-333333 leading-7'>
            I {queryStringValues.applicant_name}, understand the{' '}
            {queryStringValues.company_display_name} is using AptaHR - an
            Applicant Tracking System to process their jopb position and hereby
            give my permission for{' '}
            <span className='font-semibold'>screen recording</span> my
            assessment/presentation for internal use and to be accessed solely
            by members of the {queryStringValues.company_display_name}. I
            understand that the screen recording only be available to the
            Recruiter and/or hiring manager and will be stored in a secured
            location and will not be used for any other purpose than evaluating
            my candidacy without my explicit written permission.
            <p className='py-4'>
              I also acknowledge that my screen recording is completely
              voluntary.
            </p>
            <p>
              I release Aptagrim Consulting,{' '}
              {queryStringValues.company_display_name}, their offices,
              employees, agents, and designees from liability for any violation
              of any personal or proprietary right I may have in connection with
              such use.
            </p>
          </div>
        </div> */}
      </div>

      <div className='w-28 mx-auto my-4 '>
        {checkboxValue &&
        checkbox1Value &&
        checkbox2Value &&
        checkbox3Value &&
        checkbox4Value &&
        checkbox5Value ? (
          <button
            onClick={() => {
              const searchString = queryString.stringify(queryStringValues);
              history.push({
                pathname: '/skill-assessment/login',
                search: `?${searchString}`,
              });
            }}
            className='w-28'
          >
            <PrimaryButton>{t('translation:next')}</PrimaryButton>
          </button>
        ) : (
          <button
            disabled={true}
            className='w-28 opacity-80 cursor-not-allowed'
          >
            <PrimaryButton>{t('translation:next')}</PrimaryButton>
          </button>
        )}
      </div>
    </div>
  );
}
