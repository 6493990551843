import React from 'react';
import PrimaryButton from '../../../components/Buttons/ButtonPrimary';
import { useLocation, useHistory } from 'react-router-dom';
import queryString from 'query-string';

import cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';

import { ReactComponent as JobIlls } from '../../../assets/images/SkillAssessment/jobIlls.svg';
import { ReactComponent as TimeIlls } from '../../../assets/images/SkillAssessment/TimeIlls.svg';
import { ReactComponent as Shortanslls } from '../../../assets/images/SkillAssessment/shortansIlls.svg';

export default function AssessmentOverviewVideo({
  getVideoProfileAssessmentExamData,
  isFullscreen,
}) {
  const currentLanguageCode = cookies.get('i18next');
  const { t } = useTranslation(['translation']);
  const location = useLocation();
  const history = useHistory();
  const queryStringValues = queryString.parse(location.search);

  // console.log(isFullscreen);

  const secToMin = (value) => {
    const minutes = Math.floor(value / 60);
    return (
      minutes.toString().padStart(2, '0') +
      ':' +
      (value - minutes * 60).toString().padStart(2, '0')
    );
  };

  return (
    <div className='flex flex-col w-11/12 bg-white min-h-80vh mx-auto border rounded-lg shadow-lg my-8'>
      <div className='bg-Primary rounded-t-lg'>
        <p className='text-2xl text-white text-center pt-6'>
          {queryStringValues.stageName
            ? queryStringValues.stageName
            : 'Psychometric Assessment'}
        </p>
        <div className='flex items-center justify-center text-white pt-4 pb-6'>
          <JobIlls
            className={`w-4 h-4 mt-0.5 ${
              currentLanguageCode === 'ar' ? 'ml-2' : 'mr-2'
            }`}
          />
          <p
            className={`${
              currentLanguageCode === 'ar' ? 'pl-l ml-2' : 'pr-4 mr-2'
            }`}
          >
            {getVideoProfileAssessmentExamData?.data?.data?.job_title}
          </p>
          <TimeIlls
            className={`w-4 h-4 mt-0.5 ${
              currentLanguageCode === 'ar' ? 'ml-2' : 'mr-2'
            }`}
          />
          <p
            className={`${
              currentLanguageCode === 'ar' ? 'pl-4 ml-2' : 'pr-4 mr-2'
            }`}
          >
            {secToMin(
              getVideoProfileAssessmentExamData?.data?.data?.total_time
            )}{' '}
            Min:Sec
          </p>
          {/* <p>Total time - {secToMin(600)} Min(s):Sec(s)</p> */}
        </div>
      </div>
      <div className='flex flex-col justify-center items-center my-auto h-full'>
        <div className='flex justify-around sm:w-full lg:w-3/4 mx-auto'>
          <div className='shadow-lg border rounded-lg lg:w-52 sm:w-48 relative'>
            <p className='text-Primary font-bold py-4 whitespace-nowrap md:text-sm lg:text-normal text-center'>
              {t('translation:total_questions')}
            </p>
            <hr className='border-Primary mx-4 opacity-30' />
            <p className='font-semibold text-xl text-center pb-16 pt-8'>
              {getVideoProfileAssessmentExamData?.data?.data?.PSY_count}
            </p>
            <Shortanslls className='h-12 absolute right-4 bottom-4' />
          </div>
        </div>
        <div className='w-40 mx-auto'>
          <button
            onClick={() => {
              const searchString = queryString.stringify(queryStringValues);
              history.push({
                pathname: '/prehire-assessment/psy-test',
                search: `?${searchString}`,
              });
            }}
            className='pt-16 w-40'
          >
            <PrimaryButton>{t('translation:start_assessment')}</PrimaryButton>
          </button>
        </div>
      </div>
    </div>
  );
}
