import React from 'react';

import { useParams, useHistory } from 'react-router-dom';

import ViewJobDetails from '../components/viewJobDetails';
import useJobDetails from '../../../hooks/useGetJobDetails';

const JobDetailsApplyThroughEmail = () => {
  const { company, jobId } = useParams();
  const history = useHistory();

  const getApplicationFormJobData = useJobDetails({
    enable: !!jobId,
    jobId,
    company,
  });

  return (
    <div className='max-w-screen-xl mx-auto mt-8'>
      <button onClick={() => history.goBack()}>
        <svg
          width='22'
          height='23'
          viewBox='0 0 22 23'
          fill='none'
          xmlns='https://www.w3.org/2000/svg'
          className='ml-1'
        >
          <path
            d='M12.3276 21.7407C12.4956 21.5741 12.6289 21.3758 12.7199 21.1574C12.8109 20.939 12.8577 20.7048 12.8577 20.4682C12.8577 20.2316 12.8109 19.9973 12.7199 19.7789C12.6289 19.5605 12.4956 19.3623 12.3276 19.1957L6.41317 13.2992L20.0163 13.2992C20.4917 13.2992 20.9475 13.1103 21.2837 12.7742C21.6198 12.4381 21.8086 11.9823 21.8086 11.5069C21.8086 11.0316 21.6198 10.5757 21.2837 10.2396C20.9475 9.9035 20.4917 9.71467 20.0163 9.71467L6.41317 9.71467L12.3276 3.81817C12.6651 3.48069 12.8547 3.02296 12.8547 2.54568C12.8547 2.0684 12.6651 1.61067 12.3276 1.27318C11.9901 0.935692 11.5324 0.746093 11.0551 0.746093C10.5778 0.746093 10.1201 0.935692 9.7826 1.27318L0.821353 10.2344C0.658187 10.4049 0.530281 10.6059 0.44498 10.8259C0.35016 11.0404 0.301183 11.2724 0.301183 11.5069C0.301183 11.7415 0.35016 11.9734 0.44498 12.188C0.530281 12.408 0.658187 12.609 0.821353 12.7794L9.7826 21.7407C9.94921 21.9086 10.1474 22.042 10.3658 22.133C10.5842 22.224 10.8185 22.2708 11.0551 22.2708C11.2917 22.2708 11.526 22.224 11.7444 22.133C11.9628 22.042 12.161 21.9086 12.3276 21.7407Z'
            fill='#EB249E'
          />
        </svg>
      </button>

      <ViewJobDetails
        jobId={!!jobId}
        getApplicationFormJobData={getApplicationFormJobData}
        currentJobId={jobId}
        isAppliedThroughEmail
      />
    </div>
  );
};

export default JobDetailsApplyThroughEmail;
