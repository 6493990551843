import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import queryString from 'query-string';
import PrimaryButton from '../../../components/Buttons/ButtonPrimary';
import useMediaRecorder from '@wmik/use-media-recorder';
import LiveVideoPlayer from './LiveVideoPlayer';
import VideoPlayer from './videoPlayer';
import LottiLoader from '../../../components/LottiLoader';
import { useGeolocation } from 'react-use';

import { useTranslation } from 'react-i18next';
import cookies from 'js-cookie';

export default function VideoTest({
  getMediaStream,
  statusMain,
  setGeoLocation,
}) {
  const { t } = useTranslation(['translation']);
  const currentLanguageCode = cookies.get('i18next');
  const [isVideoPlayed, setIsVideoPlayed] = useState(false);
  const [isVideoHasAudio, setIsVideoHasAudio] = useState(false);

  const location = useLocation();
  const history = useHistory();
  const queryStringValues = queryString.parse(location.search);

  const geoLocation = useGeolocation();

  const vidRef = useRef(null);

  const playFn = () => {
    vidRef.current.play();
  };

  useEffect(() => {
    getMediaStream();
    setGeoLocation(geoLocation);
    // eslint-disable-next-line
  }, []);

  let {
    status,
    mediaBlob,
    stopRecording,
    clearMediaStream,
    startRecording,
    liveStream,
  } = useMediaRecorder({
    recordScreen: false,
    blobOptions: {
      type: `${window?.safari !== undefined ? 'video/mp4' : 'video/webm'}`,
    },
    mediaStreamConstraints: {
      audio: true,
      video: {
        facingMode: 'user',
        width: { ideal: 1280, max: 1920 },
        height: { ideal: 720, max: 1080 },
        frameRate: { ideal: 30, max: 60 },
      },
    },
  });

  return (
    <div className='bg-gray-50 max-w-5xl min-h-85vh flex flex-col w-full pt-8 mx-auto'>
      <div className='flex text-2A2515 select-none flex-grow bg-white w-11/12 mx-auto border rounded-lg'>
        <div className='w-full flex flex-col'>
          <div className='flex flex-col justify-center items-center w-full h-24 bg-Primary rounded-t-lg'>
            <p className='text-xl mt-2 text-white font-bold'>
              {t('translation:audio_and_video_test')}
            </p>
            {/* <p className='text-lg my-1 text-white'>
              Click on start recording to test your audio video
            </p> */}
          </div>
          <div className='flex h-full max-h-65vh flex-col justify-between'>
            <div className='flex items-center flex-col h-full max-h-full overflow-hidden'>
              <p className='text-lg text-black my-3'>
                "
                {t(
                  'translation:click_on_start_recording_to_test_your_audio_video'
                )}
                "
              </p>
              <div className='flex h-full max-h-45vh'>
                {status === 'acquiring_media' || status === 'stopping' ? (
                  <div className='min-h-45vh h-full flex items-center justify-center'>
                    <LottiLoader width={'30px'} height={'30px'} />
                  </div>
                ) : liveStream ? (
                  <LiveVideoPlayer stream={liveStream} />
                ) : mediaBlob ? (
                  <VideoPlayer
                    srcBlob={mediaBlob}
                    vidRef={vidRef}
                    setIsVideoPlayed={setIsVideoPlayed}
                    setIsVideoHasAudio={setIsVideoHasAudio}
                  />
                ) : status !== 'idle' && status !== 'ready' ? (
                  <div className='min-h-45vh h-full flex items-center justify-center'>
                    <LottiLoader width={'30px'} height={'30px'} />
                  </div>
                ) : null}
              </div>
            </div>
            <div className='flex flex-col mx-4 max-h-30vh'>
              <div className='flex justify-center'>
                {status === 'failed' ? (
                  <div className='flex mt-4'>
                    <p className='text-red-600'>
                      * {t('translation:unable_to_detect')}
                    </p>
                  </div>
                ) : null}
              </div>
              <div className='flex justify-between my-4'>
                <div className='flex w-32'></div>
                <div>
                  {status !== 'recording' ? (
                    <button
                      onClick={() => {
                        setIsVideoPlayed(false);
                        clearMediaStream();
                        startRecording();
                      }}
                      disabled={status === 'recording'}
                      className='w-36'
                    >
                      <PrimaryButton>
                        {t('translation:start_recording')}
                      </PrimaryButton>
                    </button>
                  ) : (
                    <button
                      onClick={stopRecording}
                      disabled={status !== 'recording'}
                      className='w-36'
                    >
                      <PrimaryButton>
                        {t('translation:stop_recording')}
                      </PrimaryButton>
                    </button>
                  )}
                  <button
                    onClick={() => {
                      playFn();
                    }}
                    disabled={!mediaBlob || liveStream ? true : false}
                    className={`w-28 ${
                      !mediaBlob || liveStream
                        ? 'opacity-60 cursor-not-allowed'
                        : ''
                    } ${currentLanguageCode === 'ar' ? 'ml-4' : 'mr-4'}`}
                  >
                    <PrimaryButton>{t('translation:play_video')}</PrimaryButton>
                  </button>
                </div>
                <div className='flex w-32'>
                  <button
                    onClick={() => {
                      clearMediaStream();
                      const searchString =
                        queryString.stringify(queryStringValues);
                      history.push({
                        pathname:
                          '/prehire-assessment/instructions-video-profile',
                        search: `?${searchString}`,
                      });
                    }}
                    disabled={
                      mediaBlob && isVideoPlayed && isVideoHasAudio
                        ? false
                        : true
                    }
                    className={`w-28 ${
                      mediaBlob && isVideoPlayed && isVideoHasAudio
                        ? ''
                        : 'opacity-60 cursor-not-allowed'
                    } ${currentLanguageCode === 'ar' ? 'ml-4' : 'mr-4'}`}
                  >
                    <PrimaryButton>{t('translation:proceed')}</PrimaryButton>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
