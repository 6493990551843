const dayjs = require('dayjs');

var localizedFormat = require('dayjs/plugin/localizedFormat');
dayjs.extend(localizedFormat);

/**
 *
 * @param {Date} date
 * @param {string} format
 * @returns date
 */

const dateFormatter = (date, format) => {
  return dayjs(date).format(format);
};

export default dateFormatter;
