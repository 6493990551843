import React from 'react';

import { ReactComponent as LaptopIllus } from 'assets/images/LaptopIllus.svg';
import { ReactComponent as UnhappyFaceIllus } from 'assets/images/UnhappyFaceIllus.svg';

function NoMobile() {
  return (
    <div className='min-h-90vh flex items-center bg-drop-background'>
      {/* <Logo className='w-1/2 mx-auto mb-6 pt-4'/> */}
      <div className='relative bg-white shadow-xl min-h-60vh flex flex-col items-center text-center w-10/12 mx-auto border rounded-md'>
        <LaptopIllus className='w-4/5 mt-16 mb-8 mx-auto' />
        {/* <p className='text-Primary text-tag-not-scheduled text-2xl pb-2 font-bold'>
          We’re sorry!
        </p> */}
        <p className='text-sm text-141414 px-4'>
          This web application can not be accessed in mobile
        </p>
        <p className='text-sm text-141414 px-4 pt-1 mb-20'>
          Please login through a laptop or desktop
        </p>
        <UnhappyFaceIllus className='absolute bottom-8 w-full mx-auto' />
      </div>
    </div>
  );
}

export default NoMobile;
