import React from 'react';
import { Dialog } from 'primereact/dialog';
import SecondaryButton from 'components/Buttons/SecondaryButton';
import PrimaryButton from 'components/Buttons/ButtonPrimary';

import { useTranslation } from 'react-i18next';
import cookies from 'js-cookie';

import { ReactComponent as SubmitIllus } from 'assets/images/SkillAssessment/SubmitIllus.svg';

function SubmitPopOver({
  isVisible,
  setVisible,
  onSubmit,
  skillAssessmentSubmitMutation,
}) {
  const { t } = useTranslation(['translation']);
  const currentLanguageCode = cookies.get('i18next');

  const header = (
    <div>
      {/* <div className='flex justify-center'>
        <div className='w-10 h-10'></div>
        <p className='text-center flex-1 text-primary-orange mt-4 text-2xl'>
          Create your assessment
        </p>
        <div className='w-10 h-10'></div>
      </div> */}
      <p className='text-center mx-auto text-515151 mt-2 text-xl'>
        {t('translation:are_you_sure_you_want_to_submit_the_skill_assessment')}
      </p>
    </div>
  );
  return (
    <div>
      <Dialog
        header={header}
        visible={isVisible}
        onHide={() => setVisible(false)}
        style={{
          // marginTop: '4rem',
          borderRadius: '1rem',
        }}
        className='w-10/12 max-w-2xl h-70vh  rounded-lg'
        modal
        appendTo='self'
        draggable={false}
        dismissableMask={true}
        blockScroll={true}
        closable={false}
        position='center'
        resizable={false}
      >
        <div className='relative w-full h-full px-10 pb-6 z-40'>
          <div className='flex w-full justify-center mt-4 mb-6 h-full'>
            <SubmitIllus />
          </div>
          <div className='flex w-full justify-center h-full'>
            <button
              onClick={() => onSubmit()}
              className={`w-32 whitespace-nowrap ${currentLanguageCode === 'ar' ? 'ml-4' : 'mr-4'}`}
            >
              <PrimaryButton
                isLoading={skillAssessmentSubmitMutation?.isLoading}
              >
                {t('translation:submit')}
              </PrimaryButton>
            </button>
            <button
              onClick={() => {
                setVisible(false);
              }}
              className={`w-32 whitespace-nowrap`}
            >
              <SecondaryButton>{t('translation:cancel')}</SecondaryButton>
            </button>
          </div>
        </div>
      </Dialog>
    </div>
  );
}

export default SubmitPopOver;
