import React, { useEffect, useContext, useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useQuery, useMutation } from 'react-query';
import { MainContext } from 'contexts/mainContext';
import { useLocation, useHistory } from 'react-router-dom';
import queryString from 'query-string';
import makeAPIRequest from 'services/makeApiRequest.js';
import apiEndPoints from 'services/apiEndpoints';
// import { ErrorToast } from 'services/toasterService.js';
import AssessmentInfo from './Components/AssessmentInfo';
import Assessmentlogin from './Components/login';
import Instructions from './Components/instructions';
import InstructionsVideo from './Components/instructionsVideo';
import TipsForVideoProfile from './Components/TipsForVideoProfile';
import VideoTest from './Components/VideoTest';
import InstructionsVideoProfile from './Components/instructionsVideoProfile';
import AssessmentOverviewVideo from './Components/AssessmentOverviewVideo';
import AssessmentTestPsy from './Components/assessmentTestPsy';
import AssessmentTest from './Components/assessmentTest';
import RegsiterSuccess from './Components/registerSuccess';
import ExamCancelled from './Components/ExamCancelled';
import useMediaRecorder from '@wmik/use-media-recorder';
import NoMobile from 'helper/NoMobile';
import PreHireOverview from './Components/PreHireOverview';
import AssessmentOverview from './Components/AssessmentOverview';

import { useGeolocation } from 'react-use';

export default function PreHireAssessment({ isFullscreen, toggle, width }) {
  const { mainDispatch } = useContext(MainContext);
  const history = useHistory();
  const location = useLocation();
  const queryStringValues = queryString.parse(location.search);
  const [additional_info, setAdditional_info] = useState();
  const [blobFiles, setBlobFiles] = useState([]);
  const [geoLocation, setGeoLocation] = useState();
  const [cancelExam, setCancelExam] = useState(false);

  const getVideoProfileAssessmentExamData = useQuery(
    ['getVideoProfileAssessmentExamData'],
    async () => {
      const { data } = await makeAPIRequest.get(
        `${apiEndPoints.SKILLASSESSMENTEXAM}?company=${queryStringValues.company_name}&job_id=${queryStringValues.job_id}&psy_ques=true`
      );
      return data;
    },
    {
      enabled: !!queryStringValues.job_id,
      onSuccess: (data) => {
        if (data.data.total_count === 0) {
          const searchString = {
            ErrorMessage: 'No Questions Found!',
            ...queryStringValues,
          };
          const newerrorsearchString = queryString.stringify(searchString);
          history.push({
            pathname: '/prehire-assessment/cancelled',
            search: `?${newerrorsearchString}`,
          });
        }
      },
      onError: (err) => {
        const newsearchString = {
          ErrorMessage: err.response.data.message,
          ...queryStringValues,
        };
        const newerrorsearchString = queryString.stringify(newsearchString);
        history.push({
          pathname: '/prehire-assessment/cancelled',
          search: `?${newerrorsearchString}`,
        });
      },
    }
  );

  const getSkillAssessmentExamData = useQuery(
    ['getSkillAssessmentExamData'],
    async () => {
      const { data } = await makeAPIRequest.get(
        `${apiEndPoints.SKILLASSESSMENTEXAM}?company=${queryStringValues.company_name}&job_id=${queryStringValues.job_id}`
      );
      return data;
    },
    {
      enabled: !!queryStringValues.job_id,
      onSuccess: (data) => {
        if (data.data.total_count === 0) {
          const searchString = {
            ErrorMessage: 'No Questions Found!',
            ...queryStringValues,
          };
          const newerrorsearchString = queryString.stringify(searchString);
          history.push({
            pathname: '/prehire-assessment/cancelled',
            search: `?${newerrorsearchString}`,
          });
        }
      },
      onError: (err) => {
        const newsearchString = {
          ErrorMessage: err.response.data.message,
          ...queryStringValues,
        };
        const newerrorsearchString = queryString.stringify(newsearchString);
        history.push({
          pathname: '/prehire-assessment/cancelled',
          search: `?${newerrorsearchString}`,
        });
      },
    }
  );

  const onStopFunction = (blob) => {
    setBlobFiles([...blobFiles, blob]);
  };

  useEffect(() => {
    if (
      blobFiles.length ===
      getVideoProfileAssessmentExamData?.data?.data?.PSY?.length
    ) {
      submitVideoExam();
    }
    // eslint-disable-next-line
  }, [blobFiles, getVideoProfileAssessmentExamData?.data?.data?.PSY?.length]);

  useEffect(() => {
    if (!!cancelExam) {
      submitVideoExam('cancelled');
    }
    // eslint-disable-next-line
  }, [cancelExam]);

  // submit
  const videoProfileSubmitMutation = useMutation(async (data) => {
    const res = await makeAPIRequest.post(
      apiEndPoints.SKILLASSESSMENTEXAM,
      data
    );
    return res.data;
  });

  const submitVideoExam = (cancelled) => {
    let FinalDataFormData = new FormData();
    FinalDataFormData.append('company_name', queryStringValues.company_name);
    FinalDataFormData.append('stage', queryStringValues.stage);
    FinalDataFormData.append('applicant_id', queryStringValues.applicant_id);
    FinalDataFormData.append('psychometric', 'True');
    const add_info = {
      ...additional_info,
      geo_location: geoLocation,
    };
    FinalDataFormData.append('additional_info', JSON.stringify(add_info));
    if (cancelled === 'cancelled') {
      FinalDataFormData.append('cancelled', 'True');
    } else {
      FinalDataFormData.append('cancelled', 'False');
      getVideoProfileAssessmentExamData?.data?.data?.PSY?.forEach(
        (question, index) => {
          FinalDataFormData.append(question._id, blobFiles[index]);
        }
      );
    }

    videoProfileSubmitMutation.mutate(FinalDataFormData, {
      onSuccess: (data) => {
        const searchString = queryString.stringify(queryStringValues);
        clearMediaStream();
        if (data.message === 'cancelled') {
          const newsearchString = {
            ErrorMessage: 'Your assessment has been cancelled.',
            ...queryStringValues,
          };
          const newerrorsearchString = queryString.stringify(newsearchString);
          history.push({
            pathname: '/prehire-assessment/cancelled',
            search: `?${newerrorsearchString}`,
          });
        } else {
          history.push({
            pathname: '/prehire-assessment/success',
            search: `?${searchString}`,
          });
        }
      },
      onError: (err) => {
        clearMediaStream();
        const searchString = queryString.stringify(queryStringValues);
        if (err.response.data.message === 'Already Completed.') {
          history.push({
            pathname: '/prehire-assessment',
            search: `?${searchString}`,
          });
        } else {
          const newsearchString = {
            ErrorMessage: err.response.data.message,
            ...queryStringValues,
          };
          const newerrorsearchString = queryString.stringify(newsearchString);
          history.push({
            pathname: '/prehire-assessment/cancelled',
            search: `?${newerrorsearchString}`,
          });
        }
      },
    });
    for (var pair of FinalDataFormData.entries()) {
      console.log(pair[0] + ', ' + pair[1]);
    }
  };

  let {
    error,
    status,
    mediaBlob,
    stopRecording,
    getMediaStream,
    clearMediaStream,
    startRecording,
    liveStream,
  } = useMediaRecorder({
    recordScreen: false,
    blobOptions: {
      type: 'video/webm',
    },
    onStop: onStopFunction,
    mediaStreamConstraints: {
      audio: true,
      video: {
        facingMode: 'user',
        width: 720,
        height: 480,
        frameRate: { ideal: 15, max: 30 },
      },
    },
  });

  useEffect(() => {
    if (queryStringValues?.company_display_name) {
      mainDispatch({
        type: 'SETNAMELOGOCOLOR',
        payload: {
          company_name: queryStringValues?.company_display_name,
          company_logo: queryStringValues?.company_logo,
          company_color: queryStringValues?.company_color,
        },
      });
    }
    // eslint-disable-next-line
  }, []);

  if (width < 750) {
    return <NoMobile />;
  }

  // console.log(blobFiles);
  return (
    <div className='bg-gray-50'>
      <Switch>
        <Route exact path='/prehire-assessment'>
          <AssessmentInfo />
        </Route>
        <Route exact path='/prehire-assessment/login'>
          <Assessmentlogin width={width} toggle={toggle} />
        </Route>
        <Route exact path='/prehire-assessment/prehire-overview'>
          <PreHireOverview
            isFullscreen={isFullscreen}
            getVideoProfileAssessmentExamData={
              getVideoProfileAssessmentExamData
            }
            getSkillAssessmentExamData={getSkillAssessmentExamData}
            toggle={toggle}
          />
        </Route>
        <Route exact path='/prehire-assessment/instructions'>
          <Instructions
            toggle={toggle}
            useGeolocation={useGeolocation}
            setGeoLocation={setGeoLocation}
          />
        </Route>
        <Route exact path='/prehire-assessment/overview'>
          <AssessmentOverview
            isFullscreen={isFullscreen}
            getSkillAssessmentExamData={getSkillAssessmentExamData}
          />
        </Route>
        <Route exact path='/prehire-assessment/test'>
          <AssessmentTest
            isFullscreen={isFullscreen}
            getSkillAssessmentExamData={getSkillAssessmentExamData}
            toggle={toggle}
            geoLocation={geoLocation}
          />
        </Route>
        <Route exact path='/prehire-assessment/instructionsVideo'>
          <InstructionsVideo />
        </Route>
        <Route exact path='/prehire-assessment/tips-video-profile'>
          <TipsForVideoProfile />
        </Route>
        <Route exact path='/prehire-assessment/instructions-video-profile'>
          <InstructionsVideoProfile status={status} />
        </Route>
        <Route exact path='/prehire-assessment/video-test'>
          <VideoTest
            getMediaStream={getMediaStream}
            statusMain={status}
            setGeoLocation={setGeoLocation}
          />
        </Route>

        <Route exact path='/prehire-assessment/overviewVideo'>
          <AssessmentOverviewVideo
            getVideoProfileAssessmentExamData={
              getVideoProfileAssessmentExamData
            }
          />
        </Route>
        <Route exact path='/prehire-assessment/psy-test'>
          <AssessmentTestPsy
            getVideoProfileAssessmentExamData={
              getVideoProfileAssessmentExamData
            }
            error={error}
            status={status}
            mediaBlob={mediaBlob}
            stopRecording={stopRecording}
            startRecording={startRecording}
            liveStream={liveStream}
            videoProfileSubmitMutation={videoProfileSubmitMutation}
            submitVideoExam={submitVideoExam}
            setAdditional_info={setAdditional_info}
            clearMediaStream={clearMediaStream}
            isFullscreen={isFullscreen}
            setCancelExam={setCancelExam}
          />
        </Route>
        <Route exact path='/prehire-assessment/success'>
          <RegsiterSuccess toggle={toggle} />
        </Route>
        <Route exact path='/prehire-assessment/cancelled'>
          <ExamCancelled clearMediaStream={clearMediaStream} toggle={toggle} />
        </Route>
      </Switch>
    </div>
  );
}
