import React from 'react';
import { Route, Switch } from 'react-router-dom';
import AssessmentOverview from './Components/AssessmentExample';
import DiscAssessment from './Components/DiscAssessment';

export default function PersonalityProfiling() {
  return (
    <div>
      <Switch>
        <Route exact path="/disc-assessment" component={AssessmentOverview}/>
        <Route exact path='/disc-assessment/:test' component={DiscAssessment}/>
      </Switch>
    </div>
  );
}
