import React from 'react';

function LiveVideoPlayer({ stream }) {
  let videoPreviewRef = React.useRef();

  React.useEffect(() => {
    if (videoPreviewRef.current && stream) {
      videoPreviewRef.current.srcObject = stream;
    }
  }, [stream]);

  if (!stream) {
    return null;
  }
  // w-3/4 mx-auto mt-4 h-full video-flipped overflow-hidden object-cover rounded-lg
  // w-full h-full video-flipped overflow-hidden object-cover rounded-t-lg
  // w-full h-full video-flipped overflow-hidden rounded-t-lg

  return (
    <div className='flex relative justify-center items-center overflow-hidden h-full'>
      <div className='h-full my-auto inline-flex justify-center'>
        <div className='absolute z-50 top-2'>
          <lottie-player
            src='https://assets1.lottiefiles.com/packages/lf20_8Wx6bV.json'
            background='transparent'
            speed='1'
            style={{ width: '2rem', height: 'auto' }}
            loop
            autoplay
          ></lottie-player>
        </div>
        <video
          ref={videoPreviewRef}
          className='h-full my-auto rounded-lg overflow-hidden video-flipped'
          autoPlay
        />
      </div>
    </div>
  );
}

export default LiveVideoPlayer;
