import React from 'react';

function VideoPlayer({
  srcBlob,
  audio,
  vidRef,
  setIsVideoPlayed,
  setIsVideoHasAudio,
}) {
  if (!srcBlob) {
    return null;
  }

  if (audio) {
    return <audio src={URL.createObjectURL(srcBlob)} controls />;
  }

  return (
    <div className='flex mx-auto justify-center items-center overflow-hidden h-full'>
      <video
        ref={vidRef}
        src={URL.createObjectURL(srcBlob)}
        className='h-full my-auto rounded-lg overflow-hidden'
        controls
        onPlaying={() => {
          setIsVideoPlayed(true);
        }}
        onPlay={() => {
          //
          // console.log(
          //   vidRef?.current?.mozHasAudio ||
          //     Boolean(vidRef?.current?.webkitAudioDecodedByteCount) ||
          //     Boolean(
          //       vidRef?.current?.audioTracks &&
          //         vidRef?.current?.audioTracks.length
          //     ),
          //   'Has Audio'
          // );
          setIsVideoHasAudio(
            vidRef?.current?.mozHasAudio ||
              Boolean(vidRef?.current?.webkitAudioDecodedByteCount) ||
              Boolean(
                vidRef?.current?.audioTracks &&
                  vidRef?.current?.audioTracks.length
              )
          );
          // vidRef?.current?.addEventListener('play', function () {
          //   // var audio = Boolean(video.webkitAudioDecodedByteCount);

          // });
        }}
      />
    </div>
  );
}

export default React.memo(VideoPlayer);
