import React, { useState } from 'react';
import { Checkbox } from 'primereact/checkbox';
import { useLocation, useHistory } from 'react-router-dom';
import queryString from 'query-string';
import PrimaryButton from '../../../components/Buttons/ButtonPrimary';

import { useTranslation } from 'react-i18next';
import cookies from 'js-cookie';

import { ReactComponent as InstructionsIlls } from '../../../assets/images/SkillAssessment/instructionsIlls.svg';
export default function InstructionsVideoProfile() {
  const { t } = useTranslation(['translation']);
  const currentLanguageCode = cookies.get('i18next');
  const [checkboxValue, setCheckboxValue] = useState(false);
  const [checkbox1Value, setCheckbox1Value] = useState(true);
  const [checkbox2Value, setCheckbox2Value] = useState(false);
  const [checkbox3Value, setCheckbox3Value] = useState(false);
  const [checkbox4Value, setCheckbox4Value] = useState(false);
  const [checkbox5Value, setCheckbox5Value] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const queryStringValues = queryString.parse(location.search);

  return (
    <div className='w-11/12 max-w-main-page bg-BgArrowIllus bg-no-repeat bg-left-top shadow-lg rounded-lg mx-auto my-8 border bg-white py-4'>
      <div className='flex w-full flex-col lg:flex-row p-6'>
        <div className='flex flex-col w-full lg:w-5/12'>
          <p className='text-center text-xl text-gray-600 font-semibold'>
            {t('translation:instructions')}
          </p>
          <InstructionsIlls className='my-auto mt-16 mx-auto' />
        </div>
        <div className='flex flex-col mt-6 lg:mt-0 w-full lg:w-7/12 px-2 text-333333 text-justify'>
          <div className='flex my-3'>
            <Checkbox
              inputId='check1'
              className={`${currentLanguageCode === 'ar' ? 'ml-2' : 'mr-2'}`}
              disabled={true}
              checked={checkbox1Value}
              onChange={(e) => {
                setCheckbox1Value(e.checked);
              }}
            />
            <p>
              {t(
                'translation:your_login_credentials_shall_be_active_only_for_the_duration_of_the_scheduled_assessment'
              )}
            </p>
          </div>
          <div className='flex my-3'>
            <Checkbox
              inputId='check2'
              className={`${currentLanguageCode === 'ar' ? 'ml-2' : 'mr-2'}`}
              disabled={!checkbox1Value}
              checked={checkbox2Value}
              onChange={(e) => {
                setCheckbox2Value(e.checked);
              }}
            />
            <p className={`${!checkbox1Value ? 'opacity-60' : ''}`}>
              {t(
                'translation:please_do_not_refresh_your_browser_once_you_login'
              )}
            </p>
          </div>
          <div className='flex my-3'>
            <Checkbox
              inputId='check3'
              className={`${currentLanguageCode === 'ar' ? 'ml-2' : 'mr-2'}`}
              disabled={!checkbox1Value || !checkbox2Value}
              checked={checkbox3Value}
              onChange={(e) => {
                setCheckbox3Value(e.checked);
              }}
            />
            <p
              className={`${
                !checkbox1Value || !checkbox2Value ? 'opacity-60' : ''
              }`}
            >
              {t(
                'translation:please_do_not_close_your_browser_until_the_end_of_the_assessment'
              )}
            </p>
          </div>
          <div className='flex my-3'>
            <span>
              <Checkbox
                inputId='check4'
                className={`${currentLanguageCode === 'ar' ? 'ml-2' : 'mr-2'}`}
                disabled={!checkbox1Value || !checkbox2Value || !checkbox3Value}
                checked={checkbox4Value}
                onChange={(e) => {
                  setCheckbox4Value(e.checked);
                }}
              />
            </span>
            <p
              className={`flex ${
                !checkbox1Value || !checkbox2Value || !checkbox3Value
                  ? 'opacity-60'
                  : ''
              }`}
            >
              {t(
                'translation:once_you_start_your_assessment_the_full_screen_mode_shall_get_activated_on_your_system_till_the_submission_of_your_assessment'
              )}
            </p>
          </div>
          <div className='flex my-3'>
            <span>
              <Checkbox
                inputId='check5'
                className={`${currentLanguageCode === 'ar' ? 'ml-2' : 'mr-2'}`}
                disabled={
                  !checkbox1Value ||
                  !checkbox2Value ||
                  !checkbox3Value ||
                  !checkbox4Value
                }
                checked={checkbox5Value}
                onChange={(e) => {
                  setCheckbox5Value(e.checked);
                }}
              />
            </span>
            <p
              className={`flex font-bold ${
                !checkbox1Value ||
                !checkbox2Value ||
                !checkbox3Value ||
                !checkbox4Value
                  ? 'opacity-60'
                  : ''
              }`}
            >
              {t(
                'translation:existing_full_screen_will_result_in_deactivation'
              )}
            </p>
          </div>
          <div className='p-field-checkbox flex  my-3'>
            <Checkbox
              inputId='terms'
              disabled={
                !checkbox1Value ||
                !checkbox2Value ||
                !checkbox3Value ||
                !checkbox4Value ||
                !checkbox5Value
              }
              checked={checkboxValue}
              onChange={(e) => {
                setCheckboxValue(e.checked);
              }}
            />
            <label
              htmlFor='terms'
              className={`text-Primary ${
                currentLanguageCode === 'ar' ? 'pr-2' : 'pl-2'
              }`}
            >
              {t('translation:i_give_my_consent')}
            </label>
          </div>
          {/* {status === 'failed' ? (
            <div className='flex mt-4'>
              <p className='text-red-600'>
                * Unable to detect webcam, Please make sure webcam is working.
              </p>
            </div>
          ) : null} */}
        </div>
      </div>

      <div className='w-28 mx-auto my-4 '>
        {checkboxValue &&
        checkbox1Value &&
        checkbox2Value &&
        checkbox3Value &&
        checkbox4Value &&
        checkbox5Value ? (
          <button
            onClick={() => {
              const searchString = queryString.stringify(queryStringValues);
              history.push({
                pathname: '/psychometric-assessment/login',
                search: `?${searchString}`,
              });
            }}
            className='w-28'
          >
            <PrimaryButton>{t('translation:next')}</PrimaryButton>
          </button>
        ) : (
          <button
            disabled={true}
            className='w-28 opacity-80 cursor-not-allowed'
          >
            <PrimaryButton>{t('translation:next')}</PrimaryButton>
          </button>
        )}
      </div>
    </div>
  );
}
