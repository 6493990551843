import React, { useContext } from 'react';
import { MainContext } from 'contexts/mainContext';
// import { ReactComponent as AptalogoIlls } from '../assets/images/Header/Aptalogo.svg';
import Avatar from 'components/Avatar';
import { Skeleton } from 'primereact/skeleton';

import cookies from 'js-cookie';

export default function Header() {
  const currentLanguageCode = cookies.get('i18next');
  const { mainState } = useContext(MainContext);
  return (
    <div className='h-20 bg-white shadow-md sticky z-10 top-0 py-2'>
      <div className='flex w-11/12 relative mx-auto content-around max-w-screen-xl'>
        {!mainState.company_name ? (
          <div className='flex'>
            <Skeleton
              className='my-1'
              height='3.5rem'
              width='3.5rem'
              borderRadius='3.5rem'
            />
            <div
              className={`py-5 text-Primary ${
                currentLanguageCode === 'ar'
                  ? 'lg:pl-4 sm:pl-2 pr-4'
                  : 'lg:pr-4 sm:pr-2 pl-4'
              }`}
            >
              <Skeleton height='1.5rem' width='8rem' />
            </div>
          </div>
        ) : (
          <div className={`${mainState.company_name ? 'flex' : 'hidden'}`}>
            <Avatar
              imgSrc={mainState?.company_logo}
              className='inline object-cover my-1 w-14 h-14 rounded-full'
              userInitials={`${mainState.company_name
                ?.charAt(0)
                .toUpperCase()}`}
            />
            <p
              className={`py-5 text-lg text-gradient ${
                currentLanguageCode === 'ar'
                  ? 'lg:pl-4 sm:pl-2 pr-4'
                  : 'lg:pr-4 sm:pr-2 pl-4'
              }`}
            >
              {mainState.company_name}
            </p>
          </div>
        )}

        {/* <div className='flex absolute right-0'>
          <p className='py-4 lg:pr-4 sm:pr-2 text-gray-400'>Powered by</p>
          <AptalogoIlls />
        </div> */}
      </div>
    </div>
  );
}
