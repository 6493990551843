import React from 'react';
import { Editor } from 'primereact/editor';

function EditorSAQ({ activeQuestion, setDummyState }) {
  const renderHeader = () => {
    return (
      <span className='ql-formats'>
        <button className='ql-bold' aria-label='Bold'></button>
        <button className='ql-italic' aria-label='Italic'></button>
        <button className='ql-underline' aria-label='Underline'></button>
        <button
          className='ql-list'
          value='ordered'
          aria-label='Ordered'
        ></button>
        <button className='ql-list' value='bullet' aria-label='Bullet'></button>
        <button className='ql-color' aria-label='Color'></button>
        <button className='ql-background' aria-label='Background'></button>
      </span>
    );
  };
  const header = renderHeader();
  return (
    <Editor
      id={activeQuestion._id}
      headerTemplate={header}
      className='w-full py-4 h-full'
      value={activeQuestion?.answer}
      onTextChange={(e) => {
        activeQuestion.answer = e.htmlValue;
        if (e.htmlValue) {
          activeQuestion.isQuestionAnswered = true;
        } else {
          activeQuestion.isQuestionAnswered = false;
        }
        setDummyState(activeQuestion.answer + activeQuestion._id);
      }}
    />
  );
}

export default EditorSAQ;
