import { useContext } from 'react';
import { MainContext } from 'contexts/mainContext';
import { useQuery } from 'react-query';
import makeAPIRequest from '../services/makeApiRequest';
import apiEndPoints from '../services/apiEndpoints';

export default function UseJobDetails({ enable = true, jobId, company }) {
  const { mainDispatch } = useContext(MainContext);

  return useQuery(
    ['getApplicationFormJobData', company, jobId],
    async () => {
      let data;
      if (company && jobId) {
        data = await makeAPIRequest.get(
          `${apiEndPoints.APPLICANTJOBS}?company=${company}&job_id=${jobId}`
        );
        // console.log(data, 'dsdss');

        return data;
      }
    },
    {
      enabled: enable,
      onSuccess: (data) => {
        if (jobId) {
          mainDispatch({
            type: 'SETNAMELOGOCOLOR',
            payload: {
              company_name: data?.data?.company_name,
              company_logo: data?.data?.company_logo,
              company_color: data?.data?.company_color,
            },
          });
        }
      },
      onError: (err) => {
        mainDispatch({
          type: 'SETNAMELOGOCOLOR',
          payload: {
            company_name: err.response?.data?.company_name,
            company_logo: err.response?.data?.company_logo,
            company_color: err.response?.data?.company_color,
          },
        });
      },
    }
  );
}
