import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import queryString from 'query-string';
// import PrimaryButton from '../../../components/Buttons/ButtonPrimary';
import SecondaryButton from '../../../components/Buttons/SecondaryButton';

import { useTranslation } from 'react-i18next';

import { ReactComponent as UnderlineIllus } from '../../../assets/images/VideoProfileAssessment/UnderlineIllus.svg';
import { ReactComponent as TestCameraIllus } from '../../../assets/images/VideoProfileAssessment/TestCameraIllus.svg';
import { ReactComponent as AvoidLightIllus } from '../../../assets/images/VideoProfileAssessment/AvoidLightIllus.svg';
import { ReactComponent as LightFrontBackIllus } from '../../../assets/images/VideoProfileAssessment/LightFrontBackIllus.svg';
import { ReactComponent as LaptopIllus } from '../../../assets/images/VideoProfileAssessment/LaptopIllus.svg';
import { ReactComponent as DistractionsIllus } from '../../../assets/images/VideoProfileAssessment/DistractionsIllus.svg';
import { ReactComponent as LensIllus } from '../../../assets/images/VideoProfileAssessment/LensIllus.svg';

export default function TipsForVideoProfile() {
  const { t } = useTranslation(['translation']);
  const location = useLocation();
  const history = useHistory();
  const queryStringValues = queryString.parse(location.search);

  return (
    <div className='w-11/12 max-w-main-page shadow-lg rounded-lg mx-auto my-8 border bg-white py-4'>
      <div className='flex'>
        <div className='w-full p-4'>
          <div className='flex flex-col items-center  justify-center'>
            <p className='text-center mb-3 text-xl text-gray-600 font-semibold'>
              {t('translation:tips_for_using_a_webcam')}
            </p>
            <UnderlineIllus />
          </div>
          <div className='my-8 grid grid-cols-2 lg:grid-cols-3 gap-4'>
            <div className='w-56 h-48 flex flex-col items-center justify-end mx-auto'>
              <TestCameraIllus className='mx-auto' />
              <p className='text-515151 text-center mt-4'>
                {t('translation:test_your_camera_before_the_assessment_starts')}
              </p>
            </div>
            <div className='w-56 h-48 flex flex-col items-center justify-end mx-auto'>
              <AvoidLightIllus className='mx-auto' />
              <p className='text-515151 text-center mt-4'>
                {t('translation:avoid_sitting_under_overhead_lighting')}
              </p>
            </div>
            <div className='w-56 h-48 flex flex-col items-center justify-end mx-auto'>
              <LightFrontBackIllus className='mx-auto' />
              <p className='text-515151 text-center mt-4'>
                {t(
                  'translation:lights_should_come_from_front_and_behind_the_webcam'
                )}
              </p>
            </div>
            <div className='w-56 h-48 flex flex-col items-center justify-end mx-auto'>
              <LaptopIllus className='mx-auto' />
              <p className='text-515151 text-center mt-4'>
                {t(
                  'translation:make_sure_the_camera_is_at_a_natural_distance_from_your_face'
                )}
              </p>
            </div>
            <div className='w-56 h-48 flex flex-col items-center justify-end mx-auto'>
              <DistractionsIllus className='mx-auto' />
              <p className='text-515151 text-center mt-4'>
                {t(
                  'translation:remove_clutter_any_other_distrcations_in_the_background'
                )}
              </p>
            </div>
            <div className='w-56 h-48 flex flex-col items-center justify-end mx-auto'>
              <LensIllus className='mx-auto' />
              <p className='text-515151 text-center mt-4'>
                {t('translation:angle_the_lens_so_it_is_at_eye_level')}
              </p>
            </div>
          </div>
          <div className='flex justify-center mx-auto mt-10 '>
            {/* <button
              onClick={() => {
                const searchString = queryString.stringify(queryStringValues);
                history.push({
                  pathname:
                    '/psychometric-assessment/instructions-video-profile',
                  search: `?${searchString}`,
                });
              }}
              className='w-28 mr-4'
            >
              <PrimaryButton>Proceed</PrimaryButton>
            </button> */}
            <button
              onClick={() => {
                const searchString = queryString.stringify(queryStringValues);
                history.push({
                  pathname: '/psychometric-assessment/video-test',
                  search: `?${searchString}`,
                });
              }}
              className='w-80'
            >
              <SecondaryButton>
                {t('translation:test_your_system_video_and_audio_settings')}
              </SecondaryButton>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
