import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import cookies from 'js-cookie';

import { Toaster } from 'react-hot-toast';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Loader from 'components/Loader';

i18n
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    supportedLngs: ['ar', 'en'],
    fallbackLng: 'ar',
    debug: false,
    lng: cookies.get('i18next') || 'ar',
    // Options for language detector
    detection: {
      order: ['path', 'cookie', 'htmlTag'],
      caches: ['cookie'],
    },
    // react: { useSuspense: false },
    backend: {
      loadPath: '/assets/locales/{{lng}}/translation.json',
    },
  });

ReactDOM.render(
  <Suspense
    fallback={
      <div className='bg-white rounded-xl max-w-screen-xl w-full mx-auto min-h-80vh flex justify-center items-center'>
        <Loader width='70' height='70' color='#FF009C' />
      </div>
    }
  >
    <React.StrictMode>
      <>
        <Toaster
          position='top-right'
          reverseOrder={false}
          duration='4000'
          containerClassName='toast-cont'
          containerStyle={{
            position: 'fixed',
          }}
        />
        <App />
      </>
    </React.StrictMode>
  </Suspense>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
