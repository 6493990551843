import React, { useEffect, useState } from 'react';
import PrimaryButton from '../../../components/Buttons/ButtonPrimary';
import DOMPurify from 'dompurify';
import { RadioButton } from 'primereact/radiobutton';
import { useMutation } from 'react-query';
import makeAPIRequest from '../../../services/makeApiRequest.js';
import apiEndPoints from '../../../services/apiEndpoints';
// import { ErrorToast } from 'services/toasterService.js';
import { useLocation, useHistory } from 'react-router-dom';
import queryString from 'query-string';
import SecondaryButton from '../../../components/Buttons/SecondaryButton';
import EditorSAQ from './EditorSAQ';
import SubmitPopOver from './SubmitPopOver';
import { useTimer } from 'react-timer-hook';
import { usePageLeave, useWindowSize } from 'react-use';
import useExitPrompt from 'helper/useExitPrompt';
import LottiLoader from '../../../components/LottiLoader';
import { Popover } from '@headlessui/react';
import { usePopper } from 'react-popper';

import { useTranslation } from 'react-i18next';
import cookies from 'js-cookie';

import { ReactComponent as InfoIllustration } from '../../../assets/images/VideoProfileAssessment/InfoIllustration.svg';

// import { ReactComponent as TimerIlls } from '../../../assets/images/SkillAssessment/TimerIlls.svg';

export default function AssessmentTest({
  getSkillAssessmentExamData,
  isFullscreen,
  toggle,
  geoLocation,
}) {
  const { t } = useTranslation(['translation']);
  const currentLanguageCode = cookies.get('i18next');
  const location = useLocation();
  const history = useHistory();
  const queryStringValues = queryString.parse(location.search);
  const [mcqQuestions, setMcqQuestions] = useState([]);
  const [tfQuestions, setTfQuestions] = useState([]);
  const [saqQuestions, setSaqQuestions] = useState([]);
  const [activeQuestion, setActiveQuestion] = useState({});
  const [showSubmitPopOver, setShowSubmitPopOver] = useState(false);
  // eslint-disable-next-line
  const [dummystate, setDummyState] = useState('Initial');
  const [isPageLeft, setIsPageLeft] = useState(false);

  let [referenceElement, setReferenceElement] = useState();
  let [popperElement, setPopperElement] = useState();
  let { styles, attributes } = usePopper(referenceElement, popperElement);

  const initialTime = getSkillAssessmentExamData?.data?.data?.total_time;
  // isRunning
  const { hours, seconds, minutes } = useTimer({
    expiryTimestamp: Date.now() + +initialTime * 1000,
    onExpire: () => {
      onSubmit();
    },
  });

  usePageLeave(() => setIsPageLeft(true));
  const { width, height } = useWindowSize();

  useEffect(() => {
    if (getSkillAssessmentExamData.data) {
      getSkillAssessmentExamData?.data?.data?.MCQ.forEach((question, index) => {
        question.questionNumber = index + 1;
        if (index === 0) {
          question.isQuestionViewed = true;
        } else {
          question.isQuestionViewed = false;
        }
        question.isQuestionAnswered = false;
        question.isQuestionMarkedReview = false;
        question.answer = null;
      });
      getSkillAssessmentExamData?.data?.data?.TF.forEach((question, index) => {
        question.questionNumber =
          getSkillAssessmentExamData?.data?.data?.MCQ.length + index + 1;
        question.isQuestionViewed = false;
        question.isQuestionAnswered = false;
        question.isQuestionMarkedReview = false;
        question.answer = null;
      });
      getSkillAssessmentExamData?.data?.data?.SAQ.forEach((question, index) => {
        question.questionNumber =
          getSkillAssessmentExamData?.data?.data?.TF.length +
          getSkillAssessmentExamData?.data?.data?.MCQ.length +
          index +
          1;
        question.isQuestionViewed = false;
        question.isQuestionAnswered = false;
        question.isQuestionMarkedReview = false;
        question.answer = '';
      });
      setActiveQuestion(
        getSkillAssessmentExamData?.data?.data?.MCQ?.[0] ||
          getSkillAssessmentExamData?.data?.data?.TF?.[0] ||
          getSkillAssessmentExamData?.data?.data?.SAQ?.[0]
      );
      setMcqQuestions(getSkillAssessmentExamData?.data?.data?.MCQ);
      setTfQuestions(getSkillAssessmentExamData?.data?.data?.TF);
      setSaqQuestions(getSkillAssessmentExamData?.data?.data?.SAQ);
    }
  }, [getSkillAssessmentExamData?.data]);

  // eslint-disable-next-line
  // const getSkillAssessmentExamData = useQuery(
  //   ['getSkillAssessmentExamData'],
  //   async () => {
  //     const { data } = await makeAPIRequest.get(
  //       `${apiEndPoints.SKILLASSESSMENTEXAM}?company=90cb462e-b991-4f40-9c1a-83eb60e33426&job_id=614972bb63f2d2b920a32353`
  //     );
  //     return data;
  //   },
  //   {
  //     onSuccess: (data) => {
  //       if (data) {
  //         data?.data?.MCQ.forEach((question, index) => {
  //           question.questionNumber = index + 1;
  //           if (index === 0) {
  //             question.isQuestionViewed = true;
  //           } else {
  //             question.isQuestionViewed = false;
  //           }
  //           question.isQuestionAnswered = false;
  //           question.isQuestionMarkedReview = false;
  //           question.answer = null;
  //         });
  //         data?.data?.TF.forEach((question, index) => {
  //           question.questionNumber = data?.data?.MCQ.length + index + 1;
  //           question.isQuestionViewed = false;
  //           question.isQuestionAnswered = false;
  //           question.isQuestionMarkedReview = false;
  //           question.answer = null;
  //         });
  //         data?.data?.SAQ.forEach((question, index) => {
  //           question.questionNumber =
  //             data?.data?.TF.length + data?.data?.MCQ.length + index + 1;
  //           question.isQuestionViewed = false;
  //           question.isQuestionAnswered = false;
  //           question.isQuestionMarkedReview = false;
  //           question.answer = '';
  //         });
  //         setActiveQuestion(data?.data?.MCQ?.[0]);
  //         setMcqQuestions(data?.data?.MCQ);
  //         setTfQuestions(data?.data?.TF);
  //         setSaqQuestions(data?.data?.SAQ);
  //       }
  //     },
  //   }
  // );

  // nextButton function
  // using array index instead of forEach

  const nextButton = (question) => {
    if (
      question.questionNumber &&
      question.questionNumber <
        mcqQuestions.length + tfQuestions.length + saqQuestions.length
    ) {
      if (question.questionNumber < mcqQuestions.length) {
        mcqQuestions[question.questionNumber].isQuestionViewed = true;
        setActiveQuestion(mcqQuestions?.[question.questionNumber]);
      } else if (
        question.questionNumber >= mcqQuestions.length &&
        question.questionNumber < mcqQuestions.length + tfQuestions.length
      ) {
        tfQuestions[
          question.questionNumber - mcqQuestions.length
        ].isQuestionViewed = true;
        setActiveQuestion(
          tfQuestions?.[question.questionNumber - mcqQuestions?.length]
        );
      } else if (
        question.questionNumber >= mcqQuestions.length + tfQuestions.length &&
        question.questionNumber <
          mcqQuestions.length + tfQuestions.length + saqQuestions.length
      ) {
        saqQuestions[
          question.questionNumber - mcqQuestions.length - tfQuestions.length
        ].isQuestionViewed = true;

        setActiveQuestion(
          saqQuestions?.[
            question.questionNumber - mcqQuestions?.length - tfQuestions?.length
          ]
        );
      }
    }
  };
  const previousButton = (question) => {
    if (question.questionNumber && question.questionNumber > 0) {
      if (question.questionNumber <= mcqQuestions.length) {
        mcqQuestions[question.questionNumber - 2].isQuestionViewed = true;
        setActiveQuestion(mcqQuestions?.[question.questionNumber - 2]);
      } else if (
        question.questionNumber > mcqQuestions.length &&
        question.questionNumber <= mcqQuestions.length + tfQuestions.length
      ) {
        if (question.questionNumber === mcqQuestions.length + 1) {
          mcqQuestions[question.questionNumber - 2].isQuestionViewed = true;
          setActiveQuestion(mcqQuestions?.[question.questionNumber - 2]);
        } else {
          tfQuestions[
            question.questionNumber - mcqQuestions.length - 2
          ].isQuestionViewed = true;
          setActiveQuestion(
            tfQuestions?.[question.questionNumber - mcqQuestions?.length - 2]
          );
        }
      } else if (
        question.questionNumber > mcqQuestions.length + tfQuestions.length &&
        question.questionNumber <=
          mcqQuestions.length + tfQuestions.length + saqQuestions.length
      ) {
        if (
          question.questionNumber ===
          mcqQuestions.length + tfQuestions.length + 1
        ) {
          if (tfQuestions.length > 0) {
            tfQuestions[
              question.questionNumber - mcqQuestions.length - 2
            ].isQuestionViewed = true;
            setActiveQuestion(
              tfQuestions?.[question.questionNumber - mcqQuestions?.length - 2]
            );
          } else {
            mcqQuestions[question.questionNumber - 2].isQuestionViewed = true;
            setActiveQuestion(mcqQuestions?.[question.questionNumber - 2]);
          }
        } else {
          saqQuestions[
            question.questionNumber -
              mcqQuestions.length -
              tfQuestions.length -
              2
          ].isQuestionViewed = true;

          setActiveQuestion(
            saqQuestions?.[
              question.questionNumber -
                mcqQuestions?.length -
                tfQuestions?.length -
                2
            ]
          );
        }
      }
    }
  };

  const viewQuestionByNumberClick = (question) => {
    question.isQuestionViewed = true;
    setActiveQuestion(question);
  };

  useEffect(() => {
    if (isFullscreen === false) {
      onSubmit(true);
    }
    // eslint-disable-next-line
  }, [isFullscreen]);

  // submit
  const skillAssessmentSubmitMutation = useMutation(async (data) => {
    const res = await makeAPIRequest.post(
      apiEndPoints.SKILLASSESSMENTEXAM,
      data
    );
    return res.data;
  });

  const onSubmit = (cancelled) => {
    const additional_info = {
      time_left: {
        hours: hours,
        minutes: minutes,
        seconds: seconds,
        total_seconds: hours
          ? hours * 60 + minutes * 60 + seconds
          : minutes
          ? minutes * 60 + seconds
          : seconds,
      },
      screen_resolution: {
        width: width,
        height: height,
      },
      is_left_page: isPageLeft,
      is_fullscreen_enabled: isFullscreen,
      geo_location: geoLocation,
    };

    let finalData = {
      company_name: queryStringValues.company_name,
      stage: queryStringValues.stage,
      applicant_id: queryStringValues.applicant_id,
      psychometric: 'False',
      additional_info: JSON.stringify(additional_info),
    };

    if (cancelled) {
      finalData = { ...finalData, cancelled: 'True' };
    } else {
      finalData = { ...finalData, cancelled: 'False' };

      mcqQuestions?.forEach((question) => {
        finalData = {
          ...finalData,
          [question._id]: question.answer ? question.answer : 'unatt$mpthrq!',
        };
      });
      tfQuestions?.forEach((question) => {
        finalData = {
          ...finalData,
          [question._id]: question.answer ? question.answer : 'unatt$mpthrq!',
        };
      });
      saqQuestions?.forEach((question) => {
        finalData = {
          ...finalData,
          [question._id]: question.answer ? question.answer : 'unatt$mpthrq!',
        };
      });
    }

    // let FinalDataFormData = new FormData();
    // FinalDataFormData.append('company_name', queryStringValues.company_name);
    // FinalDataFormData.append('stage', queryStringValues.stage);
    // FinalDataFormData.append('applicant_id', queryStringValues.applicant_id);
    // FinalDataFormData.append('psychometric', 'False');
    // FinalDataFormData.append(
    //   'additional_info',
    //   JSON.stringify(additional_info)
    // );

    // if (cancelled) {
    //   FinalDataFormData.append('cancelled', 'True');
    // } else {
    //   FinalDataFormData.append('cancelled', 'False');
    //   mcqQuestions?.forEach((question) => {
    //     FinalDataFormData.append(
    //       question._id,
    //       question.answer ? question.answer : 'unatt$mpthrq!'
    //     );
    //   });
    //   tfQuestions?.forEach((question) => {
    //     FinalDataFormData.append(
    //       question._id,
    //       question.answer ? question.answer : 'unatt$mpthrq!'
    //     );
    //   });
    //   saqQuestions?.forEach((question) => {
    //     FinalDataFormData.append(
    //       question._id,
    //       question.answer ? question.answer : 'unatt$mpthrq!'
    //     );
    //   });
    // }

    skillAssessmentSubmitMutation.mutate(finalData, {
      onSuccess: (data) => {
        if (data.message === 'cancelled') {
          const newsearchString = {
            ErrorMessage: 'Your assessment has been cancelled.',
            ...queryStringValues,
          };
          const newerrorsearchString = queryString.stringify(newsearchString);
          history.push({
            pathname: '/prehire-assessment/cancelled',
            search: `?${newerrorsearchString}`,
          });
        } else {
          const newsearchString = {
            isSkillComplete: true,
            ...queryStringValues,
          };
          const newsuccesssearchString = queryString.stringify(newsearchString);
          history.push({
            pathname: '/prehire-assessment/prehire-overview',
            search: `?${newsuccesssearchString}`,
          });
        }
      },
      onError: (err) => {
        const searchString = queryString.stringify(queryStringValues);
        if (err.response.data.message === 'Already Completed.') {
          toggle(false);
          history.push({
            pathname: '/prehire-assessment',
            search: `?${searchString}`,
          });
        } else {
          const newsearchString = {
            ErrorMessage: err.response.data.message,
            ...queryStringValues,
          };
          const newerrorsearchString = queryString.stringify(newsearchString);
          history.push({
            pathname: '/prehire-assessment/cancelled',
            search: `?${newerrorsearchString}`,
          });
        }
      },
    });
    // for (var pair of FinalDataFormData.entries()) {
    //   console.log(pair[0] + ', ' + pair[1]);
    // }
  };

  // prevent reload refresh
  // eslint-disable-next-line
  const [showExitPrompt, setShowExitPrompt] = useExitPrompt(false);

  //NOTE: this similar to componentWillUnmount()
  useEffect(() => {
    setShowExitPrompt(false);
    // eslint-disable-next-line
  }, []);

  return (
    <div className='bg-gray-50 min-h-85vh flex flex-col w-full max-w-main-page mx-auto'>
      {skillAssessmentSubmitMutation?.isLoading ? (
        <div className='min-h-85vh h-full flex items-center justify-center'>
          <LottiLoader />
        </div>
      ) : (
        <>
          <div className='flex justify-center pt-4 pb-2'>
            <lottie-player
              src='https://assets8.lottiefiles.com/packages/lf20_3lzpb2yc.json'
              background='transparent'
              speed='1'
              style={{ width: '1.5rem', height: 'auto' }}
              loop
              autoplay
            ></lottie-player>
            {hours || minutes > 0 ? (
              <p>
                <span
                  className={`text-Primary text-lg font-bold ${
                    currentLanguageCode === 'ar' ? 'pr-2' : 'pl-2'
                  }`}
                >
                  {hours ? `${hours > 9 ? hours : `0${hours}`} :` : null}
                  {minutes > 9 ? minutes : `0${minutes}`}:
                  {seconds > 9 ? seconds : `0${seconds}`}
                </span>{' '}
                <span className='text-4F4F4F font-bold'>
                  {t('translation:time_left')}
                </span>
              </p>
            ) : (
              <p>
                <span
                  className={`text-red-600 text-lg font-bold ${
                    currentLanguageCode === 'ar' ? 'pr-2' : 'pl-2'
                  }`}
                >
                  {seconds > 9 ? seconds : `0${seconds}`} Secs
                </span>{' '}
                <span className='text-4F4F4F font-bold'>
                  {t('translation:remaining')}
                </span>
              </p>
            )}
          </div>
          <div
            className={`flex flex-col lg:flex-row select-none flex-grow bg-white w-11/12 mx-auto border p-6 rounded-lg ${
              currentLanguageCode === 'ar' ? 'pr-6' : 'pl-6'
            }`}
          >
            <div
              className={`w-full lg:w-4/6 flex flex-col ${
                currentLanguageCode === 'ar' ? 'ml-4' : 'mr-4'
              }`}
            >
              <p className='text-Primary mb-3 text-xl'>
                {t('translation:question')}
              </p>
              <div className='flex h-full  flex-col justify-between'>
                <div
                  className={`flex flex-col min-h-30vh max-h-30vh lg:max-h-50vh overflow-y-scroll scroller ${
                    currentLanguageCode === 'ar' ? 'pl-3' : 'pr-3'
                  }`}
                >
                  <div className='flex'>
                    <p
                      className={`text-base 2xl:text-lg ${
                        activeQuestion?.questionNumber > 9 ? 'min-w-6' : ''
                      } ${currentLanguageCode === 'ar' ? 'ml-1' : 'mr-1'}`}
                    >
                      {activeQuestion?.questionNumber}.
                    </p>
                    <div
                      className='text-base 2xl:text-lg text-justify flex-1 prose max-w-full'
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(activeQuestion?.name),
                      }}
                    ></div>
                    {activeQuestion?.instructions ? (
                      <div
                        className={`mt-1 cursor-pointer ${
                          currentLanguageCode === 'ar' ? 'mr-4' : 'ml-4'
                        }`}
                      >
                        <Popover className='relative'>
                          <Popover.Button
                            ref={setReferenceElement}
                            className='cursor-pointer flex items-center'
                          >
                            <InfoIllustration />
                          </Popover.Button>

                          <Popover.Panel
                            ref={setPopperElement}
                            style={styles.popper}
                            {...attributes.popper}
                            className='absolute z-10 w-32rem max-w-lg px-4 py-2'
                          >
                            <div className='overflow-hidden rounded-lg shadow-lg'>
                              <div className='relative bg-white border p-5'>
                                <div className='flex flex-col'>
                                  <div
                                    className='text-sm text-justify flex-1 prose max-w-full'
                                    dangerouslySetInnerHTML={{
                                      __html: DOMPurify.sanitize(
                                        activeQuestion?.instructions
                                      ),
                                    }}
                                  ></div>
                                </div>
                              </div>
                            </div>
                          </Popover.Panel>
                        </Popover>
                      </div>
                    ) : null}
                  </div>
                  {activeQuestion?.question_type === 'MCQ' ? (
                    <div
                      className={`text-gray-500 text-sm 2xl:text-base ${
                        activeQuestion?.questionNumber > 99
                          ? `${currentLanguageCode === 'ar' ? 'mr-7' : 'ml-7'}`
                          : activeQuestion?.questionNumber > 9
                          ? `${currentLanguageCode === 'ar' ? 'mr-6' : 'ml-6'}`
                          : `${currentLanguageCode === 'ar' ? 'mr-4' : 'ml-4'}`
                      }`}
                    >
                      <div className='p-field-radiobutton pt-4 flex'>
                        <RadioButton
                          inputId='option1'
                          name='value'
                          value={activeQuestion?.options?.[0]}
                          onChange={(e) => {
                            activeQuestion.answer = e.value;
                            activeQuestion.isQuestionAnswered = true;
                            setDummyState(e.value + activeQuestion?._id);
                          }}
                          checked={
                            activeQuestion?.options?.[0] ===
                            activeQuestion?.answer
                          }
                        />
                        <label
                          htmlFor='option1'
                          className={`${
                            currentLanguageCode === 'ar' ? 'pr-2' : 'pl-2'
                          }`}
                        >
                          {activeQuestion?.options?.[0]}
                        </label>
                      </div>
                      <div className='p-field-radiobutton pt-4 flex'>
                        <RadioButton
                          inputId='option2'
                          name='value'
                          value={activeQuestion?.options?.[1]}
                          checked={
                            activeQuestion?.options?.[1] ===
                            activeQuestion?.answer
                          }
                          onChange={(e) => {
                            activeQuestion.answer = e.value;
                            activeQuestion.isQuestionAnswered = true;
                            setDummyState(e.value + activeQuestion?._id);
                          }}
                        />
                        <label
                          htmlFor='option2'
                          className={`${
                            currentLanguageCode === 'ar' ? 'pr-2' : 'pl-2'
                          }`}
                        >
                          {activeQuestion?.options?.[1]}
                        </label>
                      </div>
                      <div className='p-field-radiobutton pt-4 flex'>
                        <RadioButton
                          inputId='option3'
                          name='value'
                          value={activeQuestion?.options?.[2]}
                          checked={
                            activeQuestion?.options?.[2] ===
                            activeQuestion?.answer
                          }
                          onChange={(e) => {
                            activeQuestion.answer = e.value;
                            activeQuestion.isQuestionAnswered = true;
                            setDummyState(e.value + activeQuestion?._id);
                          }}
                        />
                        <label
                          htmlFor='option3'
                          className={`${
                            currentLanguageCode === 'ar' ? 'pr-2' : 'pl-2'
                          }`}
                        >
                          {activeQuestion?.options?.[2]}
                        </label>
                      </div>
                      <div className='p-field-radiobutton py-4 flex'>
                        <RadioButton
                          inputId='option4'
                          name='value'
                          value={activeQuestion?.options?.[3]}
                          checked={
                            activeQuestion?.options?.[3] ===
                            activeQuestion?.answer
                          }
                          onChange={(e) => {
                            activeQuestion.answer = e.value;
                            activeQuestion.isQuestionAnswered = true;
                            setDummyState(e.value + activeQuestion?._id);
                          }}
                        />
                        <label
                          htmlFor='option4'
                          className={`${
                            currentLanguageCode === 'ar' ? 'pr-2' : 'pl-2'
                          }`}
                        >
                          {activeQuestion?.options?.[3]}
                        </label>
                      </div>
                    </div>
                  ) : activeQuestion?.question_type === 'TF' ? (
                    <div
                      className={`text-gray-500 text-sm 2xl:text-base ${
                        activeQuestion?.questionNumber > 99
                          ? `${currentLanguageCode === 'ar' ? 'mr-7' : 'ml-7'}`
                          : activeQuestion?.questionNumber > 9
                          ? `${currentLanguageCode === 'ar' ? 'mr-6' : 'ml-6'}`
                          : `${currentLanguageCode === 'ar' ? 'mr-4' : 'ml-4'}`
                      }`}
                    >
                      <div className='p-field-radiobutton pt-4 flex'>
                        <RadioButton
                          inputId='option1'
                          name='value'
                          value='True'
                          onChange={(e) => {
                            activeQuestion.answer = e.value;
                            activeQuestion.isQuestionAnswered = true;
                            setDummyState(e.value + activeQuestion?._id);
                          }}
                          checked={activeQuestion?.answer === 'True'}
                        />
                        <label
                          htmlFor='option1'
                          className={`${
                            currentLanguageCode === 'ar' ? 'pr-2' : 'pl-2'
                          }`}
                        >
                          {t('translation:true')}
                        </label>
                      </div>
                      <div className='p-field-radiobutton py-4 flex'>
                        <RadioButton
                          inputId='option2'
                          name='value'
                          value='False'
                          checked={activeQuestion?.answer === 'False'}
                          onChange={(e) => {
                            activeQuestion.answer = e.value;
                            activeQuestion.isQuestionAnswered = true;
                            setDummyState(e.value + activeQuestion?._id);
                          }}
                        />
                        <label
                          htmlFor='option2'
                          className={`${
                            currentLanguageCode === 'ar' ? 'pr-2' : 'pl-2'
                          }`}
                        >
                          {t('translation:false')}
                        </label>
                      </div>
                    </div>
                  ) : activeQuestion?.question_type === 'SAQ' ? (
                    <div
                      className={`text-gray-500 text-sm 2xl:text-base ${
                        activeQuestion?.questionNumber > 99
                          ? `${currentLanguageCode === 'ar' ? 'mr-7' : 'ml-7'}`
                          : activeQuestion?.questionNumber > 9
                          ? `${currentLanguageCode === 'ar' ? 'mr-6' : 'ml-6'}`
                          : `${currentLanguageCode === 'ar' ? 'mr-4' : 'ml-4'}`
                      }`}
                    >
                      <div className='min-h-30vh lg:min-h-50vh h-full w-full'>
                        <EditorSAQ
                          activeQuestion={activeQuestion}
                          setDummyState={setDummyState}
                        />
                      </div>
                    </div>
                  ) : null}
                </div>
                <div className='flex justify-center mt-4 text-sm'>
                  {/* <button className='w-32 whitespace-nowrap mr-2'>
                <SecondaryButton>Submit answer</SecondaryButton>
              </button> */}
                  <button
                    onClick={() => previousButton(activeQuestion)}
                    className={`w-32 whitespace-nowrap ${
                      activeQuestion?.questionNumber === 1
                        ? 'cursor-not-allowed opacity-60'
                        : ''
                    } ${currentLanguageCode === 'ar' ? 'ml-4' : 'mr-4'}`}
                    disabled={activeQuestion?.questionNumber === 1}
                  >
                    <SecondaryButton>
                      {t('translation:previous')}
                    </SecondaryButton>
                  </button>
                  <button
                    onClick={() => {
                      if (activeQuestion?.isQuestionMarkedReview) {
                        activeQuestion.isQuestionMarkedReview = false;
                      } else {
                        activeQuestion.isQuestionMarkedReview = true;
                      }
                    }}
                    className={`w-32 whitespace-nowrap ${
                      currentLanguageCode === 'ar' ? 'ml-4' : 'mr-4'
                    }`}
                  >
                    <SecondaryButton>
                      {activeQuestion?.isQuestionMarkedReview
                        ? t('translation:unmark')
                        : t('translation:mark_for_review')}
                    </SecondaryButton>
                  </button>
                  {activeQuestion?.answer ? (
                    <button
                      onClick={() => nextButton(activeQuestion)}
                      className={`w-32 whitespace-nowrap ${
                        activeQuestion?.questionNumber ===
                        getSkillAssessmentExamData?.data?.data?.total_count
                          ? 'cursor-not-allowed opacity-60'
                          : ''
                      } ${currentLanguageCode === 'ar' ? 'ml-4' : 'mr-4'}`}
                      disabled={
                        activeQuestion?.questionNumber ===
                        getSkillAssessmentExamData?.data?.data?.total_count
                      }
                    >
                      <PrimaryButton>{t('translation:next')}</PrimaryButton>
                    </button>
                  ) : (
                    <button
                      onClick={() => nextButton(activeQuestion)}
                      className={`w-32 whitespace-nowrap ${
                        activeQuestion?.questionNumber ===
                        getSkillAssessmentExamData?.data?.data?.total_count
                          ? 'cursor-not-allowed opacity-60'
                          : ''
                      } ${currentLanguageCode === 'ar' ? 'ml-4' : 'mr-4'}`}
                      disabled={
                        activeQuestion?.questionNumber ===
                        getSkillAssessmentExamData?.data?.data?.total_count
                      }
                    >
                      <SecondaryButton>{t('translation:next')}</SecondaryButton>
                    </button>
                  )}
                </div>
              </div>
            </div>
            <div
              className={`mt-6 lg:mt-0 w-full lg:w-2/6 lg:border-l-2 border-Primary border-opacity-20 flex flex-col text-gray-500 ${
                currentLanguageCode === 'ar' ? 'pr-4' : 'pl-4'
              }`}
            >
              <p className='text-Primary mb-3 text-xl'>
                {t('translation:response_overview')}
              </p>
              <div className='flex h-full  flex-col justify-between'>
                <div
                  className={`flex flex-col max-h-48 lg:max-h-50vh overflow-y-scroll scroller ${
                    currentLanguageCode === 'ar' ? 'pl-3' : 'pr-3'
                  }`}
                >
                  {mcqQuestions.length ? (
                    <div className='flex flex-col'>
                      <div className='flex w-full mb-3'>
                        <span className='w-6 flex items-center'>
                          <span className='w-full border border-Primary border-opacity-40'></span>
                        </span>
                        <span className='mx-3 text-black'>
                          {t('translation:mcq')}
                        </span>
                        <span className='flex flex-grow items-center'>
                          <span className='w-full border border-Primary border-opacity-40'></span>
                        </span>
                      </div>
                      <div className='flex flex-wrap gap-2'>
                        {mcqQuestions.map((question) => (
                          <div
                            onClick={() => {
                              viewQuestionByNumberClick(question);
                            }}
                            key={question._id}
                            className={`flex items-center justify-center w-8 h-8 cursor-pointer text-sm rounded ${
                              question._id === activeQuestion?._id
                                ? 'bg-Primary  text-white'
                                : question?.isQuestionMarkedReview
                                ? 'bg-Review text-black'
                                : question?.isQuestionAnswered
                                ? 'bg-Answered  text-white'
                                : question?.isQuestionViewed
                                ? 'bg-NotAnswered text-black'
                                : 'bg-NotVisited text-black'
                            }`}
                          >
                            {question?.questionNumber}
                          </div>
                        ))}
                      </div>
                    </div>
                  ) : null}
                  {tfQuestions.length ? (
                    <div className='flex flex-col'>
                      <div className='flex w-full my-3'>
                        <span className='w-6 flex items-center'>
                          <span className='w-full border border-Primary border-opacity-40'></span>
                        </span>
                        <span className='mx-3 text-black'>
                          {t('translation:t/f')}
                        </span>
                        <span className='flex flex-grow items-center'>
                          <span className='w-full border border-Primary border-opacity-40'></span>
                        </span>
                      </div>
                      <div className='flex flex-wrap gap-2'>
                        {tfQuestions.map((question) => (
                          <div
                            onClick={() => {
                              viewQuestionByNumberClick(question);
                            }}
                            key={question._id}
                            className={`flex items-center justify-center w-8 h-8 cursor-pointer text-sm rounded ${
                              question._id === activeQuestion?._id
                                ? 'bg-Primary  text-white'
                                : question?.isQuestionMarkedReview
                                ? 'bg-Review text-black'
                                : question?.isQuestionAnswered
                                ? 'bg-Answered  text-white'
                                : question?.isQuestionViewed
                                ? 'bg-NotAnswered text-black'
                                : 'bg-NotVisited text-black'
                            }`}
                          >
                            {question?.questionNumber}
                          </div>
                        ))}
                      </div>
                    </div>
                  ) : null}
                  {saqQuestions.length ? (
                    <div className='flex flex-col'>
                      <div className='flex w-full my-3'>
                        <span className='w-6 flex items-center'>
                          <span className='w-full border border-Primary border-opacity-40'></span>
                        </span>
                        <span className='mx-3 text-black'>
                          {t('translation:saq')}
                        </span>
                        <span className='flex flex-grow items-center'>
                          <span className='w-full border border-Primary border-opacity-40'></span>
                        </span>
                      </div>
                      <div className='flex flex-wrap gap-2'>
                        {saqQuestions.map((question) => (
                          <div
                            onClick={() => {
                              viewQuestionByNumberClick(question);
                            }}
                            key={question?._id}
                            className={`flex items-center justify-center w-8 h-8 cursor-pointer text-sm rounded ${
                              question._id === activeQuestion?._id
                                ? 'bg-Primary  text-white'
                                : question?.isQuestionMarkedReview
                                ? 'bg-Review text-black'
                                : question?.isQuestionAnswered
                                ? 'bg-Answered  text-white'
                                : question?.isQuestionViewed
                                ? 'bg-NotAnswered text-black'
                                : 'bg-NotVisited text-black'
                            }`}
                          >
                            {question?.questionNumber}
                          </div>
                        ))}
                      </div>
                    </div>
                  ) : null}
                </div>
                <div className='w-full xl:text-sm text-xs text-gray-400'>
                  <div className='flex w-full justify-between mt-2'>
                    <div className='flex w-1/2 items-center'>
                      <span
                        className={`w-4 h-4 bg-Answered rounded-sm ${
                          currentLanguageCode === 'ar' ? 'ml-2' : 'mr-2'
                        }`}
                      ></span>
                      <p
                        className={`${
                          currentLanguageCode === 'ar' ? 'pl-6' : 'pr-6'
                        }`}
                      >
                        {t('translation:answered')}
                      </p>
                    </div>
                    <div className='flex w-1/2 items-center'>
                      <span
                        className={`w-4 h-4 bg-NotAnswered rounded-sm ${
                          currentLanguageCode === 'ar' ? 'ml-2' : 'mr-2'
                        }`}
                      ></span>
                      <p>{t('translation:not_answered')}</p>
                    </div>
                  </div>
                  <div className='flex w-full justify-between mt-2'>
                    <div className='flex w-1/2 items-center'>
                      <span
                        className={`w-4 h-4 bg-Review rounded-sm ${
                          currentLanguageCode === 'ar' ? 'ml-2' : 'mr-2'
                        }`}
                      ></span>
                      <p>{t('translation:marked_for_review')}</p>
                    </div>
                    <div className='flex w-1/2 items-center'>
                      <span
                        className={`w-4 h-4 bg-NotVisited rounded-sm ${
                          currentLanguageCode === 'ar' ? 'ml-2' : 'mr-2'
                        }`}
                      ></span>
                      <p>{t('translation:not_visited')}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='mt-4 mx-auto'>
            <button
              onClick={() => setShowSubmitPopOver(true)}
              className=' w-44'
            >
              <PrimaryButton>
                {t('translation:submit_assessment')}
              </PrimaryButton>
            </button>
          </div>
        </>
      )}
      <SubmitPopOver
        setVisible={setShowSubmitPopOver}
        isVisible={showSubmitPopOver}
        onSubmit={onSubmit}
        skillAssessmentSubmitMutation={skillAssessmentSubmitMutation}
      />
    </div>
  );
}
