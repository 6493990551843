import React from 'react';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import PrimaryButton from '../../../components/Buttons/ButtonPrimary';
import { useForm, Controller } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useLocation, useHistory } from 'react-router-dom';
import queryString from 'query-string';
import makeAPIRequest from '../../../services/makeApiRequest.js';
import apiEndPoints from '../../../services/apiEndpoints';
import { ErrorToast } from 'services/toasterService.js';
import ChromeRecomendedBanner from 'components/ChromeRecomendedBanner';

import { useTranslation } from 'react-i18next';
import cookies from 'js-cookie';

import LoginpageIlls from '../../../assets/images/SkillAssessment/loginPageIlls.svg';
import { ReactComponent as MCQIlls } from '../../../assets/images/SkillAssessment/MCQIlls.svg';
import { ReactComponent as TFIlls } from '../../../assets/images/SkillAssessment/TFIlls.svg';
import { ReactComponent as SampleIlls } from '../../../assets/images/SkillAssessment/sampleIlls.svg';
import { ReactComponent as SampleIlls2 } from '../../../assets/images/SkillAssessment/smapleIlls2.svg';

export default function Assessmentlogin({ width, toggle }) {
  const { t } = useTranslation(['translation']);
  const currentLanguageCode = cookies.get('i18next');
  const location = useLocation();
  const history = useHistory();
  let queryStringValues = queryString.parse(location.search);

  const defaultValues = {
    email_id: '',
    password: '',
  };

  const loginMutation = useMutation(async (data) => {
    const res = await makeAPIRequest.post(
      apiEndPoints.LOGINASSESSMENTEXAM,
      data
    );
    return res.data;
  });

  const onSubmit = (data) => {
    let LoginData = {
      company_name: queryStringValues.company_name,
      stage: queryStringValues.stage,
      ...data,
    };
    loginMutation.mutate(LoginData, {
      onSuccess: (data) => {
        // console.log(data);
        queryStringValues = {
          ...queryStringValues,
          applicant_name: data?.data?.applicant_name,
          job_id: data?.data?.job_id,
        };
        const searchString = queryString.stringify(queryStringValues);
        toggle();
        history.push({
          pathname: '/prehire-assessment/prehire-overview',
          search: `?${searchString}`,
        });
      },
      onError: (err) => {
        ErrorToast({ text: err.response.data.message });
      },
    });
  };

  const {
    control,
    formState: { errors },
    handleSubmit,
    // reset,
  } = useForm({ defaultValues });

  const getFormErrorMessage = (name) => {
    return (
      errors[name] && <small className='p-error'>{errors[name].message}</small>
    );
  };

  return (
    <>
      <ChromeRecomendedBanner />
      <div className='flex h-full max-w-main-page mx-auto'>
        {width > 1024 ? (
          <div
            className='hidden lg:flex w-full bg-gray-50 lg:relative pb-32'
            style={{
              backgroundImage: `url(${LoginpageIlls})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'contain',
              backgroundPositionY: '50px',
            }}
          >
            <div className='mt-32 w-2/5'>
              <div className='text-center w-full'>
                <p className='text-Primary text-2xl'>
                  {t('translation:hey_there_lets_login')}
                </p>
                <p className='text-xs text-gray-600'>
                  {t('translation:please_enter_your_login_credentials')}
                </p>
              </div>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className='w-80 mt-6 flex flex-col items-center mx-auto'>
                  <div className='w-full my-4'>
                    <span className='p-float-label'>
                      <Controller
                        name='email_id'
                        control={control}
                        rules={{
                          required: t('translation:this_is_a_required_field'),
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                            message: t('translation:invalid_email_address'),
                          },
                        }}
                        render={({ field }) => (
                          <InputText id={field.name} {...field} />
                        )}
                      />
                      <label htmlFor='email_id'>
                        {t('translation:email')}*
                      </label>
                    </span>
                    {getFormErrorMessage('email_id')}
                  </div>
                  <div className='w-full my-4'>
                    <span className='p-float-label'>
                      <Controller
                        name='password'
                        control={control}
                        rules={{
                          required: t('translation:this_is_a_required_field'),
                          // pattern: {
                          //   value:
                          //     /^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,}$/i,
                          //   message: 'Invalid password.',
                          // },
                        }}
                        render={({ field }) => (
                          <Password
                            id={field.name}
                            {...field}
                            toggleMask
                            feedback={false}
                          />
                        )}
                      />
                      <label htmlFor='password'>
                        {t('translation:password')}*
                      </label>
                    </span>
                    {getFormErrorMessage('password')}
                  </div>
                  <div className='w-28 mx-auto mt-6'>
                    <button className='w-28'>
                      <PrimaryButton isLoading={loginMutation.isLoading}>
                        {t('translation:login')}
                      </PrimaryButton>
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <MCQIlls
              className={`absolute right-0 top-12 ${
                currentLanguageCode === 'ar' ? 'pr-12' : 'pl-12'
              }`}
            />
            <TFIlls className='absolute right-24 md:bottom-28 xl:bottom-0 w-20' />
            <SampleIlls className='absolute bottom-8' />
            <SampleIlls2 className='absolute top-4 h-12' />
          </div>
        ) : (
          <div className='flex flex-col lg:hidden w-full bg-gray-50 '>
            <div className='flex flex-col justify-center my-20 w-full'>
              <div className='text-center w-full'>
                <p className='text-Primary text-2xl'>
                  {t('translation:hey_there_lets_login')}
                </p>
                <p className='text-xs text-gray-600'>
                  {t('translation:please_enter_your_login_credentials')}
                </p>
              </div>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className='w-80 mt-6 flex flex-col items-center mx-auto'>
                  <div className='w-full my-4'>
                    <span className='p-float-label'>
                      <Controller
                        name='email_id'
                        control={control}
                        rules={{
                          required: t('translation:this_is_a_required_field'),
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                            message: t('translation:invalid_email_address'),
                          },
                        }}
                        render={({ field }) => (
                          <InputText id={field.name} {...field} />
                        )}
                      />
                      <label htmlFor='email_id'>
                        {t('translation:email')}*
                      </label>
                    </span>
                    {getFormErrorMessage('email_id')}
                  </div>
                  <div className='w-full my-4'>
                    <span className='p-float-label'>
                      <Controller
                        name='password'
                        control={control}
                        rules={{
                          required: t('translation:this_is_a_required_field'),
                          // pattern: {
                          //   value:
                          //     /^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,}$/i,
                          //   message: 'Invalid password.',
                          // },
                        }}
                        render={({ field }) => (
                          <Password
                            id={field.name}
                            {...field}
                            toggleMask
                            feedback={false}
                          />
                        )}
                      />
                      <label htmlFor='password'>
                        {t('translation:password')}*
                      </label>
                    </span>
                    {getFormErrorMessage('password')}
                  </div>
                  <div className='w-28 mx-auto mt-6'>
                    <button className='w-28'>
                      <PrimaryButton isLoading={loginMutation.isLoading}>
                        {t('translation:login')}
                      </PrimaryButton>
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <div
              className='flex h-96 z-20 relative'
              style={{
                backgroundImage: `url(${LoginpageIlls})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'contain',
                backgroundPositionY: '50px',
              }}
            >
              <MCQIlls
                className={`absolute right-0 ${
                  currentLanguageCode === 'ar' ? 'pr-12' : 'pl-12'
                }`}
              />
              <TFIlls className='absolute left-16 top-16 w-20' />
              <SampleIlls className='absolute bottom-8' />
              {/* <SampleIlls2 className='absolute top-4 h-12' /> */}
            </div>
          </div>
        )}
      </div>
    </>
  );
}
