import React from 'react';

import Loader from '../Loader';

const PrimaryButton = ({ children, isLoading = false }) => {
  if (isLoading) {
    return (
      <div className='bg-purple text-white px-2 leading-5 py-2 rounded-md flex items-center justify-center'>
        <Loader />
      </div>
    );
  }
  return (
    <div className='bg-purple text-white px-2 py-2 rounded-md leading-5 hover:opacity-95 transition-colors'>
      {children}
    </div>
  );
};

export default PrimaryButton;
