import React, { useRef, useState, useEffect, useCallback } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { FileUpload } from 'primereact/fileupload';
import { Skeleton } from 'primereact/skeleton';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { useWindowSize } from 'react-use';

import { useTranslation } from 'react-i18next';
import cookies from 'js-cookie';

import { Checkbox } from 'primereact/checkbox';
import { useDropzone } from 'react-dropzone';
import NoMobile from '../../../helper/NoMobile';
import PrimaryButton from '../../../components/Buttons/ButtonPrimary';
// import { ErrorToast } from 'services/toasterService.js';

import AddPhoto from 'assets/images/JobApplication/addPhoto.png';
import { ReactComponent as EditIlls } from '../../../assets/images/JobApplication/EditIlls.svg';
import { ReactComponent as CheckIcon } from 'assets/images/JobApplication/CheckIcon.svg';
import { ReactComponent as UploadIcon } from 'assets/images/JobApplication/uploadIcon.svg';
import { ErrorToast } from 'services/toasterService';

export default function ApplyJob({
  getApplicationFormFields,
  selectJobName,
  selectJobID,
  jobId,
  company,
  postJobApplication,
  isAppliedThroughEmail,
  preFilledDataFromQuery,
  testJobApplicationResume,
}) {
  const { t } = useTranslation(['translation']);
  const currentLanguageCode = cookies.get('i18next');
  const location = useLocation();
  const history = useHistory();
  let queryStringValues = queryString.parse(location.search);
  const { width } = useWindowSize();

  const [checkboxValue, setCheckboxValue] = useState(false);

  const [isResumeValid, setIsResumeValid] = useState(false);

  const [selectedFile, setSelectedFile] = useState(null);
  const [preview, setPreview] = useState('');
  const [showImageError, setShowImageError] = useState(false);

  const [fileName, setFileName] = useState('');
  const [file, setFile] = useState();
  const [noFileError, setNoFileError] = useState('');
  const [invalidPdfError, setInvalidPdfError] = useState('');

  // form start
  const {
    control,
    formState: { errors },
    handleSubmit,
    // watch,
    // reset,
    setValue,
    clearErrors,
    setError,
  } = useForm();

  const onSubmit = (data) => {
    let formData = new FormData();
    if (jobId) {
      formData.append('job_id', jobId);
    } else if (selectJobID) {
      formData.append('job_id', selectJobID);
    }
    formData.append('upload_image', selectedFile ? selectedFile : '');
    if (!location.pathname.includes('/apply-through-email/')) {
      formData.append('resume', file);
    }
    formData.append('company_name', company);
    getApplicationFormFields?.data?.data?.forEach((fieldItem) => {
      formData.append(
        fieldItem.be_name,
        data[fieldItem.be_name] ? data[fieldItem.be_name] : ''
      );
    });
    postJobApplication.mutate(formData, {
      onSuccess: (data) => {
        queryStringValues.view = 'success';
        queryStringValues = {
          ...queryStringValues,
          successMessage: data.message,
        };
        const searchString = queryString.stringify(queryStringValues);
        history.push({
          pathname: location.pathname,
          search: `?${searchString}`,
        });
      },
      onError: (err) => {
        queryStringValues.view = 'error';
        queryStringValues = {
          ...queryStringValues,
          ErrorMessage: err.response.data.message,
        };
        const searchString = queryString.stringify(queryStringValues);
        history.push({
          pathname: location.pathname,
          search: `?${searchString}`,
        });
        // ErrorToast({ text: err.response.data.message });
      },
    });
  };

  const getFormErrorMessage = (name) => {
    return (
      errors[name] && (
        <small className='p-error text-xs'>{errors[name].message}</small>
      )
    );
  };

  // form end

  // Image Upload

  const fileRef = useRef(null);

  const triggerFileInput = () => {
    fileRef.current.click();
  };

  useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  const onSelectFile = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(null);
      return;
    }

    // validating Image upload size
    if (e.target.files && e.target.files[0].size > 300000) {
      setShowImageError(true);
      return;
    } else {
      setShowImageError(false);
    }

    // validating Image upload type
    if (
      (e.target.files && e.target.files[0].type === 'image/jpeg') ||
      (e.target.files && e.target.files[0].type === 'image/png')
    ) {
      // I've kept this  simple by using the first image instead of multiple
      setSelectedFile(e.target.files[0]);
    }
  };

  // Image Upload End

  //  CV Upload Start

  function fileSizeValidator(file) {
    if (file.size > 500000) {
      return {
        code: 'size-too-large',
        message: `* ${t('translation:upto_500kb_supported')}`,
      };
    }
    return null;
  }
  const onDrop = useCallback((acceptedFiles) => {
    setFileName(acceptedFiles[0]?.name);
    setNoFileError('');
    if (acceptedFiles.length >= 1) {
      setFile(acceptedFiles[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    onDrop,
    accept:
      'application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    maxFiles: 1,
    validator: fileSizeValidator,
  });

  const fileRejectionItems = (
    <ul>
      {fileRejections
        .map((item) => item.errors[0].message)
        .filter((value, index, self) => self.indexOf(value) === index)
        .map((item, index) => (
          <li key={item + index} className='text-xs my-1 text-red-600'>
            {item ===
            'File type must be application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document'
              ? `* ${t('translation:only_docx_and_pdf_supported')}`
              : item}
          </li>
        ))}
    </ul>
  );

  const chooseOptions = { label: 'Upload', icon: 'pi pi-upload' };

  const resetFileUpload = (keyname) => {
    // console.log(keyname);
    setValue(keyname, null, { shouldValidate: true });
  };

  // CV Upload End

  // Test CV Resume to be valid

  useEffect(() => {
    if (file) {
      let formData = new FormData();
      formData.append('resume', file);

      testJobApplicationResume.mutate(formData, {
        onSuccess: (data) => {
          if (data?.data === true) {
            setIsResumeValid(true);
            setInvalidPdfError('');
          } else if (data?.data === false) {
            setIsResumeValid(false);
            setInvalidPdfError(
              t(
                'translation:your_cv_seems_to_be_in_an_image_format_please_upload_a_CV_in_a_proper_PDF/WORD_text_format'
              )
            );
          }
        },
        onError: (err) => {
          setIsResumeValid(false);
          ErrorToast({ text: err.response.data.message });
        },
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);

  return (
    <>
      {width < 600 ? (
        <NoMobile />
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className='flex mx-6 lg:mx-0'>
            <div className='flex flex-col w-full h-full max-w-screen-xl mx-auto'>
              {getApplicationFormFields.isLoading ? (
                <div className='flex w-full flex-col mx-auto rounded-lg bg-white shadow-lg my-8 border pt-4 pb-8 px-8'>
                  <div className='flex w-full justify-center'>
                    <div className='text-2xl mb-8'>
                      <Skeleton height='2rem' width='15rem' />
                    </div>
                  </div>
                  <div className='flex flex-col lg:flex-row items-center w-full'>
                    <div className='w-full mb-4 lg:mb-0 lg:w-1/5 relative'>
                      <div className='flex justify-center'>
                        <div className='relative'>
                          <img
                            className='inline object-cover h-32 w-32 rounded-full'
                            src={AddPhoto}
                            alt='Profile'
                          />
                          <EditIlls className='absolute -bottom-3 -right-4 w-16 h-20 lg:w-20 xl:w-20 cursor-pointer' />
                        </div>
                      </div>
                      <Skeleton
                        className='my-4 mx-auto'
                        height='1.625rem'
                        width='8rem'
                      />
                    </div>
                    <div className='w-full lg:w-4/5 px-4'>
                      <div className='grid grid-cols-1 md:grid-cols-3 gap-8'>
                        <Skeleton height='2.625rem' />
                        <Skeleton height='2.625rem' />
                        <Skeleton height='2.625rem' />
                        <Skeleton height='2.625rem' />
                        <Skeleton height='2.625rem' />
                        <Skeleton height='2.625rem' />
                        <Skeleton height='2.625rem' />
                      </div>
                      <hr className='mt-6 mb-8 text-green-300' />
                      <div className='grid grid-cols-1 md:grid-cols-3 gap-8 mt-4'>
                        <Skeleton height='2.625rem' />
                        <Skeleton height='2.625rem' />
                        <Skeleton height='2.625rem' />
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className='flex w-full flex-col mx-auto rounded-lg bg-white shadow-lg my-8 border pt-4 pb-8 px-8'>
                  <div className='flex w-full justify-center'>
                    <p className='text-2xl mb-8 font-bold text-Primary'>
                      {selectJobName}
                    </p>
                  </div>
                  <div className='flex flex-col lg:flex-row items-center w-full'>
                    <div className='w-full mb-4 lg:mb-0 lg:w-1/5 relative'>
                      <div className='flex justify-center'>
                        <Controller
                          name='profileImage'
                          control={control}
                          rules={
                            getApplicationFormFields?.data?.upload_fields?.[0]
                              ?.mandatory
                              ? {
                                  required: t(
                                    'translation:this_is_a_required_field'
                                  ),
                                }
                              : {}
                          }
                          render={(props) => (
                            <input
                              type='file'
                              name='image'
                              id='image'
                              accept='image/jpeg,image/png'
                              className='hidden'
                              ref={fileRef}
                              onChange={(e) => {
                                props.field.onChange(e);
                                onSelectFile(e);
                              }}
                              {...props}
                            />
                          )}
                        />

                        <div className='relative'>
                          <img
                            className='inline object-cover h-32 w-32 rounded-full'
                            src={preview || AddPhoto}
                            alt='Profile'
                          />
                          <EditIlls
                            onClick={triggerFileInput}
                            className='absolute -bottom-3 -right-4 w-16 h-20 lg:w-20 xl:w-20 cursor-pointer'
                          />
                        </div>
                      </div>
                      <p className='text-center text-Primary mt-4 text-lg'>
                        {
                          getApplicationFormFields?.data?.upload_fields?.[0]
                            ?.name
                        }{' '}
                        {getApplicationFormFields?.data?.upload_fields?.[0]
                          ?.mandatory
                          ? '*'
                          : ''}
                      </p>
                      {showImageError ? (
                        <div className='text-xs my-3 text-center text-red-600'>
                          * {t('translation:upto_300kb_supported')}
                        </div>
                      ) : null}
                      <div className='text-xs my-3 text-center text-red-600'>
                        {getFormErrorMessage('profileImage')}
                      </div>
                    </div>
                    <div className='w-full lg:w-4/5 px-4'>
                      <div className='grid grid-cols-1 md:grid-cols-3 gap-8'>
                        {getApplicationFormFields?.data?.data?.map(
                          (fieldItem, index) => {
                            if (
                              index > 5 ||
                              fieldItem.be_name === 'upload_image' ||
                              fieldItem.be_name === 'resume'
                            ) {
                              return null;
                            }
                            return (
                              <div key={fieldItem._id}>
                                {fieldItem.field_type === 'text' ? (
                                  <span className='p-float-label'>
                                    <Controller
                                      name={fieldItem.be_name}
                                      control={control}
                                      rules={
                                        fieldItem.mandatory
                                          ? {
                                              required: t(
                                                'translation:this_is_a_required_field'
                                              ),
                                            }
                                          : {}
                                      }
                                      defaultValue={
                                        fieldItem.be_name === 'name'
                                          ? preFilledDataFromQuery?.name || ''
                                          : ''
                                      }
                                      render={({ field }) => {
                                        if (fieldItem.be_name === 'name') {
                                          return (
                                            <InputText
                                              id={field.be_name}
                                              {...field}
                                            />
                                          );
                                        } else {
                                          return (
                                            <InputText
                                              id={field.be_name}
                                              {...field}
                                            />
                                          );
                                        }
                                      }}
                                    />
                                    <label htmlFor={fieldItem.be_name}>
                                      {`${fieldItem.name} ${
                                        fieldItem.mandatory ? '*' : ''
                                      }`}
                                    </label>
                                  </span>
                                ) : fieldItem.field_type === 'email' ? (
                                  <span className='p-float-label'>
                                    <Controller
                                      name={fieldItem.be_name}
                                      control={control}
                                      rules={
                                        fieldItem.mandatory
                                          ? {
                                              required: t(
                                                'translation:this_is_a_required_field'
                                              ),
                                              pattern: {
                                                value:
                                                  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                                message: t(
                                                  'translation:invalid_email_address'
                                                ),
                                              },
                                            }
                                          : {
                                              pattern: {
                                                value:
                                                  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                                message: t(
                                                  'translation:invalid_email_address'
                                                ),
                                              },
                                            }
                                      }
                                      defaultValue={
                                        fieldItem.be_name === 'email'
                                          ? preFilledDataFromQuery?.email || ''
                                          : ''
                                      }
                                      render={({ field }) => {
                                        if (fieldItem.be_name === 'email') {
                                          return (
                                            <InputText
                                              id={field.be_name}
                                              {...field}
                                              readOnly={location.pathname.includes(
                                                '/apply-through-email/'
                                              )}
                                            />
                                          );
                                        } else {
                                          <InputText
                                            id={field.be_name}
                                            {...field}
                                          />;
                                        }
                                      }}
                                    />
                                    <label htmlFor={fieldItem.be_name}>
                                      {`${fieldItem.name} ${
                                        fieldItem.mandatory ? '*' : ''
                                      }`}
                                    </label>
                                  </span>
                                ) : fieldItem.field_type === 'int' ? (
                                  <span className='p-float-label'>
                                    <Controller
                                      name={fieldItem.be_name}
                                      control={control}
                                      rules={
                                        fieldItem.mandatory
                                          ? {
                                              required: t(
                                                'translation:this_is_a_required_field'
                                              ),
                                              pattern: {
                                                value:
                                                  /^[+-]?((\d+(\.\d*)?)|(\.\d+))$/,
                                                message: t(
                                                  'translation:please_provide_numbers'
                                                ),
                                              },
                                            }
                                          : {
                                              pattern: {
                                                value:
                                                  /^[+-]?((\d+(\.\d*)?)|(\.\d+))$/,
                                                message: t(
                                                  'translation:please_provide_numbers'
                                                ),
                                              },
                                            }
                                      }
                                      defaultValue={
                                        fieldItem.be_name === 'mobile_number'
                                          ? preFilledDataFromQuery?.mobile_number ||
                                            ''
                                          : ''
                                      }
                                      render={({ field }) => {
                                        if (
                                          fieldItem.be_name === 'mobile_number'
                                        ) {
                                          return (
                                            <InputText
                                              id={field.be_name}
                                              {...field}
                                            />
                                          );
                                        } else {
                                          return (
                                            <InputText
                                              id={field.be_name}
                                              {...field}
                                            />
                                          );
                                        }
                                      }}
                                    />
                                    <label htmlFor={fieldItem.be_name}>
                                      {`${fieldItem.name} ${
                                        fieldItem.mandatory ? '*' : ''
                                      }`}
                                    </label>
                                  </span>
                                ) : fieldItem.field_type === 'drop_down' ? (
                                  <span className='p-float-label'>
                                    <Controller
                                      name={fieldItem.be_name}
                                      control={control}
                                      rules={
                                        fieldItem.mandatory
                                          ? {
                                              required: t(
                                                'translation:this_is_a_required_field'
                                              ),
                                            }
                                          : {}
                                      }
                                      render={({ field }) => (
                                        <Dropdown
                                          options={fieldItem.values}
                                          {...field}
                                        />
                                      )}
                                    />
                                    <label htmlFor={fieldItem.be_name}>
                                      {`${fieldItem.name} ${
                                        fieldItem.mandatory ? '*' : ''
                                      }`}
                                    </label>
                                  </span>
                                ) : fieldItem.field_type === 'date' ? (
                                  <span
                                    className={`p-float-label  ${
                                      currentLanguageCode === 'ar'
                                        ? 'left-side-calender'
                                        : ''
                                    }`}
                                  >
                                    <Controller
                                      name={fieldItem.be_name}
                                      control={control}
                                      rules={
                                        fieldItem.mandatory
                                          ? {
                                              required: t(
                                                'translation:this_is_a_required_field'
                                              ),
                                            }
                                          : {}
                                      }
                                      render={({ field }) => (
                                        <Calendar
                                          id={field.be_name}
                                          dateFormat='dd/mm/yy'
                                          monthNavigator
                                          yearNavigator
                                          yearRange='1901:2030'
                                          showIcon
                                          {...field}
                                          onSelect={(e) => {
                                            let event = new Date(e.value);
                                            var newDate = new Date(
                                              event.getTime() -
                                                event.getTimezoneOffset() *
                                                  60 *
                                                  1000
                                            );
                                            let date = JSON.stringify(newDate);
                                            date = date.slice(1, 11);
                                            field.onChange(date);
                                          }}
                                        />
                                      )}
                                    />
                                    <label htmlFor={fieldItem.be_name}>
                                      {`${fieldItem.name} ${
                                        fieldItem.mandatory ? '*' : ''
                                      }`}
                                    </label>
                                  </span>
                                ) : fieldItem.field_type === 'file' ? (
                                  <span className='max-h-10'>
                                    <span className='relative'>
                                      <label
                                        className={` ${
                                          currentLanguageCode === 'ar'
                                            ? 'upload-label-right'
                                            : 'upload-label-left'
                                        } whitespace-nowrap `}
                                        htmlFor={fieldItem.be_name}
                                      >
                                        {`${fieldItem.name} ${
                                          fieldItem.mandatory ? '*' : ''
                                        }`}
                                      </label>
                                    </span>
                                    <Controller
                                      name={fieldItem.be_name}
                                      control={control}
                                      rules={
                                        fieldItem.mandatory
                                          ? {
                                              required: t(
                                                'translation:this_is_a_required_field'
                                              ),
                                            }
                                          : {}
                                      }
                                      render={({ field }) => (
                                        <FileUpload
                                          mode='basic'
                                          maxFileSize={500000}
                                          id={field.be_name}
                                          onValidationFail={(e) => {
                                            setError(fieldItem.be_name, {
                                              type: 'validate',
                                              message: `* ${t(
                                                'translation:upto_500kb_supported'
                                              )}`,
                                            });
                                          }}
                                          onSelect={(e) => {
                                            if (e.files[0].size < 500000) {
                                              field.onChange(e.files[0]);
                                              clearErrors(fieldItem.be_name);
                                            } else {
                                              setError(fieldItem.be_name, {
                                                type: 'validate',
                                                message: `* ${t(
                                                  'translation:upto_500kb_supported'
                                                )} `,
                                              });
                                            }
                                          }}
                                          onClear={(e) => {
                                            resetFileUpload(fieldItem.be_name);
                                          }}
                                          chooseOptions={chooseOptions}
                                          {...field}
                                        />
                                      )}
                                    />
                                  </span>
                                ) : null}
                                {getFormErrorMessage(fieldItem.be_name)}
                              </div>
                            );
                          }
                        )}
                      </div>
                      <hr className='mt-6 mb-8 text-green-300' />
                      <div className='grid grid-cols-1 md:grid-cols-3 gap-8 mt-4'>
                        {getApplicationFormFields?.data?.data?.map(
                          (fieldItem, index) => {
                            if (
                              index <= 5 ||
                              fieldItem.be_name === 'upload_image' ||
                              fieldItem.be_name === 'resume'
                            ) {
                              return null;
                            }
                            return (
                              <div key={fieldItem._id}>
                                {fieldItem.field_type === 'text' ? (
                                  <span className='p-float-label'>
                                    <Controller
                                      name={fieldItem.be_name}
                                      control={control}
                                      rules={
                                        fieldItem.mandatory
                                          ? {
                                              required: t(
                                                'translation:this_is_a_required_field'
                                              ),
                                            }
                                          : {}
                                      }
                                      render={({ field }) => (
                                        <InputText
                                          id={field.be_name}
                                          {...field}
                                        />
                                      )}
                                    />
                                    <label htmlFor={fieldItem.be_name}>
                                      {`${fieldItem.name} ${
                                        fieldItem.mandatory ? '*' : ''
                                      }`}
                                    </label>
                                  </span>
                                ) : fieldItem.field_type === 'email' ? (
                                  <span className='p-float-label'>
                                    <Controller
                                      name={fieldItem.be_name}
                                      control={control}
                                      rules={
                                        fieldItem.mandatory
                                          ? {
                                              required: t(
                                                'translation:this_is_a_required_field'
                                              ),
                                              pattern: {
                                                value:
                                                  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                                message: t(
                                                  'translation:invalid_email_address'
                                                ),
                                              },
                                            }
                                          : {
                                              pattern: {
                                                value:
                                                  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                                message: t(
                                                  'translation:invalid_email_address'
                                                ),
                                              },
                                            }
                                      }
                                      render={({ field }) => (
                                        <InputText
                                          id={field.be_name}
                                          {...field}
                                        />
                                      )}
                                    />
                                    <label htmlFor={fieldItem.be_name}>
                                      {`${fieldItem.name} ${
                                        fieldItem.mandatory ? '*' : ''
                                      }`}
                                    </label>
                                  </span>
                                ) : fieldItem.field_type === 'int' ? (
                                  <span className='p-float-label'>
                                    <Controller
                                      name={fieldItem.be_name}
                                      control={control}
                                      rules={
                                        fieldItem.mandatory
                                          ? {
                                              required: t(
                                                'translation:this_is_a_required_field'
                                              ),
                                              pattern: {
                                                value:
                                                  /^[+-]?((\d+(\.\d*)?)|(\.\d+))$/,
                                                message: t(
                                                  'translation:invalid_number_format'
                                                ),
                                              },
                                            }
                                          : {
                                              pattern: {
                                                value:
                                                  /^[+-]?((\d+(\.\d*)?)|(\.\d+))$/,
                                                message: t(
                                                  'translation:invalid_number_format'
                                                ),
                                              },
                                            }
                                      }
                                      render={({ field }) => (
                                        <InputText
                                          id={field.be_name}
                                          {...field}
                                        />
                                      )}
                                    />
                                    <label htmlFor={fieldItem.be_name}>
                                      {`${fieldItem.name} ${
                                        fieldItem.mandatory ? '*' : ''
                                      }`}
                                    </label>
                                  </span>
                                ) : fieldItem.field_type === 'drop_down' ? (
                                  <span className='p-float-label'>
                                    <Controller
                                      name={fieldItem.be_name}
                                      control={control}
                                      rules={
                                        fieldItem.mandatory
                                          ? {
                                              required: t(
                                                'translation:this_is_a_required_field'
                                              ),
                                            }
                                          : {}
                                      }
                                      render={({ field }) => (
                                        <Dropdown
                                          options={fieldItem.values}
                                          {...field}
                                        />
                                      )}
                                    />
                                    <label htmlFor={fieldItem.be_name}>
                                      {`${fieldItem.name} ${
                                        fieldItem.mandatory ? '*' : ''
                                      }`}
                                    </label>
                                  </span>
                                ) : fieldItem.field_type === 'date' ? (
                                  <span
                                    className={`p-float-label  ${
                                      currentLanguageCode === 'ar'
                                        ? 'left-side-calender'
                                        : ''
                                    }`}
                                  >
                                    <Controller
                                      name={fieldItem.be_name}
                                      control={control}
                                      rules={
                                        fieldItem.mandatory
                                          ? {
                                              required: t(
                                                'translation:this_is_a_required_field'
                                              ),
                                            }
                                          : {}
                                      }
                                      render={({ field }) => (
                                        <Calendar
                                          id={field.be_name}
                                          dateFormat='dd/mm/yy'
                                          onSelect={(e) => {
                                            let event = new Date(e.value);
                                            var newDate = new Date(
                                              event.getTime() -
                                                event.getTimezoneOffset() *
                                                  60 *
                                                  1000
                                            );
                                            let date = JSON.stringify(newDate);
                                            date = date.slice(1, 11);
                                            field.onChange(date);
                                          }}
                                          monthNavigator
                                          yearNavigator
                                          yearRange='1901:2030'
                                          showIcon
                                          {...field}
                                        />
                                      )}
                                    />
                                    <label htmlFor={fieldItem.be_name}>
                                      {`${fieldItem.name} ${
                                        fieldItem.mandatory ? '*' : ''
                                      }`}
                                    </label>
                                  </span>
                                ) : fieldItem.field_type === 'file' ? (
                                  <span className='max-h-10'>
                                    <span className='relative'>
                                      <label
                                        className={`${
                                          currentLanguageCode === 'ar'
                                            ? 'upload-label-right'
                                            : 'upload-label-left'
                                        } whitespace-nowrap `}
                                        htmlFor={fieldItem.be_name}
                                      >
                                        {`${fieldItem.name} ${
                                          fieldItem.mandatory ? '*' : ''
                                        }`}
                                      </label>
                                    </span>
                                    <Controller
                                      name={fieldItem.be_name}
                                      control={control}
                                      rules={
                                        fieldItem.mandatory
                                          ? {
                                              required: t(
                                                'translation:this_is_a_required_field'
                                              ),
                                            }
                                          : {}
                                      }
                                      render={({ field }) => (
                                        <FileUpload
                                          mode='basic'
                                          maxFileSize={500000}
                                          id={field.be_name}
                                          onValidationFail={(e) => {
                                            setError(fieldItem.be_name, {
                                              type: 'validate',
                                              message: `* ${t(
                                                'translation:upto_500kb_supported'
                                              )}`,
                                            });
                                          }}
                                          onSelect={(e) => {
                                            if (e.files[0].size < 500000) {
                                              field.onChange(e.files[0]);
                                              clearErrors(fieldItem.be_name);
                                            } else {
                                              setError(fieldItem.be_name, {
                                                type: 'validate',
                                                message: `* ${t(
                                                  'translation:upto_500kb_supported'
                                                )}`,
                                              });
                                            }
                                          }}
                                          onClear={(e) => {
                                            resetFileUpload(fieldItem.be_name);
                                          }}
                                          chooseOptions={chooseOptions}
                                          {...field}
                                        />
                                      )}
                                    />
                                  </span>
                                ) : null}
                                {getFormErrorMessage(fieldItem.be_name)}
                              </div>
                            );
                          }
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {isAppliedThroughEmail ? null : (
                <div className='w-full flex flex-col lg:flex-row mx-auto rounded-lg bg-white shadow-lg my-8 border p-4'>
                  <div className='flex flex-col mb-4 lg:mb-0 flex-1 bg-AtsFriendlyBg bg-no-repeat bg-center bg-opacity-10'>
                    <div className='flex justify-center w-full text-Primary font-bold text-2xl text-center'>
                      <div className='w-11/12 py-2 px-2 border-b border-Gray3 border-opacity-20'>
                        {t('translation:recommended_cv_format')}
                      </div>
                    </div>
                    <div className='w-full px-12 my-4 lg:border-r border-Gray3 border-opacity-20'>
                      <div className='flex my-4 text-333333'>
                        <span
                          className={`${
                            currentLanguageCode === 'ar' ? 'ml-2' : 'mr-2'
                          }`}
                        >
                          <CheckIcon />
                        </span>
                        <span>
                          {t('translation:use_a_chronological_cv_format')}
                        </span>
                      </div>
                      <div className='flex my-4 text-333333'>
                        <span
                          className={`${
                            currentLanguageCode === 'ar' ? 'ml-2' : 'mr-2'
                          }`}
                        >
                          <CheckIcon />
                        </span>
                        <span>
                          {t('translation:remove_graphics_or_tables')}
                        </span>
                      </div>
                      <div className='flex my-4 text-333333'>
                        <span
                          className={`${
                            currentLanguageCode === 'ar' ? 'ml-2' : 'mr-2'
                          }`}
                        >
                          <CheckIcon />
                        </span>
                        <span>
                          {t('translation:correctly_label_your_sections')}
                        </span>
                      </div>
                      <div className='flex my-4 text-333333'>
                        <span
                          className={`${
                            currentLanguageCode === 'ar' ? 'ml-2' : 'mr-2'
                          }`}
                        >
                          <CheckIcon />
                        </span>
                        <span>
                          {t('translation:include_job_relevant_keywords')}
                        </span>
                      </div>
                      <div className='flex my-4 text-333333'>
                        <span
                          className={`${
                            currentLanguageCode === 'ar' ? 'ml-2' : 'mr-2'
                          }`}
                        >
                          <CheckIcon />
                        </span>
                        <span>
                          {t('translation:type_your_cv_in_a_common_font')}
                        </span>
                      </div>
                      <div className='flex my-4 text-333333'>
                        <span
                          className={`${
                            currentLanguageCode === 'ar' ? 'ml-2' : 'mr-2'
                          }`}
                        >
                          <CheckIcon />
                        </span>
                        <span>
                          {t(
                            'translation:save_your_cv_in_a_proper_file_type_(.docx or pdf)'
                          )}
                        </span>
                      </div>
                      <div className='flex mt-10 text-333333'>
                        <span
                          className={`${
                            currentLanguageCode === 'ar' ? 'ml-4' : 'mr-4'
                          }`}
                        >
                          <Checkbox
                            checked={checkboxValue}
                            onChange={(e) => {
                              setCheckboxValue(e.checked);
                            }}
                          />
                        </span>
                        <span className='w-10/12 text-Primary font-bold'>
                          {t(
                            'translation:my_cv_is_in_the_recommended_file_format_(.docx/pdf)_and_does_not_contain_any_graphics'
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className='flex flex-col flex-1'>
                    <div className='flex justify-center w-full text-Primary font-bold text-2xl text-center'>
                      <div className='w-11/12 py-2 px-2 border-b border-Gray3 border-opacity-20'>
                        {t('translation:upload_your_cv')}
                      </div>
                    </div>
                    <div
                      className={`w-full h-full flex flex-col items-center px-12 my-4 ${
                        !checkboxValue ? 'cursor-not-allowed opacity-50' : ''
                      }`}
                    >
                      <div
                        className={`w-full flex flex-col px-4 mt-4 ${
                          !checkboxValue ? 'pointer-events-none ' : ''
                        } `}
                      >
                        <div
                          {...getRootProps()}
                          className='w-full flex text-grey-3-900 flex-col items-center justify-center h-56 rounded border-opacity-50 border-dashed border-2 border-C4C4C4'
                        >
                          <input {...getInputProps()} />
                          <div className='flex items-center justify-center'>
                            <UploadIcon className='w-16' />
                          </div>
                          <div className='flex items-center text-Gray3 text-center mb-4 justify-center'>
                            {fileName
                              ? fileName
                              : t('translation:click_here_to_upload')}
                          </div>
                        </div>
                      </div>
                      <div
                        className={`flex w-full my-1 text-xs ${
                          checkboxValue ? 'opacity-50' : ''
                        } `}
                      >
                        <p>
                          *{' '}
                          {t(
                            'translation:only_files_from_internal_storage_can_be_uploaded'
                          )}
                        </p>
                      </div>
                      {fileRejectionItems && (
                        <div className='flex w-full my-1'>
                          {fileRejectionItems}
                        </div>
                      )}
                      {noFileError && (
                        <div className='flex w-full my-1 text-xs text-red-600'>
                          {noFileError}
                        </div>
                      )}
                      {invalidPdfError && (
                        <div className='flex w-full my-1 text-xs text-red-600'>
                          {invalidPdfError}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}

              {isAppliedThroughEmail ? (
                <div className='w-full flex justify-center items-center'>
                  <button
                    className={`w-24 md:w-32 text-center cursor-pointer mx-auto my-4`}
                  >
                    <PrimaryButton isLoading={postJobApplication.isLoading}>
                      <p className='text-sm'>{t('translation:apply')}</p>
                    </PrimaryButton>
                  </button>
                </div>
              ) : (
                <div className='w-full flex justify-center items-center'>
                  <button
                    disabled={
                      (!file &&
                        getApplicationFormFields?.data?.upload_fields?.[1]
                          ?.mandatory) ||
                      !checkboxValue ||
                      !isResumeValid
                    }
                    className={`w-24 md:w-32 text-center cursor-pointer mx-auto my-4  ${
                      (!file &&
                        getApplicationFormFields?.data?.upload_fields?.[1]
                          ?.mandatory) ||
                      !checkboxValue ||
                      !isResumeValid
                        ? 'cursor-not-allowed opacity-50'
                        : ''
                    } `}
                  >
                    <PrimaryButton
                      isLoading={
                        postJobApplication.isLoading ||
                        testJobApplicationResume.isLoading
                      }
                    >
                      <p className='text-sm'>{t('translation:apply')}</p>
                    </PrimaryButton>
                  </button>
                </div>
              )}
            </div>
          </div>
        </form>
      )}
    </>
  );
}
