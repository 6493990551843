import React, { useState, useRef, useEffect } from 'react';
import PrimaryButton from '../../../components/Buttons/ButtonPrimary';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useQuery } from 'react-query';
import makeAPIRequest from '../../../services/makeApiRequest.js';
import apiEndPoints from '../../../services/apiEndpoints';
import { Dropdown } from 'primereact/dropdown';
import { useForm, useFieldArray } from 'react-hook-form';

import { ReactComponent as LevelIlls } from '../../../assets/images/PersonalityProfiling/level.svg';
import { ReactComponent as TimerIlls } from '../../../assets/images/SkillAssessment/TimerIlls.svg';

export default function DiscAssessment() {
  const [discQuestions, setDiscQuestions] = useState(null);
  const [activeSlide, setActiveSlide] = useState(1);
  const [sliderstate, setSliderState] = useState();

  const slider1 = useRef();

  const {  control, handleSubmit } = useForm({
    defaultValues: {
      test: [{ value: '' }],
    },
  });
  const { fields } = useFieldArray({
    control,
    name: 'test',
  });

  useEffect(() => {
    setSliderState(slider1.current);
  }, []);

  const Discdata = useQuery(
    ['disc-assessment-data'],
    async () => {
      const { data } = await makeAPIRequest.get(apiEndPoints.DISCASSESSMENT);
      return data;
    },
    {
      onSuccess: (data) => {
        setDiscQuestions(data.data.questions);
      },
    }
  );

  console.log(Discdata)
  const numbers = [
    { name: 1, value: 1 },
    { name: 2, value: 2 },
    { name: 3, value: 3 },
    { name: 4, value: 4 },
  ];

  var settings = {
    dots: false,
    infinite: false,
    speed: 100,
    slidesToShow: 4,
    slidesToScroll: 4,
    draggable: false,
    arrows: false,
    afterChange: (current) => setActiveSlide(current / 4 + 1),
  };

  const onSubmit = (data) => {
    console.log(data);
  }

  return (
    <div className='bg-gray-50 '>
      <div className='flex justify-center py-4'>
        <TimerIlls className='w-4 h-4 mt-1' />
        <p>
          <span className='text-Primary pl-2'>2:25</span> Time left
        </p>
      </div>
      <div className='w-11/12 border rounded-lg shadow-lg bg-white mx-auto mt-4 min-h-full max-w-screen-xl'>
        <form onSubmit={handleSubmit(onSubmit)}>
        <div className='py-4 px-8'>
          <p className=''>
            Please use the following scale to rate the statements :
          </p>
          <div className='mt-2 w-2/4 flex justify-start'>
            <LevelIlls />
          </div>
          <div className='w-full'>
            <p className='flex w-full justify-end pb-4 pr-4 text-sm text-gray-600'>
              {discQuestions
                ? `${activeSlide}/${
                    discQuestions && Object.keys(discQuestions).length / 4
                  }`
                : null}
            </p>
            <Slider {...settings} ref={(slider) => (slider1.current = slider)}>
              {discQuestions &&
                Object.keys(discQuestions).map((item, key) => (
                  <div
                    className='py-4 pl-2 border-Primary border min-h-full rounded-lg md:text-sm lg:text-normal disc-assessment'
                    key={item['0']}
                  >
                    {fields.map((mapValues) => {
                      return (
                        <div key={mapValues.id}>
                          <div className='flex w-full relative lg:pt-1 xl:py-2 md:pt-2'>
                            <p className='w-4/5 mb-2'>
                              {discQuestions[item][0]}
                            </p>
                            <div className='absolute md:right-1 xl:right-4 w-8'>
                              <Dropdown
                                className='border-gray-400 text-gray-600 h-6'
                                options={numbers}
                                optionLabel='name'
                                optionValue='value'
                              />
                            </div>
                          </div>
                          <div className='flex w-full relative lg:pt-1 xl:py-2 md:pt-2'>
                            <p className='w-4/5 mb-2'>
                              {discQuestions[item][1]}
                            </p>
                            <div className='absolute md:right-1 xl:right-4 w-8'>
                              <Dropdown
                                className='border-gray-400 text-gray-600 h-6'
                                options={numbers}
                                optionLabel='name'
                                optionValue='value'
                              />
                            </div>
                          </div>
                          <div className='flex w-full relative lg:pt-1 xl:py-2 md:pt-2'>
                            <p className='w-4/5 mb-2'>
                              {discQuestions[item][2]}
                            </p>
                            <div className='absolute md:right-1 xl:right-4 w-8'>
                              <Dropdown
                                className='border-gray-400 text-gray-600 h-6'
                                options={numbers}
                                optionLabel='name'
                                optionValue='value'
                              />
                            </div>
                          </div>
                          <div className='flex w-full relative lg:py-1 xl:py-2 md:py-2'>
                            <p className='w-4/5 mb-2'>
                              {discQuestions[item][3]}
                            </p>
                            <div className='absolute md:right-1 xl:right-4 w-8'>
                              <Dropdown
                                className='border-gray-400 text-gray-600 h-6'
                                options={numbers}
                                optionLabel='name'
                                optionValue='value'
                              />
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                ))}
            </Slider>
          </div>
        </div>
        <div className='flex w-72 mx-auto my-4'>
          <button
            className='text-sm mr-4 w-32'
            onClick={() => {
              sliderstate.slickPrev();
            }}
          >
            <PrimaryButton>Prev</PrimaryButton>
          </button>
          {`${activeSlide}` ===
          `${discQuestions && Object.keys(discQuestions).length / 4}` ? (
            <button className='text-sm w-32'>
              <PrimaryButton>Submit</PrimaryButton>
            </button>
          ) : (
            <button
              className='text-sm mr-4 w-32'
              onClick={() => {
                sliderstate.slickNext();
              }}
            >
              <PrimaryButton>Next</PrimaryButton>
            </button>
          )}
        </div>
        </form>
      </div>
    </div>
  );
}
