import React, { useContext, useState } from 'react';
import { MainContext } from 'contexts/mainContext';
import PrimaryButton from '../../../components/Buttons/ButtonPrimary';
import { useLocation, useHistory } from 'react-router-dom';
import queryString from 'query-string';
import { useQuery } from 'react-query';
import makeAPIRequest from '../../../services/makeApiRequest.js';
import apiEndPoints from '../../../services/apiEndpoints';
import dateFormatter from 'helper/dateFormatter';
import { Skeleton } from 'primereact/skeleton';
import ChromeRecomendedBanner from 'components/ChromeRecomendedBanner';
import { Checkbox } from 'primereact/checkbox';
import ModalVideo from 'react-modal-video';
import Tip from 'components/Tooltip';

import { useTranslation } from 'react-i18next';
import cookies from 'js-cookie';

import { ReactComponent as CorrectIlls } from '../../../assets/images/SkillAssessment/correctIlls.svg';
import { ReactComponent as LinkExpired } from '../../../assets/images/VideoProfileAssessment/LinkExpired.svg';
import { ReactComponent as CalenderIlls } from '../../../assets/images/SkillAssessment/calender.svg';
import { ReactComponent as InfoIlls } from '../../../assets/images/SkillAssessment/infoIlls.svg';
// import { ReactComponent as MainpageIlls } from '../../../assets/images/SkillAssessment/MainpageIlls.svg';
// import { ReactComponent as MainpageIlls2 } from '../../../assets/images/SkillAssessment/MainpageIlls2.svg';

export default function AssessmentInfo() {
  const currentLanguageCode = cookies.get('i18next');
  const { t } = useTranslation(['translation']);
  const { mainState, mainDispatch } = useContext(MainContext);
  const location = useLocation();
  const history = useHistory();
  let queryStringValues = queryString.parse(location.search);
  const [checkboxValue, setCheckboxValue] = useState(false);
  const [isVideoOpen, setVideoOpen] = useState(false);

  const getAssessmentInfoDetails = useQuery(
    ['getAssessmentInfoDetails'],
    async () => {
      const { data } = await makeAPIRequest.get(
        `${apiEndPoints.ASSESSMENTSTAGES}?applicant_id=${queryStringValues.applicant_id}&company_name=${queryStringValues.company_name}&stage=${queryStringValues.stage}`
      );
      return data;
    },
    {
      onSuccess: (data) => {
        queryStringValues = {
          ...queryStringValues,
          stageName: data.stage_name,
        };
        const searchString = queryString.stringify(queryStringValues);
        history.push({
          pathname: location.pathname,
          search: `?${searchString}`,
        });
        mainDispatch({
          type: 'SETNAMELOGOCOLOR',
          payload: {
            company_name: data.company_name,
            company_logo: data.company_logo,
            company_color: data.company_color,
          },
        });
      },
    }
  );

  return (
    <>
      <ChromeRecomendedBanner />
      <div className='bg-gray-50 mx-auto w-full max-w-main-page min-h-85vh h-full flex items-center justify-center '>
        <div className='felx items-center felx-nowrap pt-28 pb-32 relative'>
          {getAssessmentInfoDetails?.data?.message ===
          'You have missed the assessment.' ? (
            <LinkExpired className='w-20 h-20 mx-auto' />
          ) : (
            <CorrectIlls className='w-20 h-20 mx-auto' />
          )}
          {/* <MainpageIlls2 className='absolute h-52 top-28 left-20' />
        <MainpageIlls className='absolute h-72 right-8 top-20' /> */}
          <p className='text-2xl font-medium text-center mt-4'>
            {getAssessmentInfoDetails?.data?.message}
          </p>
          {getAssessmentInfoDetails?.data?.message ===
          'Your Assessment has been already submitted' ? null : (
            <div className='flex w-full justify-center mt-2'>
              {getAssessmentInfoDetails.isLoading ? (
                <div className='flex w-full justify-center'>
                  <Skeleton
                    className={`my-auto ${currentLanguageCode === 'ar' ? 'ml-2' : 'mr-2'}`}
                    height='1.2rem'
                    width='1.2rem'
                  />{' '}
                  <Skeleton height='1.5rem' width='26rem' />
                </div>
              ) : getAssessmentInfoDetails?.data?.data?.range_info ? (
                <div className='flex w-full justify-center'>
                  <CalenderIlls className={`w-4 h-4 mt-1 ${currentLanguageCode === 'ar' ? 'ml-2' : 'mr-2'}`} />
                  <p className='text-Primary font-bold'>
                    {dateFormatter(
                      getAssessmentInfoDetails?.data?.data?.range_info
                        ?.from_date,
                      'LLL'
                    )}{' '}
                    <span className='text-gray-600 font-bold'>-</span>{' '}
                    {dateFormatter(
                      getAssessmentInfoDetails?.data?.data?.range_info?.to_date,
                      'LLL'
                    )}
                  </p>
                </div>
              ) : null}
            </div>
          )}

          <div className='flex justify-center'>
            <div className='flex my-3 items-center'>
              <Checkbox
                inputId='check1'
                className={`${currentLanguageCode === 'ar' ? 'ml-2' : 'mr-2'}`}
                checked={checkboxValue}
                onChange={(e) => {
                  setCheckboxValue(e.checked);
                }}
              />
              <button
                onClick={() => {
                  setVideoOpen(true);
                }}
              >
                <p className='hover:underline'>
                  {t('translation:watch_an_overview_of_assessment')}
                </p>
              </button>
            </div>
          </div>
          <div>
            {getAssessmentInfoDetails?.data?.message ===
              'Your Assessment has been already submitted' ||
            getAssessmentInfoDetails?.data?.message ===
              'You have missed the assessment.' ? null : (
              <div className='w-28 mx-auto mt-12'>
                {getAssessmentInfoDetails?.data?.data?.login &&
                checkboxValue ? (
                  <button
                    onClick={() => {
                      queryStringValues = {
                        ...queryStringValues,
                        company_display_name: mainState.company_name,
                        company_logo: mainState.company_logo,
                        company_color: mainState.company_color,
                      };
                      const searchString =
                        queryString.stringify(queryStringValues);
                      history.push({
                        pathname: '/prehire-assessment/instructionsVideo',
                        search: `?${searchString}`,
                      });
                    }}
                    className='w-28'
                  >
                    <PrimaryButton>{t('translation:start')}</PrimaryButton>
                  </button>
                ) : (
                  <Tip
                    content={`${
                      getAssessmentInfoDetails?.data?.data?.login &&
                      !checkboxValue
                        ? 'Enable checkbox'
                        : ''
                    }`}
                  >
                    <button
                      disabled={true}
                      className='w-28 opacity-80 cursor-not-allowed'
                    >
                      <PrimaryButton>{t('translation:start')}</PrimaryButton>
                    </button>
                  </Tip>
                )}
              </div>
            )}

            <div className='flex items-center justify-center mt-4'>
              <InfoIlls className={`w-4 h-4 ${currentLanguageCode === 'ar' ? 'ml-1' : 'mr-1'}`} />
              {getAssessmentInfoDetails?.data?.message ===
              'You have missed the assessment.' ? (
                <p className='text-sm text-4F4F4F'>
                  {t(
                    'translation:as_you_failed_to_take_assessment_in_given_time_line_link_has_been_expired'
                  )}
                </p>
              ) : (
                <p className='text-sm text-4F4F4F'>
                  {t(
                    'translation:the_credentials_sent_to_your_registered_email_id_are_valid_only_for_a_single_use'
                  )}
                </p>
              )}
            </div>
            {getAssessmentInfoDetails?.data?.message ===
              'You have missed the assessment.' ||
            getAssessmentInfoDetails?.data?.message ===
              'Your Assessment has been already submitted' ? null : (
              <p className='text-center text-sm text-4F4F4F'>
                {t(
                  'translation:please_login_when_you_are_ready_to_take_your_assessment'
                )}
              </p>
            )}
          </div>
        </div>
        <ModalVideo
          channel='custom'
          autoplay
          isOpen={isVideoOpen}
          url={
            'https://stgaptahr.blob.core.windows.net/devaptahr/How%20to%20take%20a%20PRE-HIRE%20asssessment.mp4'
          }
          onClose={() => setVideoOpen(false)}
        />
      </div>
    </>
  );
}
