import React, { useEffect, useContext } from 'react';
import { Route, Switch } from 'react-router-dom';
// import { useQuery } from 'react-query';
import { MainContext } from 'contexts/mainContext';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
// import makeAPIRequest from 'services/makeApiRequest.js';
// import apiEndPoints from 'services/apiEndpoints';
// import { ErrorToast } from 'services/toasterService.js';
import AssessmentInfo from './Components/AssessmentInfo';
import Instructions from './Components/instructions';
import VideoInterview from './Components/VideoInterview';
import RegsiterSuccess from './Components/registerSuccess';
import NoMobile from 'helper/NoMobile';

export default function TechnicalInterview({ width }) {
  const { mainDispatch } = useContext(MainContext);
  // const history = useHistory();
  const location = useLocation();
  const queryStringValues = queryString.parse(location.search);

  useEffect(() => {
    if (queryStringValues?.company_display_name) {
      mainDispatch({
        type: 'SETNAMELOGOCOLOR',
        payload: {
          company_name: queryStringValues?.company_display_name,
          company_logo: queryStringValues?.company_logo,
          company_color: queryStringValues?.company_color,
        },
      });
    }
    // eslint-disable-next-line
  }, []);

  if (width < 750) {
    return <NoMobile />;
  }

  return (
    <div className='bg-gray-50'>
      <Switch>
        <Route exact path='/technical-interview'>
          <AssessmentInfo />
        </Route>
        <Route path='/technical-interview/instructions'>
          <Instructions />
        </Route>
        <Route path='/technical-interview/video-interview'>
          <VideoInterview />
        </Route>
        <Route path='/technical-interview/success'>
          <RegsiterSuccess />
        </Route>
      </Switch>
    </div>
  );
}
