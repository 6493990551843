import React, { useContext } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { useQuery, useMutation } from 'react-query';
import { MainContext } from 'contexts/mainContext';

import makeAPIRequest from 'services/makeApiRequest.js';
import apiEndPoints from 'services/apiEndpoints';

import { Skeleton } from 'primereact/skeleton';

import ApplyJob from '../components/ApplyJob';

import queryString from 'query-string';

import { useTranslation } from 'react-i18next';
import cookies from 'js-cookie';

import { ReactComponent as LocationIlls } from '../../../assets/images/JobApplication/locationIlls.svg';
import { ReactComponent as ExperienceIlls } from '../../../assets/images/JobApplication/ExperienceIlls.svg';
import { ReactComponent as DateIlls } from '../../../assets/images/JobApplication/DateIlls.svg';

import dateFormatter from 'helper/dateFormatter';
import useJobDetails from '../../../hooks/useGetJobDetails';

import RegsiterSuccess from '../components/registerSuccess';
import ErrorMessage from '../components/ErrorMessage';

const ApplyThroughEmail = () => {
  const { t } = useTranslation(['translation']);
  const currentLanguageCode = cookies.get('i18next');
  const history = useHistory();
  const location = useLocation();

  const { mainDispatch } = useContext(MainContext);

  const queryParams = queryString.parse(location.search);

  const { company, jobId } = useParams();
  const redirectToViewJobDetails = () => {
    history.push(`/jobs/apply-through-email-job-details/${company}/${jobId}`);
  };

  // eslint-disable-next-line no-unused-vars
  const getApplicationFormJobData = useJobDetails({
    enable: !!jobId,
    jobId,
    company,
  });

  const getApplicationFormFields = useQuery(
    ['getApplicationFormFields', company],
    async () => {
      const { data } = await makeAPIRequest.get(
        `${apiEndPoints.APPLICANTJOBAPPFIELDS}?company_name=${company}`
      );
      return data;
    },
    {
      enabled: !!jobId,
    }
  );

  const getApplicationFormJobs = useQuery(
    ['getApplicationFormJobs', company, queryParams.client],
    async () => {
      let resData;
      if (!!queryParams.client) {
        resData = await makeAPIRequest.get(
          `${apiEndPoints.APPLICANTJOBS}?company=${company}&client=${queryParams.client}`
        );
      } else {
        resData = await makeAPIRequest.get(
          `${apiEndPoints.APPLICANTJOBS}?company=${company}`
        );
      }
      return resData.data;
    },
    {
      enabled: !jobId,
      onSuccess: (data) => {
        mainDispatch({
          type: 'SETNAMELOGOCOLOR',
          payload: {
            company_name: data.company_name,
            company_logo: data.company_logo,
            company_color: data.company_color,
          },
        });
      },
      onError: (err) => {
        mainDispatch({
          type: 'SETNAMELOGOCOLOR',
          payload: {
            company_name: err.response?.data?.company_name,
            company_logo: err.response?.data?.company_logo,
            company_color: err.response?.data?.company_color,
          },
        });
      },
    }
  );

  const postJobApplication = useMutation(async (data) => {
    const res = await makeAPIRequest.post(apiEndPoints.APPLICANTAPI, data);
    return res.data;
  });

  if (queryParams.view === 'success')
    <RegsiterSuccess getApplicationFormJobs={getApplicationFormJobs} />;
  if (queryParams.view === 'error')
    return <ErrorMessage getApplicationFormJobs={getApplicationFormJobs} />;
  return (
    <section className='py-10'>
      {getApplicationFormJobData.isLoading ? (
        <div
          className={`w-full  max-w-screen-xl mx-auto  border bg-white rounded-lg shadow-lg p-6 ${
            currentLanguageCode === 'ar' ? 'pl-4' : 'pr-4'
          }`}
        >
          <div className='text-2xl'>
            <Skeleton height='2rem' width='12rem' />
          </div>
          <div className='mt-4 flex'>
            <div
              className={`flex ${
                currentLanguageCode === 'ar'
                  ? 'sm:ml-2 lg:ml-3'
                  : 'sm:mr-2 lg:mr-3'
              }`}
            >
              <Skeleton height='1rem' width='1rem' />
              <div
                className={`text-sm text-gray-400 pt-1 ${
                  currentLanguageCode === 'ar' ? 'pr-1' : 'pl-1'
                }`}
              >
                <Skeleton height='1.3rem' width='8rem' />
              </div>
            </div>
            <Skeleton
              className={`${currentLanguageCode === 'ar' ? 'ml-2' : 'mr-2'}`}
              height='0.75rem'
              width='2px'
            />
            <div
              className={`flex ${
                currentLanguageCode === 'ar'
                  ? 'sm:ml-2 lg:ml-3'
                  : 'sm:mr-2 lg:mr-3'
              }`}
            >
              <Skeleton height='1rem' width='1rem' />
              <div
                className={`text-sm text-gray-400 pt-1 ${
                  currentLanguageCode === 'ar' ? 'pr-1' : 'pl-1'
                }`}
              >
                <Skeleton height='1.3rem' width='8rem' />
              </div>
            </div>
            <Skeleton
              className={`${currentLanguageCode === 'ar' ? 'ml-2' : 'mr-2'}`}
              height='0.75rem'
              width='2px'
            />
            <div
              className={`flex ${
                currentLanguageCode === 'ar'
                  ? 'sm:ml-2 lg:ml-3'
                  : 'sm:mr-2 lg:mr-3'
              }`}
            >
              <Skeleton height='1rem' width='1rem' />
              <div
                className={`text-sm text-gray-400 pt-1 ${
                  currentLanguageCode === 'ar' ? 'pr-1' : 'pl-1'
                }`}
              >
                <Skeleton height='1.3rem' width='12rem' />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          {getApplicationFormJobData.data?.data?.data.map((job) => {
            return (
              <div
                className={`border bg-white rounded-lg shadow-lg  p-6 w-full  max-w-screen-xl mx-auto ${
                  currentLanguageCode === 'ar' ? 'pl-4' : 'pr-4'
                }`}
                key={job.job_id}
              >
                <p className='text-2xl'>{job.jobtitle}</p>
                <div className='mt-4 flex flex-col md:flex-row'>
                  <div
                    className={`flex ${
                      currentLanguageCode === 'ar'
                        ? 'sm:ml-2 lg:ml-3'
                        : 'sm:mr-2 lg:mr-3'
                    }`}
                  >
                    <LocationIlls className='w-4 h-4' />
                    <p
                      className={`text-sm text-gray-400 pt-1 ${
                        currentLanguageCode === 'ar' ? 'pr-1' : 'pl-1'
                      }`}
                    >
                      {job.location}
                    </p>
                  </div>
                  <div
                    className={`h-3 w-0.5 bg-gray-700 hidden md:block ${
                      currentLanguageCode === 'ar'
                        ? 'sm:ml-2 lg:ml-3'
                        : 'sm:mr-2 lg:mr-3'
                    }`}
                  ></div>
                  <div
                    className={`flex ${
                      currentLanguageCode === 'ar'
                        ? 'sm:ml-2 lg:ml-3'
                        : 'sm:mr-2 lg:mr-3'
                    }`}
                  >
                    <DateIlls className='w-4 h-4' />
                    <p
                      className={`text-sm text-gray-400 pt-1 ${
                        currentLanguageCode === 'ar' ? 'pr-1' : 'pl-1'
                      }`}
                    >
                      {dateFormatter(job.posted_date, 'll')}
                    </p>
                  </div>
                  <div
                    className={`h-3 w-0.5 bg-gray-700 hidden md:block ${
                      currentLanguageCode === 'ar'
                        ? 'sm:ml-2 lg:ml-3'
                        : 'sm:mr-2 lg:mr-3'
                    }`}
                  ></div>
                  <div
                    className={`flex ${
                      currentLanguageCode === 'ar'
                        ? 'sm:ml-2 lg:ml-3'
                        : 'sm:mr-2 lg:mr-3'
                    }`}
                  >
                    <ExperienceIlls className='w-4 h-4' />
                    <p
                      className={`text-sm text-gray-400 pt-1 ${
                        currentLanguageCode === 'ar' ? 'pr-1' : 'pl-1'
                      }`}
                    >
                      {`Experience: ${job.experience} ${
                        job.experience === '0-1' ? 'year' : 'years'
                      }`}
                    </p>
                  </div>

                  <div
                    className={`flex ${
                      currentLanguageCode === 'ar'
                        ? 'sm:ml-2 lg:ml-3'
                        : 'sm:mr-2 lg:mr-3'
                    }`}
                  >
                    <p
                      className={`text-sm pt-1 text-Primary cursor-pointer underline font-bold ${
                        currentLanguageCode === 'ar' ? 'pr-1' : 'pl-1'
                      }`}
                      onClick={redirectToViewJobDetails}
                    >
                      {t('translation:view_more')}
                    </p>
                  </div>
                </div>
              </div>
            );
          })}
        </>
      )}

      <div>
        <ApplyJob
          getApplicationFormFields={getApplicationFormFields}
          selectJobName={
            getApplicationFormJobData.isLoading
              ? ''
              : getApplicationFormJobData.data?.data?.data[0].jobtitle
          }
          company={company}
          jobId={jobId}
          postJobApplication={postJobApplication}
          isAppliedThroughEmail
          preFilledDataFromQuery={queryParams}
        />
      </div>
    </section>
  );
};

export default ApplyThroughEmail;
