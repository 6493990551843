import React, { useEffect, useState } from 'react';
import SecondaryButton from '../../../components/Buttons/SecondaryButton';
import { useTimer } from 'react-timer-hook';
import { usePageLeave, useWindowSize } from 'react-use';
import useExitPrompt from 'helper/useExitPrompt';

import cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';

// import { ReactComponent as TimerIlls } from '../../../assets/images/SkillAssessment/TimerIlls.svg';
import { ReactComponent as TimeToReadIllus } from '../../../assets/images/TimeToReadIllus.svg';
import LiveVideoPlayer from './LiveVideoPlayer';
import LottiLoader from '../../../components/LottiLoader';
import SubmitPopOverVideo from './SubmitPopOverVideo';

export default function AssessmentTestPsy({
  getVideoProfileAssessmentExamData,
  error,
  status,
  stopRecording,
  startRecording,
  clearMediaStream,
  liveStream,
  videoProfileSubmitMutation,
  setAdditional_info,
  isFullscreen,
  setCancelExam,
}) {
  const currentLanguageCode = cookies.get('i18next');
  const { t } = useTranslation(['translation']);
  // const location = useLocation();
  // const history = useHistory();
  // const queryStringValues = queryString.parse(location.search);
  const [videoProfileQuestions, setVideoProfileQuestions] = useState([]);
  const [activeQuestion, setActiveQuestion] = useState({});
  const [showSubmitPopOver, setShowSubmitPopOver] = useState(false);
  // eslint-disable-next-line
  // const [dummystate, setDummyState] = useState('Initial');
  const [isPageLeft, setIsPageLeft] = useState(false);
  const [isdisabled, setIsdisabled] = useState(true);

  const [viewTimerState, setViewTimerState] = useState('timer');

  const initialTime =
    getVideoProfileAssessmentExamData?.data?.data?.buffer_time;
  // isRunning
  const { seconds, minutes, restart } = useTimer({
    expiryTimestamp: Date.now() + +initialTime * 1000,
    onExpire: () => {
      videoProfileTimmerFn();
    },
  });

  useEffect(() => {
    if (isFullscreen === false) {
      setCancelExam(true);
    }
    // eslint-disable-next-line
  }, [isFullscreen]);

  usePageLeave(() => setIsPageLeft(true));
  const { width, height } = useWindowSize();

  useEffect(() => {
    clearMediaStream();
    if (getVideoProfileAssessmentExamData?.data) {
      getVideoProfileAssessmentExamData?.data?.data?.PSY.forEach(
        (question, index) => {
          question.questionNumber = index + 1;
          if (index === 0) {
            question.isQuestionViewed = true;
          } else {
            question.isQuestionViewed = false;
          }
          question.answer = null;
        }
      );
      setActiveQuestion(
        getVideoProfileAssessmentExamData?.data?.data?.PSY?.[0]
      );
      setVideoProfileQuestions(
        getVideoProfileAssessmentExamData?.data?.data?.PSY
      );
    }
    // eslint-disable-next-line
  }, [getVideoProfileAssessmentExamData?.data]);

  useEffect(() => {
    const additional_info = {
      screen_resolution: {
        width: width,
        height: height,
      },
      is_left_page: isPageLeft,
      is_fullscreen_enabled: isFullscreen,
    };
    setAdditional_info(additional_info);
  }, [isPageLeft, height, width, setAdditional_info, isFullscreen]);

  // prevent reload refresh
  // eslint-disable-next-line
  const [showExitPrompt, setShowExitPrompt] = useExitPrompt(false);

  //NOTE: this similar to componentWillUnmount()
  useEffect(() => {
    setShowExitPrompt(false);
    // eslint-disable-next-line
  }, []);

  // video record
  const videoProfileTimmerFn = () => {
    if (viewTimerState === 'timer') {
      setViewTimerState('question');
      startVideoRecording();
      setTimeout(() => {
        const time = new Date();
        time.setSeconds(
          time.getSeconds() +
            Math.ceil(
              getVideoProfileAssessmentExamData?.data?.data?.total_time /
                getVideoProfileAssessmentExamData?.data?.data?.total_count
            )
        );
        restart(time);
      }, 200);
      setIsdisabled(true);
      setTimeout(() => {
        setIsdisabled(false);
      }, 15000);
    } else if (viewTimerState === 'question') {
      if (activeQuestion?.questionNumber !== videoProfileQuestions.length) {
        setShowSubmitPopOver(false);
        setViewTimerState('timer');
        stopVideoRecording();
        if (
          activeQuestion?.questionNumber &&
          activeQuestion?.questionNumber < videoProfileQuestions.length
        ) {
          if (activeQuestion?.questionNumber < videoProfileQuestions.length) {
            videoProfileQuestions[
              activeQuestion?.questionNumber
            ].isQuestionViewed = true;
            setActiveQuestion(
              videoProfileQuestions?.[activeQuestion?.questionNumber]
            );
          }
        }
        setTimeout(() => {
          const time = new Date();
          time.setSeconds(
            time.getSeconds() +
              getVideoProfileAssessmentExamData?.data?.data?.buffer_time
          );
          restart(time);
        }, 200);
      } else {
        stopVideoRecording();
      }
    }
  };

  const startVideoRecording = () => {
    if (status !== 'recording') {
      startRecording(250);
    }
  };
  const stopVideoRecording = () => {
    if (status === 'recording') {
      stopRecording();
    }
  };

  return (
    <div className='bg-gray-50 min-h-85vh flex flex-col w-full max-w-main-page mx-auto'>
      {videoProfileSubmitMutation?.isLoading ? (
        <div className='min-h-85vh h-full flex flex-col items-center justify-center'>
          <LottiLoader />
          <div className='my-4 text-4F4F4F'>
            {t('translation:please_wait_we_are_submitting_your_assessment')}
          </div>
        </div>
      ) : (
        <>
          <div className='flex justify-center py-4'>
            <div className=''>
              <lottie-player
                src='https://assets8.lottiefiles.com/packages/lf20_3lzpb2yc.json'
                background='transparent'
                speed='1'
                style={{ width: '1.5rem', height: 'auto' }}
                loop
                autoplay
              ></lottie-player>
            </div>
            {minutes > 0 ? (
              <p
                className={`flex items-center ${
                  currentLanguageCode === 'ar' ? 'mr-2' : 'ml-2'
                }`}
              >
                <span
                  className={`text-Primary text-lg font-bold ${
                    currentLanguageCode === 'ar' ? 'pr-2' : 'pl-2'
                  }`}
                >
                  {minutes > 9 ? minutes : `0${minutes}`}:
                  {seconds > 9 ? seconds : `0${seconds}`}
                </span>{' '}
                <span
                  className={`text-4F4F4F font-bold ${
                    currentLanguageCode === 'ar' ? 'mr-2' : 'ml-2'
                  }`}
                >
                  {t('translation:time_left')}
                </span>
              </p>
            ) : viewTimerState === 'question' ? (
              <p
                className={`flex items-center ${
                  currentLanguageCode === 'ar' ? 'mr-2' : 'ml-2'
                }`}
              >
                <span
                  className={`text-red-600 text-lg font-bold ${
                    currentLanguageCode === 'ar' ? 'pr-2' : 'pl-2'
                  }`}
                >
                  {seconds > 9 ? seconds : `0${seconds}`} Secs
                </span>{' '}
                <span
                  className={`text-4F4F4F font-bold ${
                    currentLanguageCode === 'ar' ? 'mr-2' : 'ml-2'
                  }`}
                >
                  {t('translation:time_left')}
                </span>
              </p>
            ) : (
              <p
                className={`flex items-center ${
                  currentLanguageCode === 'ar' ? 'mr-2' : 'ml-2'
                }`}
              >
                <span className='text-4F4F4F font-bold '>
                  {t('translation:recording_starts_in')}
                </span>
                <span
                  className={`text-red-600 text-lg font-bold ${
                    currentLanguageCode === 'ar' ? 'pr-2' : 'pl-2'
                  }`}
                >
                  {seconds > 9 ? seconds : `0${seconds}`} Secs
                </span>{' '}
                {/* <span className='text-4F4F4F font-bold'>remaining</span> */}
              </p>
            )}
          </div>
          <div className='flex text-2A2515 select-none flex-grow bg-white w-11/12 mx-auto border rounded-lg'>
            <div className='w-full flex flex-col'>
              <div className='flex h-full max-h-75vh flex-col justify-between'>
                <div className='flex flex-col mx-4 max-h-20vh'>
                  <div className='2xl:text-xl text-lg flex font-semibold justify-center my-4 mx-10'>
                    <div
                      className={`flex ${
                        activeQuestion?.name?.length > 100
                          ? 'w-10/12'
                          : activeQuestion?.name?.length > 50
                          ? 'w-50rem'
                          : 'w-32rem'
                      } justify-start`}
                    >
                      {' '}
                      <span
                        className={`font-bold ${
                          currentLanguageCode === 'ar' ? 'ml-1' : 'mr-1'
                        }`}
                      >
                        {activeQuestion?.questionNumber}.{' '}
                      </span>{' '}
                      <p>{activeQuestion?.name}</p>
                    </div>
                  </div>
                </div>
                <div className='flex flex-col h-full mt-4 max-h-full overflow-hidden'>
                  {/* <VideoPlayer srcBlob={mediaBlob} /> */}
                  {liveStream ? (
                    <LiveVideoPlayer stream={liveStream} />
                  ) : (
                    <div className='flex justify-center items-center min-h-full'>
                      <div className='h-full w-32rem my-10 rounded-lg bg-ECECEC'>
                        <div className='flex flex-col justify-evenly items-center w-full h-full'>
                          <p className='text-Primary font-bold text-lg'>
                            {t('translation:time_to_read_the_question')}
                          </p>
                          <TimeToReadIllus className='w-72 h-48' />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className='flex flex-col mx-4 max-h-14'>
                  <div className='flex justify-between my-4  text-sm'>
                    <div className='w-32'></div>
                    <div>
                      {videoProfileQuestions.length ? (
                        <div className='flex flex-col'>
                          <div className='flex flex-wrap gap-3'>
                            {videoProfileQuestions.map((question) => (
                              <div
                                key={question._id}
                                className={`flex items-center justify-center w-10 h-10 text-sm rounded ${
                                  question?.isQuestionViewed
                                    ? 'bg-Primary  text-white'
                                    : 'bg-white border-2 border-black border-opacity-50 text-2A2515'
                                }`}
                              >
                                {question?.questionNumber}
                              </div>
                            ))}
                          </div>
                        </div>
                      ) : null}
                    </div>
                    <button
                      onClick={() => {
                        if (viewTimerState === 'question') {
                          setShowSubmitPopOver(true);
                        }
                      }}
                      disabled={viewTimerState === 'timer' || isdisabled}
                      className={`w-32 whitespace-nowrap ${
                        viewTimerState === 'timer' || isdisabled
                          ? 'opacity-60 cursor-not-allowed'
                          : ''
                      }`}
                    >
                      <SecondaryButton>
                        {activeQuestion?.questionNumber ===
                        videoProfileQuestions.length
                          ? t('translation:submit')
                          : t('translation:save_and_next')}{' '}
                      </SecondaryButton>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className='w-32 mt-4 mx-auto'>
        <button onClick={() => setShowSubmitPopOver(true)} className=' w-32'>
          <PrimaryButton>Submit</PrimaryButton>
        </button>
      </div> */}
          <SubmitPopOverVideo
            setVisible={setShowSubmitPopOver}
            isVisible={showSubmitPopOver}
            videoProfileTimmerFn={videoProfileTimmerFn}
            activeQuestionNumber={activeQuestion?.questionNumber}
            videoProfileQuestionsLength={videoProfileQuestions.length}
          />
        </>
      )}
    </div>
  );
}
