import React, { useEffect, useContext, useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useQuery } from 'react-query';
import { MainContext } from 'contexts/mainContext';
import { useLocation, useHistory } from 'react-router-dom';
import queryString from 'query-string';
import makeAPIRequest from 'services/makeApiRequest.js';
import apiEndPoints from 'services/apiEndpoints';
import AssessmentInfo from './Components/AssessmentInfo';
import Assessmentlogin from './Components/login';
import Instructions from './Components/instructions';
import AssessmentOverview from './Components/AssessmentOverview';
import AssessmentTest from './Components/assessmentTest';
import RegsiterSuccess from './Components/registerSuccess';
import ExamCancelled from './Components/ExamCancelled';
import { useGeolocation } from 'react-use';

import NoMobile from 'helper/NoMobile';

export default function SkillAssessment({ isFullscreen, toggle, width }) {
  const { mainDispatch } = useContext(MainContext);
  const location = useLocation();
  const history = useHistory();
  const queryStringValues = queryString.parse(location.search);
  const [geoLocation, setGeoLocation] = useState();

  useEffect(() => {
    if (queryStringValues?.company_display_name) {
      mainDispatch({
        type: 'SETNAMELOGOCOLOR',
        payload: {
          company_name: queryStringValues?.company_display_name,
          company_logo: queryStringValues?.company_logo,
          company_color: queryStringValues?.company_color,
        },
      });
    }
    // eslint-disable-next-line
  }, []);

  const getSkillAssessmentExamData = useQuery(
    ['getSkillAssessmentExamData'],
    async () => {
      const { data } = await makeAPIRequest.get(
        `${apiEndPoints.SKILLASSESSMENTEXAM}?company=${queryStringValues.company_name}&job_id=${queryStringValues.job_id}`
      );
      return data;
    },
    {
      enabled: !!queryStringValues.job_id,
      onSuccess: (data) => {
        if (data.data.total_count === 0) {
          const searchString = {
            ErrorMessage: 'No Questions Found!',
            ...queryStringValues,
          };
          const newerrorsearchString = queryString.stringify(searchString);
          history.push({
            pathname: '/skill-assessment/cancelled',
            search: `?${newerrorsearchString}`,
          });
        }
      },
      onError: (err) => {
        const newsearchString = {
          ErrorMessage: err.response.data.message,
          ...queryStringValues,
        };
        const newerrorsearchString = queryString.stringify(newsearchString);
        history.push({
          pathname: '/skill-assessment/cancelled',
          search: `?${newerrorsearchString}`,
        });
      },
    }
  );

  if (width < 750) {
    return <NoMobile />;
  }

  return (
    <div className='bg-gray-50'>
      <Switch>
        <Route exact path='/skill-assessment'>
          <AssessmentInfo />
        </Route>
        <Route exact path='/skill-assessment/login'>
          <Assessmentlogin width={width} toggle={toggle} />
        </Route>
        <Route exact path='/skill-assessment/instructions'>
          <Instructions
            useGeolocation={useGeolocation}
            setGeoLocation={setGeoLocation}
          />
        </Route>
        <Route exact path='/skill-assessment/overview'>
          <AssessmentOverview
            isFullscreen={isFullscreen}
            getSkillAssessmentExamData={getSkillAssessmentExamData}
          />
        </Route>
        <Route exact path='/skill-assessment/test'>
          <AssessmentTest
            isFullscreen={isFullscreen}
            getSkillAssessmentExamData={getSkillAssessmentExamData}
            toggle={toggle}
            geoLocation={geoLocation}
          />
        </Route>
        <Route exact path='/skill-assessment/success'>
          <RegsiterSuccess toggle={toggle} />
        </Route>
        <Route exact path='/skill-assessment/cancelled'>
          <ExamCancelled toggle={toggle} />
        </Route>
      </Switch>
    </div>
  );
}
