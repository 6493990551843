import React, { createContext, useEffect, useReducer } from 'react';
export const MainContext = createContext();

let initialState = {
  company_name: null,
  company_logo: null,
  company_color: null,
  show_warning_banner:
    JSON.parse(localStorage.getItem('show_warning_banner')) || true,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SETNAMELOGOCOLOR':
      localStorage.setItem(
        'company_name',
        JSON.stringify(action.payload.company_name)
      );
      localStorage.setItem(
        'company_logo',
        JSON.stringify(action.payload.company_logo)
      );
      localStorage.setItem(
        'company_color',
        JSON.stringify(action.payload.company_color)
      );
      return {
        ...state,
        company_name: action.payload.company_name,
        company_logo: action.payload.company_logo,
        company_color: action.payload.company_color,
      };
    case 'SETWARNINGBANNER':
      localStorage.setItem(
        'show_warning_banner',
        JSON.stringify(action.payload)
      );
      return { ...state, show_warning_banner: action.payload };
    default:
      return state;
  }
};

const MainProvider = ({ children }) => {
  const [mainState, mainDispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    initialState = { ...initialState };
  }, []);

  return (
    <MainContext.Provider
      value={{
        mainState,
        mainDispatch,
      }}
    >
      {children}
    </MainContext.Provider>
  );
};

export default MainProvider;
