import React from 'react';
// import SecondaryButton from '../../../components/Buttons/SecondaryButton';

// import LottiLoader from '../../../components/LottiLoader';
import VideoConference from './VideoConference';

export default function VideoInterview() {
  return (
    <div className='bg-gray-50 min-h-85vh flex items-center justify-center w-full max-w-main-page mx-auto'>
      <div className='flex mx-10 w-full'>
        <VideoConference />
      </div>
    </div>
  );
}
