import React from 'react';
import SecondaryButton from '../../../components/Buttons/SecondaryButton';
import { ReactComponent as NOJObs } from 'assets/images/JobApplication/no_jobs.svg';

import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { Skeleton } from 'primereact/skeleton';

import dateFormatter from 'helper/dateFormatter';

import { useTranslation } from 'react-i18next';
import cookies from 'js-cookie';

import { ReactComponent as LocationIlls } from '../../../assets/images/JobApplication/locationIlls.svg';
import { ReactComponent as ExperienceIlls } from '../../../assets/images/JobApplication/ExperienceIlls.svg';
import { ReactComponent as DateIlls } from '../../../assets/images/JobApplication/DateIlls.svg';

export default function ViewAllJobs({
  setSelectJobID,
  getApplicationFormJobs,
}) {
  const { t } = useTranslation(['translation']);
  const currentLanguageCode = cookies.get('i18next');
  const location = useLocation();
  const history = useHistory();
  const queryStringValues = queryString.parse(location.search);

  return (
    <div className='flex mx-6 lg:mx-0'>
      <div
        className={`w-full lg:mx-auto lg:max-w-screen-xl rounded-lg bg-white shadow-lg my-8 border pt-6 p-4 ${
          currentLanguageCode === 'ar' ? 'pl-2' : 'pr-2'
        }`}
      >
        {getApplicationFormJobs?.status === 'error' &&
        getApplicationFormJobs?.error?.response?.data?.message ===
          'No Job(s) Found.' ? (
          <div className='max-h-75vh min-h-75vh flex flex-col items-center justify-center'>
            <div>
              <NOJObs />
            </div>
            <div>{t('translation:no_Jobs_found')}.</div>
          </div>
        ) : (
          <div className='max-h-75vh min-h-75vh px-2 overflow-y-scroll scroller'>
            {getApplicationFormJobs.isLoading ? (
              <div>
                <div>
                  <div>
                    <Skeleton height='1.5rem' width='12rem' />
                  </div>
                  <div className='flex w-full items-center'>
                    <div className='flex w-8/12 '>
                      <div
                        className={`flex flex-1 ${
                          currentLanguageCode === 'ar'
                            ? 'sm:ml-2 lg:ml-3'
                            : 'sm:mr-2 lg:mr-3'
                        }`}
                      >
                        <Skeleton height='1rem' width='1rem' />
                        <div className='text-sm text-gray-400 divl-1 pt-1 whitespace-nowrap'>
                          <Skeleton
                            className={`${
                              currentLanguageCode === 'ar' ? 'mr-1' : 'ml-1'
                            }`}
                            height='1.3rem'
                            width='8rem'
                          />
                        </div>
                      </div>
                      <Skeleton
                        className={`${
                          currentLanguageCode === 'ar'
                            ? 'sm:ml-4 lg:ml-5'
                            : 'sm:mr-4 lg:mr-5'
                        }`}
                        height='0.75rem'
                        width='2px'
                      />
                      <div
                        className={`flex flex-1 ${
                          currentLanguageCode === 'ar'
                            ? 'sm:ml-2 lg:ml-3'
                            : 'sm:mr-2 lg:mr-3'
                        }`}
                      >
                        <Skeleton height='1rem' width='1rem' />
                        <div
                          className={`text-sm text-gray-400 pt-1 whitespace-nowrap ${
                            currentLanguageCode === 'ar' ? 'pr-1' : 'pl-1'
                          }`}
                        >
                          <Skeleton
                            className={`${
                              currentLanguageCode === 'ar' ? 'mr-1' : 'ml-1'
                            }`}
                            height='1.3rem'
                            width='12rem'
                          />
                        </div>
                      </div>
                      <Skeleton
                        className={`${
                          currentLanguageCode === 'ar'
                            ? 'sm:ml-4 lg:ml-5'
                            : 'sm:mr-4 lg:mr-5'
                        }`}
                        height='0.75rem'
                        width='2px'
                      />
                      <div
                        className={`flex flex-1 ${
                          currentLanguageCode === 'ar'
                            ? 'sm:ml-2 lg:ml-3'
                            : 'sm:mr-2 lg:mr-3'
                        }`}
                      >
                        <Skeleton height='1rem' width='1rem' />
                        <div
                          className={`text-sm text-gray-400 pt-1 whitespace-nowrap ${
                            currentLanguageCode === 'ar' ? 'pr-1' : 'pl-1'
                          }`}
                        >
                          <Skeleton
                            className={`${
                              currentLanguageCode === 'ar' ? 'mr-1' : 'ml-1'
                            }`}
                            height='1.3rem'
                            width='8rem'
                          />
                        </div>
                      </div>
                    </div>

                    <div className='flex w-4/12 justify-end'>
                      <Skeleton
                        className={`mb-2 ${
                          currentLanguageCode === 'ar' ? 'ml-4' : 'mr-4'
                        }`}
                        height='2.4rem'
                        width='7rem'
                      />
                    </div>
                  </div>
                  <hr className='my-4' />
                </div>
                <div>
                  <div>
                    <Skeleton height='1.5rem' width='12rem' />
                  </div>
                  <div className='flex w-full items-center'>
                    <div className='flex w-8/12 '>
                      <div
                        className={`flex flex-1 ${
                          currentLanguageCode === 'ar'
                            ? 'sm:ml-2 lg:ml-3'
                            : 'sm:mr-2 lg:mr-3'
                        }`}
                      >
                        <Skeleton height='1rem' width='1rem' />
                        <div className='text-sm text-gray-400 divl-1 pt-1 whitespace-nowrap'>
                          <Skeleton
                            className={`${
                              currentLanguageCode === 'ar' ? 'mr-1' : 'ml-1'
                            }`}
                            height='1.3rem'
                            width='8rem'
                          />
                        </div>
                      </div>
                      <Skeleton
                        className={`${
                          currentLanguageCode === 'ar'
                            ? 'sm:ml-4 lg:ml-5'
                            : 'sm:mr-4 lg:mr-5'
                        }`}
                        height='0.75rem'
                        width='2px'
                      />
                      <div
                        className={`flex flex-1 ${
                          currentLanguageCode === 'ar'
                            ? 'sm:ml-2 lg:ml-3'
                            : 'sm:mr-2 lg:mr-3'
                        }`}
                      >
                        <Skeleton height='1rem' width='1rem' />
                        <div
                          className={`text-sm text-gray-400 pt-1 whitespace-nowrap ${
                            currentLanguageCode === 'ar' ? 'pr-1' : 'pl-1'
                          }`}
                        >
                          <Skeleton
                            className={`${
                              currentLanguageCode === 'ar' ? 'mr-1' : 'ml-1'
                            }`}
                            height='1.3rem'
                            width='12rem'
                          />
                        </div>
                      </div>
                      <Skeleton
                        className={`${
                          currentLanguageCode === 'ar'
                            ? 'sm:ml-4 lg:ml-5'
                            : 'sm:mr-4 lg:mr-5'
                        }`}
                        height='0.75rem'
                        width='2px'
                      />
                      <div
                        className={`flex flex-1 ${
                          currentLanguageCode === 'ar'
                            ? 'sm:ml-2 lg:ml-3'
                            : 'sm:mr-2 lg:mr-3'
                        }`}
                      >
                        <Skeleton height='1rem' width='1rem' />
                        <div
                          className={`text-sm text-gray-400 pt-1 whitespace-nowrap ${
                            currentLanguageCode === 'ar' ? 'pr-1' : 'pl-1'
                          }`}
                        >
                          <Skeleton
                            className={`${
                              currentLanguageCode === 'ar' ? 'mr-1' : 'ml-1'
                            }`}
                            height='1.3rem'
                            width='8rem'
                          />
                        </div>
                      </div>
                    </div>

                    <div className='flex w-4/12 justify-end'>
                      <Skeleton
                        className={`mb-2 ${
                          currentLanguageCode === 'ar' ? 'ml-4' : 'mr-4'
                        }`}
                        height='2.4rem'
                        width='7rem'
                      />
                    </div>
                  </div>
                  <hr className='my-4' />
                </div>
                <div>
                  <div>
                    <Skeleton height='1.5rem' width='12rem' />
                  </div>
                  <div className='flex w-full items-center'>
                    <div className='flex w-8/12 '>
                      <div
                        className={`flex flex-1 ${
                          currentLanguageCode === 'ar'
                            ? 'sm:ml-2 lg:ml-3'
                            : 'sm:mr-2 lg:mr-3'
                        }`}
                      >
                        <Skeleton height='1rem' width='1rem' />
                        <div className='text-sm text-gray-400 divl-1 pt-1 whitespace-nowrap'>
                          <Skeleton
                            className={`${
                              currentLanguageCode === 'ar' ? 'mr-1' : 'ml-1'
                            }`}
                            height='1.3rem'
                            width='8rem'
                          />
                        </div>
                      </div>
                      <Skeleton
                        className={`${
                          currentLanguageCode === 'ar'
                            ? 'sm:ml-4 lg:ml-5'
                            : 'sm:mr-4 lg:mr-5'
                        }`}
                        height='0.75rem'
                        width='2px'
                      />
                      <div
                        className={`flex flex-1 ${
                          currentLanguageCode === 'ar'
                            ? 'sm:ml-2 lg:ml-3'
                            : 'sm:mr-2 lg:mr-3'
                        }`}
                      >
                        <Skeleton height='1rem' width='1rem' />
                        <div
                          className={`text-sm text-gray-400 pt-1 whitespace-nowrap ${
                            currentLanguageCode === 'ar' ? 'pr-1' : 'pl-1'
                          }`}
                        >
                          <Skeleton
                            className={`${
                              currentLanguageCode === 'ar' ? 'mr-1' : 'ml-1'
                            }`}
                            height='1.3rem'
                            width='12rem'
                          />
                        </div>
                      </div>
                      <Skeleton
                        className={`${
                          currentLanguageCode === 'ar'
                            ? 'sm:ml-4 lg:ml-5'
                            : 'sm:mr-4 lg:mr-5'
                        }`}
                        height='0.75rem'
                        width='2px'
                      />
                      <div
                        className={`flex flex-1 ${
                          currentLanguageCode === 'ar'
                            ? 'sm:ml-2 lg:ml-3'
                            : 'sm:mr-2 lg:mr-3'
                        }`}
                      >
                        <Skeleton height='1rem' width='1rem' />
                        <div
                          className={`text-sm text-gray-400 pt-1 whitespace-nowrap ${
                            currentLanguageCode === 'ar' ? 'pr-1' : 'pl-1'
                          }`}
                        >
                          <Skeleton
                            className={`${
                              currentLanguageCode === 'ar' ? 'mr-1' : 'ml-1'
                            }`}
                            height='1.3rem'
                            width='8rem'
                          />
                        </div>
                      </div>
                    </div>

                    <div className='flex w-4/12 justify-end'>
                      <Skeleton
                        className={`mb-2 ${
                          currentLanguageCode === 'ar' ? 'ml-4' : 'mr-4'
                        }`}
                        height='2.4rem'
                        width='7rem'
                      />
                    </div>
                  </div>
                  <hr className='my-4' />
                </div>
              </div>
            ) : (
              getApplicationFormJobs.data?.data?.map((item, index, arr) => (
                <div key={item._id}>
                  <div className='flex mb-4 lg:mb-0 w-full items-center justify-between'>
                    <p>{item.jobtitle}</p>
                    <div className='flex lg:hidden justify-end'>
                      <button
                        onClick={() => {
                          setSelectJobID(item._id);
                          if (!!queryStringValues.client) {
                            history.push({
                              pathname: location.pathname,
                              search: `?_id=${item._id}&name=${item.jobtitle}&view=view&client=${queryStringValues.client}`,
                            });
                          } else {
                            history.push({
                              pathname: location.pathname,
                              search: `?_id=${item._id}&name=${item.jobtitle}&view=view`,
                            });
                          }
                        }}
                        className={`w-28 ${
                          currentLanguageCode === 'ar' ? 'ml-4' : 'mr-4'
                        }`}
                      >
                        <SecondaryButton>
                          {t('translation:open')}
                        </SecondaryButton>
                      </button>
                    </div>
                  </div>
                  <div className='flex w-full items-center'>
                    <div className='flex flex-col md:flex-row w-full lg:w-8/12 '>
                      <div
                        className={`flex flex-1 ${
                          currentLanguageCode === 'ar'
                            ? 'sm:ml-2 lg:ml-3'
                            : 'sm:mr-2 lg:mr-3'
                        }`}
                      >
                        <LocationIlls className='w-4 h-4' />
                        <p
                          className={`text-sm text-gray-400 pt-1 whitespace-nowrap ${
                            currentLanguageCode === 'ar' ? 'pr-1' : 'pl-1'
                          }`}
                        >
                          {item.location}
                        </p>
                      </div>
                      <div
                        className={`h-3 w-0.5 bg-gray-700 hidden md:block ${
                          currentLanguageCode === 'ar'
                            ? 'sm:ml-4 lg:ml-5'
                            : 'sm:mr-4 lg:mr-5'
                        }`}
                      ></div>
                      <div
                        className={`flex flex-1 ${
                          currentLanguageCode === 'ar'
                            ? 'sm:ml-2 lg:ml-3'
                            : 'sm:mr-2 lg:mr-3'
                        }`}
                      >
                        <ExperienceIlls className='w-4 h-4' />
                        <p
                          className={`text-sm text-gray-400 pt-1 whitespace-nowrap ${
                            currentLanguageCode === 'ar' ? 'pr-1' : 'pl-1'
                          }`}
                        >
                          {`Experience: ${item.experience} ${
                            item.experience === '0-1' ? 'year' : 'years'
                          }`}
                        </p>
                      </div>
                      <div
                        className={`h-3 w-0.5 bg-gray-700 hidden md:block ${
                          currentLanguageCode === 'ar'
                            ? 'sm:ml-4 lg:ml-5'
                            : 'sm:mr-4 lg:mr-5'
                        }`}
                      ></div>
                      <div
                        className={`flex flex-1 ${
                          currentLanguageCode === 'ar'
                            ? 'sm:ml-2 lg:ml-3'
                            : 'sm:mr-2 lg:mr-3'
                        }`}
                      >
                        <DateIlls className='w-4 h-4' />
                        <p
                          className={`text-sm text-gray-400 pt-1 whitespace-nowrap ${
                            currentLanguageCode === 'ar' ? 'pr-1' : 'pl-1'
                          }`}
                        >
                          {dateFormatter(item.posted_date, 'll')}
                        </p>
                      </div>
                    </div>
                    <div className='hidden lg:flex lg:w-4/12 justify-end'>
                      <button
                        onClick={() => {
                          setSelectJobID(item._id);
                          if (!!queryStringValues.client) {
                            history.push({
                              pathname: location.pathname,
                              search: `?_id=${item._id}&name=${item.jobtitle}&view=view&client=${queryStringValues.client}`,
                            });
                          } else {
                            history.push({
                              pathname: location.pathname,
                              search: `?_id=${item._id}&name=${item.jobtitle}&view=view`,
                            });
                          }
                        }}
                        className={`w-28 mb-2 ${
                          currentLanguageCode === 'ar' ? 'ml-4' : 'mr-4'
                        }`}
                      >
                        <SecondaryButton>
                          {t('translation:open')}
                        </SecondaryButton>
                      </button>
                    </div>
                  </div>
                  {arr.length - 1 !== index ? <hr className='my-4' /> : null}
                </div>
              ))
            )}
          </div>
        )}
      </div>
    </div>
  );
}
